import React, { Component } from 'react'
import { exception } from 'services/GA';

import { NonIdealState } from '.';

export default class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		exception({
			description: error,
			fatal: true,
		})
		console.error(error, errorInfo)
	}

	render() {
		const { children, ...props } = this.props
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <NonIdealState
				{...props}
				icon="fixing_bugs"
				title="Ocorreu um erro"
				description="Seremos alertados sobre o ocorrido e faremos o máximo para corrigir o problema o quanto antes."
			/>;
		}

		return children;
	}
}