import React from 'react'
import styled from 'styled-components'
import { Undraw } from './Undraw';

const Container = styled.div`
	height: ${props => props.height || "100%"};
	padding: 20px 40px;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	text-align: center;
`

const SVGContainer = styled.div`
	color: var(--SVG_default_color);
	margin-bottom: 15px;
	width: 100%;
`

const NonIdealState = ({ icon, color, title, description, action, ...props }) => <Container {...props}>
	<SVGContainer>
		<Undraw name={icon || "fixing_bugs"} primaryColor={color || "currentColor"} />
	</SVGContainer>
	{title && <h4>{title}</h4>}
	{description && <p>{description}</p>}
	{action}
</Container>

export default NonIdealState