import React from 'react'

import {
	SelectWrapper, Container, IconPrefix, Input, Reset, Label, Caret,
	BasicSelectItem, basicSearch, 
} from './elements'

export const Presenter = ({
	options, valueDefault, containerClass,
	outline, icon, label, type, autoComplete,
	active, focus, value,
	handleSelect, handleFocus, handleBlur, resetValue,
	...props
}) => <SelectWrapper
	className={containerClass}
	items={options}
	itemRenderer={BasicSelectItem}
	onItemSelect={handleSelect}
	itemPredicate={basicSearch}
	disabled={props.disabled}
	inputProps={{
		placeholder: "Buscar...",
		autoFocus: true,
	}}
	popoverProps={{
		position: "top",
	// TODO: prevent changing the focus state when the focus goes to the popover
	// 	isOpen: focus,
	}}
>
	<Container {...{ outline, active }} className="conpub-inputselect">
		<IconPrefix {...{ icon, focus }} />
		<Input 
			{...props}
			onChange={handleSelect}
			onFocus={handleFocus}
			onBlur={handleBlur}
			value={value}
			tabIndex="0" // TODO: still not accessible by keyboard
			// This is a workaround: https://stackoverflow.com/q/15738259/4718937
			autoComplete={autoComplete || "new-password"}
		/>
		<Caret icon="caret-down" />
		{active && <Reset icon="cross" onClick={resetValue} />}
		<Label htmlFor={props.id}>{ label }</Label>
	</Container>
</SelectWrapper>

// Presenter.prototype = {
// 	multiple: a.bool,
// 	options: a.arrayOf(a.shape({
// 		checked: a.bool,
// 		disabled: a.bool,
// 		icon: a.string,
// 		image: a.string,
// 		separator: a.bool,
// 		text: a.oneOfType([a.object, a.string]),
// 		value: a.string
// 	})),
// }