import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
	background-image: url(${props => props.image});
	background-size: cover;
	background-position: center;
	/* background-blend-mode: soft-light; */
	min-height: calc(calc(var(--vh) * 100) - var(--nav-height));
	max-width: 100%;

	@media (min-width: 993px) {
		background-position: left;
	}
`

const Banner = styled.img`
	width: 100vw;
	height: calc(var(--vh) * 100);
	position: absolute;
	object-fit: cover;
`

const MyMask = props => <Container {...props}>
	{<props.defaultImage />}
	<Banner src={props.image} alt="" />
	{props.children}
</Container>

export default MyMask