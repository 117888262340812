import React, { useState, useCallback, useEffect, memo, useContext } from 'react'
import { Stepper } from '../Stepper'
import { useLoginSteps as useLoginStep } from './steps/login'
import { useForgotSteps as useForgotStep } from './steps/forgotPassword'
import { MDB as MDBLayout } from '../Stepper/layouts'
import { configuracaoCadastro } from '../../services'

import { useChangeSteps as useChangeStep } from './steps/changePassword'
import { useCheckEmailStep } from './steps/checkEmail'
import { useCheckProfileStep } from './steps/checkProfile'
import { CheckProfileWarningStep } from './steps/checkProfileWarning'
import { useCompleteProfile } from './steps/completeProfile'
import { ForgotPasswordWarningStep } from './steps/forgotPasswordWarning'
import { CompleteProfileWarningStep } from './steps/completeProfileWarning'
import { ChangePasswordSuccess } from './steps/changePasswordSuccess'
import { clearAuthStorage } from './utils/clearAuthStorage'
import { useParams } from 'react-router'
import { PageContext } from 'pages'

export const SEQUENCIA = { LOGIN: 0, FORGOT: 1, CADASTRO: 2, CHANGE: 3 }

export const CardAuthentication = memo(({ onFinish, initSequencia }) => {
	const { orgaoInfo } = useContext(PageContext)
	useEffect(() => {
		// TODO: criar um hook para checar os parâmetros do órgão
		if (orgaoInfo?.config?.MANTER_LOGIN_PARA_CONTRIBUIR) {
			if (sessionStorage.getItem('auth-token'))
				onFinish()
		} else {
			// TODO: discutir se é obrigatório reentrar com o usuário/senha antes de fazer contribuição #132
			clearAuthStorage()
		}
	}, [orgaoInfo, onFinish])

	const [sequencia, setSequencia] = useState(initSequencia || SEQUENCIA.LOGIN)
	const switchSequencia = useCallback(x => () => setSequencia(x), [])

	let { idConsulta } = useParams()

	const [neededSections, setNeededSections] = useState({})
	useEffect(() => { (async () => {
		const r = idConsulta ? await configuracaoCadastro(idConsulta) : []
		setNeededSections({
			...r.reduce((prev, cur) => ({
				...prev, [cur.nome]: cur.obrigatorio,
			}), {}),
			nome: true,
			email: true,
			senha: true,
		})
	})() }, [idConsulta])

	const [shouldCompleteProfile, setSCP] = useState(false)
	const mustCompleteLogin		= useCallback(() => setSCP(true), [])
	useEffect(() => setSCP(() => sequencia === SEQUENCIA.CADASTRO), [sequencia])
	const completeProfileSteps = useCompleteProfile({ neededSections })
	
	const [shouldCheckProfile, setSChP] = useState(false)
	const mustCheckProfile = useCallback(p => setSChP(p), [])
	const checkProfileStep = useCheckProfileStep({ neededSections, profile: shouldCheckProfile })

	const loginStep = useLoginStep({
		switchSequencia, neededSections, mustCompleteLogin, mustCheckProfile,
	})
	const forgotStep = useForgotStep({ switchSequencia })
	const registrationStep = useCheckEmailStep({ switchSequencia })
	const changeStep = useChangeStep()

	const firstStep = {
		[SEQUENCIA.LOGIN]:		loginStep,
		[SEQUENCIA.FORGOT]:		forgotStep,
		[SEQUENCIA.CADASTRO]:	registrationStep,
		[SEQUENCIA.CHANGE]:		changeStep,
	}[sequencia]

	const steps = [firstStep]

	if (sequencia === SEQUENCIA.FORGOT)
		steps.push(() => <ForgotPasswordWarningStep {...{ switchSequencia }} />)

	if (sequencia === SEQUENCIA.CHANGE)
		steps.push(ChangePasswordSuccess)

	if (shouldCompleteProfile) {
		steps.push(CompleteProfileWarningStep)
		steps.push(...completeProfileSteps)
	}

	if (shouldCheckProfile) {
		steps.push(CheckProfileWarningStep)
		steps.push(checkProfileStep)
	}

	return <Stepper layout={MDBLayout} onFinish={onFinish}>
		{steps.map((C, i) => <C key={i} />)}
	</Stepper>
})
