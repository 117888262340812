import {
	capitalizeFirstLetter, defaultFetch, restoreOrgaoId, personalToken
} from "../utils"

const API_CONSULTA = process.env.REACT_APP_API_READ_ONLY_ROOT + 'consulta';
const API_CONTRIBUICAO_RO = process.env.REACT_APP_API_READ_ONLY_ROOT + 'contribuicao';
const API_CONTRIBUICAO_RW = process.env.REACT_APP_API_READ_WRITE_ROOT + 'contribuicao';

// ============================== API DOC - START ==============================
/**
 * @api {get} /consulta/ativas Listar consultas ativas
 * @apiVersion 0.1.0
 * @apiName ConsultasAtivas
 * @apiGroup Consultas
 * @apiPermission todos
 * 
 * @apiDescription Esse endpoint deve responder com as consultas que estiverem
 * ativas. Basicamente deverá executar o método
 * <i>recuperarConsultasAtivasPorOrgao</i> do <i>ConsultaDAO</i>, realizando uma
 * filtragem dos campos.
 * 
 * @apiParam {Number} idOrgao Identificação única do órgão
 * 
 * @apiSuccess {Object[]}		lista				Lista das consultas ativas.
 * @apiSuccess {Number}			lista.id				Identificação única da
 * consulta.
 * @apiSuccess {String}			lista.datainicio		Data de início que a
 * consulta começou ou vai começar. Pode ser tanto uma data futura (a interface
 * irá ocultá-la nesse caso) ou uma data no passado.
 * @apiSuccess {String}			lista.datatermino	Data de término que a
 * consulta finalizou ou vai finalizar. Deve ser uma data futura, visto que como
 * ela ainda está ativa, pressupõe-se que ela ainda não terminou.
 * @apiSuccess {String{..255}}	lista.nome			Nome ou título da consulta.
 * @apiSuccess {String}			lista.ementa		Ementa da consulta, exibida
 * apenas durante a listagem das consultas. Deve chamar a atenção de quem a lê.
 * Assim como a descrição, pode conter declarações HTML.
 * 
 * @apiUse ServiceResponseError
 */
// =============================== API DOC - END ===============================

// ============================== JS DOC - START ===============================
/**
 * @typedef ConsultaResumida
 * @property {Number}	id			Identificação única da consulta.
 * @property {String}	datainicio	Data de início que a
 * consulta começou ou vai começar. Pode ser tanto uma data futura (a interface
 * irá ocultá-la nesse caso) ou uma data no passado.
 * @property {String}	datatermino	Data de término que a consulta finalizou ou
 * vai finalizar. Deve ser uma data futura, visto que como ela ainda está ativa,
 * pressupõe-se que ela ainda não terminou.
 * @property {String}   nome		Nome ou título da consulta.
 * @property {String}	ementa		Ementa da consulta, exibida apenas durante a listagem das consultas. Assim como a descrição, pode conter declarações HTML.
 * 
 * @function consultasAtivas
 * @param {Number} idOrgao Identificação única do órgão
 * @return {ConsultaResumida[]}
 */
// =============================== JS DOC - END ================================
export function consultasAtivas() {
	const id = restoreOrgaoId(),
		endPoint = `${API_CONSULTA}/ativas?idOrgao=${id}`

	return defaultFetch(endPoint)
}

// ============================== API DOC - START ==============================
/**
 * @api {get} /consulta/finalizadas Listar consultas finalizadas
 * @apiVersion 0.1.0
 * @apiName ConsultasFinalizadas
 * @apiGroup Consultas
 * @apiPermission todos
 * 
 * @apiDescription Esse endpoint deve responder com as consultas que estiverem
 * finalizadas. Basicamente deverá executar o método
 * <i>recuperarConsultasFinalizadasPorOrgao</i> do <i>ConsultaDAO</i>,
 * realizando uma filtragem dos campos.
 * 
 * @apiParam {Number} idOrgao Identificação única do órgão
 * 
 * @apiSuccess {Object[]}	lista				Lista das consultas finalizadas.
 * @apiSuccess {Number}		lista.id			Identificação única da consulta.
 * @apiSuccess {String}		lista.datainicio	Data de início que a consulta
 * começou ou vai começar. Deve ser uma data passada, visto que como ela está
 * finalizada, pressupõe-se que já terminou.
 * @apiSuccess {String}		lista.datatermino	Data de término que a consulta
 * finalizou ou vai finalizar. Deve ser uma data passada, visto que como ela
 * está finalizada, pressupõe-se que já terminou.
 * @apiSuccess {String}		lista.nome			Nome ou título da consulta.
 * @apiSuccess {String}		lista.ementa		Ementa da consulta, atualmente
 * não é exibida durante a listagem das consultas finalizadas, mas isso pode
 * voltar a acontecer. Assim como a descrição, pode conter declarações HTML.
 * 
 * @apiUse ServiceResponseError
 */
// =============================== API DOC - END ===============================

// ============================== JS DOC - START ===============================
/**
 * @function consultasFinalizadas
 * @param {Number} idOrgao Identificação única do órgão
 * @return {ConsultaResumida[]}
 */
// =============================== JS DOC - END ================================
export function consultasFinalizadas() {
	const idOrgao = restoreOrgaoId(),
		endPoint = `${API_CONSULTA}/finalizadas?idOrgao=${idOrgao}`

	return defaultFetch(endPoint)
}

// ============================== API DOC - START ==============================
/**
 * @api {get} /consulta/:id Recuperar detalhes de uma consulta
 * @apiVersion 0.1.0
 * @apiName DetalhesConsulta
 * @apiGroup Consultas
 * @apiPermission todos
 * 
 * @apiDescription Esse endpoint deve responder com os detalhes de uma consulta.
 * Na realidade, só será chamado para consultas ativas, portanto não há problema
 * se a API recusar responder caso a consulta já tenha sido encerrada. 
 * <ul><li>O nome, consta na tabela <i>CONSULTA</i>.</li>
 * <li>Os arquivos estão mapeados na tabela <i>ARQUIVOS</i>.</li>
 * <li>As questões estão mapeados na tabela <i>QUESTOES</i>.<ul>
 * 		<li>As alternativas para o tipo 'objetiva' estão mapeados na tabela <i>ALTERNATIVA</i></li>
 * 		<li>As alternativas para o tipo 'checkbox' estão mapeados na tabela <i>ALTERNATIVACHECKBOX</i></li>
 * </ul></li></ul>
 * 
 * @apiParam {Number} id Identificação única da consulta
 * 
 * @apiSuccess {String{..255}}			nome					Nome ou título
 * da consulta.
 * @apiSuccess {String}					descricao				Descrição da
 * consulta, exibida com o intúito de esclarecer qualquer dúvida que o cidadão
 * possa ter.<br /><br /><b>Atenção</b>: o texto pode conter declarações HTML,
 * entretanto é uma prática que no futuro deve ser substituída por algo como
 * Markdown para evitar que scripts seja injetados no site.
 * @apiSuccess {Boolean}				obrigatorio				Determina se o
 * campo de justificar opinião é obrigatório.
 * @apiSuccess {Number{..11}}			max_chars				Número de
 * caracteres máximo para o campo de justificar opinião.
 * @apiSuccess {Number{..11}}			min_chars				Número de
 * caracteres mínimo para o campo de justificar opinião.
 * @apiSuccess {String}					datainicio				Data de início
 * que a consulta começou ou vai começar. Deve ser uma data passada, visto que
 * como ela está finalizada, pressupõe-se que já terminou.
 * @apiSuccess {String}					datatermino				Data de término
 * que a consulta finalizou ou vai finalizar. Deve ser uma data passada, visto
 * que como ela está finalizada, pressupõe-se que já terminou.
 * @apiSuccess {Object[]}				[arquivos]				Anexos da
 * consulta.
 * @apiSuccess {String="video","pdf"}	arquivos.tipo			Tipo do anexo,
 * apenas para exibir o ícone correspondente.
 * @apiSuccess {String{..200}}			arquivos.comentarios	Descrição do
 * anexo.
 * @apiSuccess {String{..50}}			[arquivos.tamanho]		Tamanho que o
 * anexo ocupa, utilizado para que o usuário posso saber o que esperar ao
 * abrí-lo.<br /><br />Para o caso que o vídeo seja transmitido via stream
 * (YouTube, Vimeo, Drive, etc...), o parâmetro de tamanho pode ser opcional,
 * visto que o usuário não irá ter que esperar que o vídeo seja baixado por
 * completo para poder assistí-lo.
 * @apiSuccess {String}					arquivos.caminho		URL onde o anexo
 * está disponível publicamente. Esta URL será aberta em uma nova aba, para que
 * o usuário possa acessá-la e voltar a consulta posteriormente.
 * @apiSuccess {Object[]}				questoes			Lista de questões da
 * consulta.
 * @apiSuccess {String}					questoes.titulo
 * @apiSuccess {String{..50}=objetiva,cidades,checkbox,discursiva}	questoes.tipo=null
 * @apiSuccess {Object[]}				[questoes.alternativas] Lista de
 * alternativas caso o tipo seja 'objetiva' ou 'checkbox'.
 * @apiSuccess {Number}					questoes.alternativas.id
 * @apiSuccess {String}					questoes.alternativas.texto
 * 
 * @apiSuccess {String}					questoes.instrucao
 * @apiSuccess {Number}					questoes.posicao=0	Posição relativa as
 * outras questoes da mesma consulta.
 * @apiSuccess {String}					questoes.mascara=''	Máscara jquery para
 * ser usada na questão do tipo discursiva.
 *
 * @apiUse ServiceResponseError
 */
// =============================== API DOC - END ===============================

// ============================== JS DOC - START ===============================
/**
 * @typedef Arquivo
 * @property {"video" | "pdf"} tipo Tipo do anexo, apenas para exibir o ícone
 * correspondente.
 * @property {String} comentarios Descrição do anexo.
 * @property {String} [tamanho] Tamanho que o anexo ocupa, utilizado para que o
 * usuário posso saber o que esperar ao abrí-lo.<br /><br />Para o caso que o
 * vídeo seja transmitido via stream (YouTube, Vimeo, Drive, etc...), o
 * parâmetro de tamanho pode ser opcional, visto que o usuário não irá ter que
 * esperar que o vídeo seja baixado por completo para poder assistí-lo.
 * @property {String} caminho URL onde o anexo está disponível publicamente.
 * Esta URL será aberta em uma nova aba, para que o usuário possa acessá-la e
 * voltar a consulta posteriormente.
 * 
 * @typedef Alternativa
 * @property {Number} id
 * @property {String} texto
 * 
 * @typedef Questao
 * @property {String} titulo
 * @property {"objetiva" | "cidades" | "checkbox" | "discursiva"} tipo
 * @property {Alternativa[]} [alternativas] Lista de alternativas caso o tipo
 * seja 'objetiva' ou 'checkbox'.
 * @property {String} instrucao
 * @property {Number} [posicao=0] Posição relativa as outras questoes da mesma
 * consulta.
 * @property {String} [mascara] Máscara jquery para ser usada na questão do
 * tipo discursiva.
 *
 * @typedef ConsultaCompleta
 * @property {String} nome Nome ou título da consulta
 * @property {String} descricao Descrição da consulta, exibida com o intúito de
 * esclarecer qualquer dúvida que o cidadão possa ter.<br /><br />
 * <b>Atenção</b>: o texto pode conter declarações HTML, entretanto é uma
 * prática que no futuro deve ser substituída por algo como Markdown para evitar
 * que scripts seja injetados no site.
 * @property {Boolean} obrigatorio Determina se o campo de justificar opinião é
 * obrigatório.
 * @property {Number} max_chars Número de caracteres máximo para o campo de
 * justificar opinião.
 * @property {Number} min_chars Número de caracteres mínimo para o campo de justificar opinião.
 * @property {Arquivo[]} [arquivos] Anexos da consulta.
 * @property {Questao[]} questoes Lista de questões da consulta.
 *
 * @function detalhesConsulta
 * @param {Number} consulta_id
 * @return {Promise<ConsultaCompleta>}
 */
// =============================== JS DOC - END ================================
export async function detalhesConsulta(id = 0) {
	const endPoint = `${API_CONSULTA}/${id}`

	return defaultFetch(endPoint)
		.then(r => {
			const error = { code: 404, message: `${capitalizeFirstLetter(process.env.REACT_APP_BRANDING_OBJECT)} não encontrada.` }
			if (typeof r !== 'object') throw error
			return r
		})
		.then(r => {
			if (Array.isArray(r.questoes)) {
				for (const questao of r.questoes) {
					if (questao.tipo === 'discursiva') {
						questao.maxLength = questao.max_chars
						questao.minLength = questao.min_chars
					}
				}
			}
			return r
		})
}

// ============================== API DOC - START ==============================
/**
 * @api {post} /contribuicao/create Enviar contribuição
 * @apiVersion 0.1.0
 * @apiName enviarContribuicao
 * @apiGroup Contribuicao
 * @apiPermission autenticados
 * 
 * @apiDescription Esse endpoint deve registrar a contribuição respondida pelo
 * usuário na tabela <i>CONTRIBUICAO</i>. Os campos <i>datacontribuicao</i> e
 * <i>status</i> devem ser preenchidos pela API, com a hora atual e o valor
 * <i>ativa</i>, respectivamente.
 * <ul><li>Os arquivos serão inseridos na tabela <i>ARQUIVOSCONTRIBUICOES</i>.
 * </li>
 * <li>As questões serão inseridas em diferentes tabelas, dependendo do seu
 * tipo:<ul>
 * 		<li>As respostas discursivas serão inseridas na tabela
 * <i>RESPOSTASDISCURSIVAS</i>.</li>
 * 		<li>As respostas objetivas serão inseridas na tabela
 * <i>RESPOSTAS</i>.</li>
 * 		<li>As respostas múltiplas (checkbox) serão inseridas na tabela
 * <i>RESPOSTASCHECKBOX</i>.</li>
 * 		<li>As respostas de cidades serão inseridas na tabela
 * <i>RESPOSTASCIDADES</i>.
 * </ul></li></ul>
 *
 * @apiHeader {String}	Authorization	Token de autorização, podendo
 * ser do tipo Basic ou do tipo Bearer. No Basic, o nome de usuário com a senha
 * são combinados por dois pontos e convertidos para base64. Não é um método
 * seguro e deve ser evitado pois codificação em base64 não significa
 * encriptação ou hashing. Para resolver isso, utiliza-se o tipo Bearer para se
 * referir a OAuth 2.0, onde no caso utilizaremos JWT.
 *
 * @apiHeaderExample {json} Cabeçalho com email e senha:
 * 				{ "Authorization": "Basic YWxhZGRpbjpvcGVuc2VzYW1l" }
 * @apiHeaderExample {json} Cabeçalho com JWT:
 * 				{ "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c" }
 * 
 * @apiParam {Number} id_consulta Identificação única da consulta.
 * 
 * @apiParam {Object} c Contribuição
 * @apiParam {String} c.id_consulta Identificador da consulta
 * @apiParam {Object[]} questoes Lista de questões com respostas
 * @apiParam {String{..50}=objetiva,cidades,checkbox,discursiva} questoes.tipo=null
 * @apiParam {Number} questoes.questao_id
 * @apiParam {String} questoes.resposta Texto da resposta para o tipo
 * 'discursiva'
 * @apiParam {Number} questoes.alternativa_id Identificação da alternativa
 * para o tipo 'objetiva' e 'checkbox'. Provavelmente também será usada para o
 * tipo 'cidades'.
 * @apiParam {Object[]} [arquivos] TODO: estudar como será o upload.
 * @apiParam {String} respostasugestao Resposta discursiva exibida por padrão
 * em todas as consultas, a fim de que o contribuidor possa justificar sua
 * opinião. Pode não ser obrigatório.
 * 
 * @apiUse ServiceResponseError
 */
// =============================== API DOC - END ===============================

// ============================== JS DOC - START ===============================
/**
 * @typedef TextAnswer
 * @property {"discursiva"} tipo
 * @property {Number} questao_id
 * @property {String} resposta
 *
 * @typedef OptionAnswer
 * @property {"objetiva" | "cidades" | "checkbox"} tipo
 * @property {Number} questao_id
 * @property {Number} alternativa_id
 *
 * @typedef QuestionAnswer
 * @type {TextAnswer | OptionAnswer}
 *
 * @function enviarContribuição
 * @param {Number} idConsultation Identificação única da consulta.
 * @param {QuestionAnswer[]} questions Lista de questões da consulta
 * @param {FileSended[]} files Lista de anexos
 * @param {String} justification Justificativa da opinião do contribuidor.
 */
// =============================== JS DOC - END ================================
export async function enviarContribuição(token, idConsultation, questions, files, justification) {
	const endPoint = `${API_CONTRIBUICAO_RW}/create`
	
	const _pt = await personalToken()

	const c = {
		id_consulta: idConsultation,
		questoes: questions,
		respostasugestao: justification,
		_pt,
	}

	return defaultFetch(endPoint, {
		method: "POST",
		body: JSON.stringify(c),
		headers: {
			'Authorization': `Bearer ${token}`,
		},
	})
}

// ============================== API DOC - START ==============================
// TODO
// =============================== API DOC - END ===============================
// ============================== JS DOC - START ===============================
// TODO
// =============================== JS DOC - END ================================
export function getContribuicao(token, idConsultation) {
	const endPoint = `${API_CONTRIBUICAO_RO}/getContribuicao?idConsulta=${idConsultation}`

	return defaultFetch(endPoint, {
		method: "GET",
		headers: {
			'Authorization': `Bearer ${token}`,
			'Content-Type': 'text/plain',
		},
	}).catch(error => {
		if (error.code === 404) return
		else throw error
	}).then(r => {
		if (typeof r !== 'object') return

		const result = { ticket: r.ticket, contribuicao_livre: r.respostasugestao }

		for (const questao of r.questoes) {

			if (questao.tipo === "objetiva" || questao.tipo === "checkbox" || questao.tipo === "tabela_objetiva" || questao.tipo === "condicional")
				result[questao.questao_id] = questao.alternativa_id.join(",")
			else if (questao.tipo === "discursiva")
				result[questao.questao_id] = questao.resposta
			else if (questao.tipo === "cidades")
				result[questao.questao_id] = questao.cidades.join(',')

			if (questao.texto_outros) {
				result[questao.questao_id + "-outros"] = questao.texto_outros
			}
		}

		return result
	})
}

// ============================== API DOC - START ==============================
// TODO
// =============================== API DOC - END ===============================
// ============================== JS DOC - START ===============================
// TODO
// =============================== JS DOC - END ================================
export function getAllContribuicoes(token) {
	const endPoint = `${API_CONTRIBUICAO_RO}`

	return defaultFetch(endPoint, {
		method: "GET",
		headers: {
			'Authorization': `Bearer ${token}`,
			'Content-Type': 'text/plain',
		},
	})
}

// ============================== API DOC - START ==============================
/**
 * @api {post} /consultation/removeContribution Remover contribuição
 * @apiVersion 0.1.0
 * @apiName RemoverContribuicao
 * @apiGroup Contribuicao
 * @apiPermission autenticados
 * 
 * @apiDescription Esse endpoint deve remover a contribuição respondida pelo
 * usuário na tabela <i>CONTRIBUICAO</i>.
 *
 * @apiHeader {String}	Authorization	Token de autorização, podendo
 * ser do tipo Basic ou do tipo Bearer. No Basic, o nome de usuário com a senha
 * são combinados por dois pontos e convertidos para base64. Não é um método
 * seguro e deve ser evitado pois codificação em base64 não significa
 * encriptação ou hashing. Para resolver isso, utiliza-se o tipo Bearer para se
 * referir a OAuth 2.0, onde no caso utilizaremos JWT.
 *
 * @apiHeaderExample {json} Cabeçalho com email e senha:
 * 				{ "Authorization": "Basic YWxhZGRpbjpvcGVuc2VzYW1l" }
 * @apiHeaderExample {json} Cabeçalho com JWT:
 * 				{ "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c" }
 * 
 * @apiParam {Number} id_consulta Identificação única da consulta.
 * 
 * @apiUse ServiceResponseError
 */
// =============================== API DOC - END ===============================

// ============================== JS DOC - START ===============================
/**
 * @function enviarContribuição
 * @param {Number} idConsultation Identificação única da consulta.
 */
// =============================== JS DOC - END ================================
export function removerContribuição(token, idConsultation) {
	const endPoint = `${API_CONTRIBUICAO_RW}/removeAll`

	return defaultFetch(endPoint, {
		method: "POST",
		body: idConsultation,
		headers: {
			'Authorization': `Bearer ${token}`,
			'Content-Type': 'text/plain',
		},
	})

}

// ============================== API DOC - START ==============================
/**
 * @api {get} /agency/:agencyId/signInForm Formulário de cadastro
 * @apiVersion 0.1.0
 * @apiName FormularioCadastro
 * @apiGroup Orgao
 * @apiPermission todos
 * 
 * @apiDescription Recuperar o formulário de cadastro de um órgão específico.
 * Tais informações estão contidas na tabela <i>CONFIGURACOESCADASTRO</i>. Para
 * o front, só é interessante saber os valores das propriedades <i>campo</i>
 * (texto) e <i>obrigatorio</i> (booleano) para os quais a coluna
 * <i>habilitado</i> é <b>true</b> (ou 1). Então acho que um simples mapa de
 * chave-valor é suficiente. A propriedade <i>categoria</i> também está
 * disponível mas acho que o órgão não é capaz de alterar a categoria de um
 * campo, e portanto cada campo possui sua categoria inerente no sistema. Dessa
 * forma, o front pode agir independentemente dessa informação.
 * 
 * @apiParam {Number} id Identificação única do órgão
 * 
 * @apiUse ServiceResponseError
 * 
 * @apiSuccessExample {json} Exemplo do órgão 15:
 *     HTTP/1.1 200 OK
 *     {
 *       "Escolaridade": true,
 *       "Faixa Etária": true,
 *       "Cidade_Estado": true,
 *       "Endereço": true,
 *       "CEP": true,
 *       "Localização Geográfica": true
 *     }
 * @apiSuccessExample {json} Exemplo do órgão 22:
 *     HTTP/1.1 200 OK
 *     {
 *       "RG": true,
 *       "Celular": false,
 *       "Sexo": true,
 *       "Escolaridade": true,
 *       "Faixa Etária": true,
 *       "Cidade_Estado": true,
 *       "Endereço": true,
 *       "CEP": false
 *     }
 * @apiSuccessExample {json} Exemplo do órgão 37:
 *     HTTP/1.1 200 OK
 *     {
 *       "Celular": false,
 *       "Telefone": false,
 *       "Sexo": true,
 *       "Escolaridade": true,
 *       "Faixa Etária": true,
 *       "Cidade_Estado": true,
 *       "Endereço": true,
 *       "CEP": true,
 *       "Localização Geográfica": true
 *     }
 */
// =============================== API DOC - END ===============================

// ============================== JS DOC - START ===============================
/**
 * @typedef ConfiguracaoCadastro
 * @property {number} id Id do campo
 * @property {string} categoria Categoria do campo
 * @property {string} nome Nome do campo
 * @property {string} mascara Máscara do campo
 * @property {boolean} obrigatorio Se esse campo é obrigatório
 * 
 */

/**
 * @function configuracaoCadastro
 * @param {number} idConsultation
 * @returns {Promise<ConfiguracaoCadastro[]>}
 */
// =============================== JS DOC - END ================================
export function configuracaoCadastro(idConsultation) {
	const endPoint = `${API_CONSULTA}/${idConsultation}/formularioCadastro`

	return defaultFetch(endPoint)
}