/* eslint-disable no-unused-expressions */
import { useContext, useRef, useCallback } from "react"

import { PageContext } from "../../../../pages"
import { useStepper } from "../../../Stepper/utils"
import { getFormData, resetCustomValidity } from "../../../../utils"
import { updateUser } from "../../../../services/Auth_API";
import { updateStorageProfile } from "../../utils";
import { isValidLandlinePhone, isValidCEP, isValidMobilePhone, isValidCPF } from "@brazilian-utils/brazilian-utils";

export const useLogic = () => {
	const formRef = useRef(),
		submitRef = useRef({}),
		{ next } = useStepper(),
		{ setLoading } = useContext(PageContext)

	const validateForm = useCallback(() => {
		const data = getFormData(formRef.current)

		let msg, target

		if (data.cpfcnpj && !isValidCPF(data.cpfcnpj)) {
			msg = "CPF inválido."
			target = '[name=cpfcnpj]'
		} else if (data.telefone && !isValidMobilePhone(data.telefone)) {
			msg = "Número inválido."
			target = '[name=telefone]'
		} else if (data.telefone2 && !isValidLandlinePhone(data.telefone2)) {
			msg = "Número inválido."
			target = '[name=telefone2]'
		} else if (data.cep && !isValidCEP(data.cep)) {
			msg = "CEP inválido."
			target = '[name=cep]'
		}

		if (msg) formRef.current?.querySelector(target).setCustomValidity(msg)
	}, [])

	const handleNext = useCallback(async e => {
		resetCustomValidity(e.target)
		resetCustomValidity(formRef.current)
		submitRef.current.target = e.target

		setLoading(true)
		validateForm()

		if (formRef.current?.checkValidity()) {
			return new Promise(res => {
				submitRef.current.res = res
			})
		}

		formRef.current?.reportValidity()
		setLoading(false)
		return false
	}, [setLoading, validateForm])

	const handleSubmit = useCallback(e => {
		e.preventDefault()

		const form = formRef.current
		const data = getFormData(form)

		const newProfile = updateStorageProfile(data)
		const token = sessionStorage.getItem('auth-token')

		updateUser(token, newProfile).then(r => !!r)
			.catch(error => {
				submitRef.current.target.setCustomValidity(error.message)
				form?.reportValidity()
				return false
			})
			.then(v => {
				const { res } = submitRef.current
				res?.(v)
			})
			.finally(() => setLoading(false))
	}, [setLoading])

	return {
		formRef,
		handleNext, handleSubmit, handleBtnClick: next,
	}
}
