/* eslint-disable no-unused-expressions */
import { useContext, useRef, useCallback,  useState } from "react"
import { isValidCPF, isValidMobilePhone, isValidLandlinePhone, capitalize } from '@brazilian-utils/brazilian-utils';

import { PageContext } from "../../../../pages"
import { useStepper } from "../../../Stepper/utils"
import { signUp, updateUser } from "../../../../services"
import { getFormData, resetCustomValidity } from "../../../../utils"
import { updateStorageProfile } from "../../utils";
import { modalView, timing } from "services/GA";
import retryForm from "utils/retryForm";

export const useLogic = ({ _map }) => {
	const formRef = useRef(),
		submitRef = useRef({}),
		{ next } = useStepper(),
		{ setLoading } = useContext(PageContext),
		[hasError, setError] = useState(false)
	
	const [data] = useState(JSON.parse(sessionStorage.getItem('auth-profile')) || {})
	if (data.nome) {
		const field = _map.sec.fields.find(f => f.name === 'nome')
		if (field) field.readOnly = true
		// field.disabled = true
		// field.required = false
	}

	const validateForm = useCallback(() => {
		resetCustomValidity(submitRef.current.target)
		resetCustomValidity(formRef.current)

		const data = getFormData(formRef.current)

		switch (_map.sec.id) {
			case "regStepId":
				if (data.nome) {
					const msg1 = data.nome.trim().includes(" ") ? "" : "Por favor, insira seu nome e sobrenomes."
					formRef.current?.querySelector('[name=nome]')
						?.setCustomValidity(msg1)
				}

				if (data.cpfcnpj) {
					const msg1 = isValidCPF(data.cpfcnpj) ? "" : "CPF inválido."
					formRef.current?.querySelector('[name=cpfcnpj]')
						?.setCustomValidity(msg1)
				}
				break;
				
			case "regStepContact":
				if (data.telefone) {
					const msg2 = isValidMobilePhone(data.telefone) ? "" : "Número inválido."
					formRef.current?.querySelector('[name=telefone]')
						?.setCustomValidity(msg2)
				}
				if (data.telefone2) {
					const msg3 = isValidLandlinePhone(data.telefone2) ? "" : "Número inválido."
					formRef.current?.querySelector('[name=telefone2]')
						?.setCustomValidity(msg3)
				}
				break;
		
			default:
				break;
		}
	}, [_map])

	const handleNext = useCallback(async e => {
		submitRef.current.target = e.target

		setLoading(true)
		validateForm()

		if (formRef.current?.checkValidity()) {
			return new Promise(res => {
				submitRef.current.res = res
			})
		}

		setLoading(false)
		formRef.current?.reportValidity()
		return false
	}, [setLoading, validateForm])

	const handleSubmit = useCallback(e => {
		e.preventDefault()

		setError(false)

		const form = formRef.current
		const data = getFormData(form)

		if (data.nome) data.nome = capitalize(data.nome)

		const newProfile = updateStorageProfile(data)

		if (_map.i === _map.arr.length - 1) { // last section
			const token = sessionStorage.getItem('auth-token')

			let service
			if (token)	service = updateUser(token, newProfile)
			else		service = signUp(newProfile)

			service.then(r => !!r)
				.catch(message => {
					setError(retryForm(message, e, () => setError(false)))
					return false
				})
				.then(v => {
					setLoading(false)
					if (!token) {
						modalView("Cadastro feito")

						const start = window.sessionStorage.getItem("timeRegistrationStarted")
						if (start) {
							timing({
								category: "Autenticação",
								variable: "Cadastro",
								value: Date.now() - Number(start),
							})
						}
					}
					const { res } = submitRef.current
					res?.(v)
				})
		} else {
			setLoading(false)
			const { res } = submitRef.current
			res?.(true)
		}

		
	}, [setLoading, _map])

	return {
		handleNext, formRef, handleSubmit,
		formId: _map.sec.id+"Form",
		handleBtnClick: next,
		..._map.sec, data,
		hasError,
	}
}
