/* eslint-disable no-unused-expressions */
import React, { useCallback, useRef, useState } from 'react'
import { MDBNav, MDBNavItem, MDBNavLink, MDBIcon, MDBInput, MDBTooltip } from 'mdbreact'
import { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { fixDbMasks, mask } from 'utils'
import { isValidCNPJ, isValidCPF, isValidPhone } from '@brazilian-utils/brazilian-utils'

const Interim = styled.span`
	display: inline-block;
	color: gray;
	padding-top: 9px;
	box-sizing: border-box;
	text-align: right;
	white-space: nowrap;
	overflow: hidden;
	direction: rtl;
	text-overflow: ellipsis;
	width: 100%;
`

const TABS = {
	DIGITAR: "DIGITAR",
	FALAR: "FALAR"
}

function LOG(...text) {
	console.log(...text)
}

const DEFAULT_MESSAGE = "Usar o microfone"

const pulse = keyframes`
	0% {
		box-shadow: 0 0 0 0 rgba(var(--primaryBg-RGB), 0.7);//rgba(52, 172, 224, 0.7);
	}
	
	70% {
		box-shadow: 0 0 0 10px rgba(var(--primaryBg-RGB), 0);//rgba(52, 172, 224, 0);
	}
	
	100% {
		box-shadow: 0 0 0 0 rgba(var(--primaryBg-RGB), 0);//rgba(52, 172, 224, 0);
	}
`

const PulseIcon = styled(MDBIcon)`
	.active & {
		color: orangered;
		border-radius: 50%;
		box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
		animation: ${pulse} 2s infinite;
	}
`

const MDBInputStyled = styled(MDBInput)`
	resize: vertical;
	overflow-y: visible !important;
`

const MyNavLink = styled(MDBNavLink)`
	&.active {
		color: var(--primaryBg) !important;
	}

	&:not(.active) {
		color: grey;
	}
`

const InputContainer = styled.div`
	.md-form {
		border-color: #dee2e6;
		border-style: solid;
		border-width: 0;
		${props => props.hasTabs && `
		margin-top: 0;
		border-left-width: 1px;
		border-right-width: 1px;
		`};
	}

	&& textarea {
		padding-left: 10px;
		padding-right: 10px;
	}
`

const InputQuestaoDiscursivaNotDisabled = ({
	id, name, label, disabled, resposta, onChange, minLength, maxLength, mascara, required, containerClass, ...props
}) => {
	const recognition = useRef({}),
		[support, setSupport] = useState(true)

	useEffect(() => {
		const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition

		if (!SpeechRecognition) {
			setSupport(false)
			return
		}

		const recog = new SpeechRecognition()

		recog.continous = true
		recog.interimResults = true

		recognition.current = recog
	}, [])

	const [modoDeComentar, setModoDeComentar] = useState(TABS.DIGITAR),
		[allowed, setAllowed] = useState(false),
		[finalTranscript, setFinalTranscript] = useState(resposta),
		[interimTranscript, setInterimTranscript] = useState(""),
		[tooltipMessage, setTooltipMessage] = useState("Verificando disponibilidade..."),
		[tooltipVisible, setTooltipVisible] = useState(!1)

	const changeTab = useCallback(id => () => {
		if (id === TABS.FALAR && !allowed)
			return navigator.mediaDevices.getUserMedia({ audio: true })
				.then(function (stream) {
					LOG('You let me use your mic!')
					stream.getTracks().forEach(function (track) {
						track.stop();
					});

					setAllowed(true)
					setTooltipMessage(DEFAULT_MESSAGE)
					setTooltipVisible(false)
					setModoDeComentar(id)
				})
				.catch(function (err) {
					setTooltipMessage("Permissão negada para user o microfone, altere a configuração no seu dispositivo.")
					LOG('You blocked me to use your mic!')
				})
		setModoDeComentar(id)
	}, [allowed])

	useEffect(() => {
		if (disabled || mascara) return

		if (!navigator.mediaDevices) {
			setTooltipMessage("Microfone indisponível!")
			return
		}

	}, [disabled, mascara])

	const checkValidity = useCallback((elem) => {
		if (elem.value === '') {
			elem.classList.remove('invalid')
			elem.classList.remove('valid')
			return
		}

		const size = elem.value.length
		let message = ""
		if (size && minLength && size < minLength) {
			message = `Sua resposta deve ter no mínimo ${minLength} caracteres.`
		} else if (size && maxLength && size > maxLength) {
			message = `Sua resposta deve ter no máximo ${maxLength} caracteres.`
		} else if (mascara) {
			const value = elem.value
			switch (mascara) {
				// lccl.qa.gm.data
				case "mask('99/99/9999')": // pt_BR | es_PE | en_US
					if (!/^(((0[1-9]|[12][0-9]|3[01])[- /.](0[13578]|1[02])|(0[1-9]|[12][0-9]|30)[- /.](0[469]|11)|(0[1-9]|1\d|2[0-8])[- /.]02)[- /.]\d{4}|29[- /.]02[- /.](\d{2}(0[48]|[2468][048]|[13579][26])|([02468][048]|[1359][26])00))$/i.test(value)) {
						message = "Não é uma data válida."
					}
					break;

				// lccl.qa.gm.telefone
				case "mask('(99)9999-9999')": // pt_BR | es_PE | en_US
					if (!isValidPhone(value)) {
						message = "Não é um telefone válido."
					}
					break;

				// lccl.qa.gm.cpf
				case "mask('999.999.999-99')": // pt_BR
					if (!isValidCPF(value)) {
						message = "Não é um CPF válido."
					}
					break;

				// lccl.qa.gm.cnpj
				case "mask('99.999.999/9999-99')": // pt_BR
					if (!isValidCNPJ(value)) {
						message = "Não é um CNPJ válido."
					}
					break;

				default:
					break;
			}
		}

		elem.scrollIntoView({ block: "center", behavior: "smooth" })
		elem.setCustomValidity(message)
		if (elem.checkValidity()) {
			elem.classList.remove('invalid')
			elem.classList.add('valid')
		} else {
			elem.classList.add('invalid')
			elem.classList.remove('valid')
		}
		setTimeout(() => elem.reportValidity(), 500)
	}, [minLength, maxLength, mascara])

	useEffect(() => {
		if (disabled) return

		const listening = modoDeComentar === TABS.FALAR
		if (listening && allowed) {
			recognition.current.start?.()
			recognition.current.onend = () => {
				LOG("...continue listening...")
				recognition.current.start()
			}

		} else {
			recognition.current.stop?.()
			recognition.current.onend = () => {
				LOG("Stopped listening per click")
				allowed && setTooltipMessage(DEFAULT_MESSAGE)
			}
		}

		recognition.current.onstart = () => {
			LOG("Listening!")
			setTooltipMessage("Ouvindo... Toque novamente para parar")
			setTooltipVisible(true)
		}

		let finalTranscript = ''
		recognition.current.onresult = event => {
			let interimTranscript = ''

			for (let i = event.resultIndex; i < event.results.length; i++) {
				const transcript = event.results[i][0].transcript;
				if (event.results[i].isFinal) finalTranscript += transcript + ' ';
				else interimTranscript += transcript;
			}
			setInterimTranscript(interimTranscript)

			setFinalTranscript(x => (x + ' ' + finalTranscript).trim())
			if (typeof onChange === 'function') onChange({ target: inputRef.current })
			checkValidity(inputRef.current)

			finalTranscript = ''
		}

		recognition.current.onerror = event => {
			LOG("Error occurred in recognition: " + event.error)

			switch (event.error) {
				case "not-allowed":
					setAllowed(false)
					setTooltipMessage("Microfone indisponível!")
					setTooltipVisible(true)
					setModoDeComentar(TABS.DIGITAR)
					break;

				default:
					break;
			}
		}

		return () => recognition.current.stop?.()
	}, [modoDeComentar, id, allowed, disabled, onChange, checkValidity])

	const inputRef = useRef()

	useEffect(() => {
		if (inputRef.current)
			checkValidity(inputRef.current)
	}, [checkValidity])

	const changeHandler = useCallback(e => {
		setFinalTranscript(e.target.value)
		onChange?.(e)

		checkValidity(e.target)
	}, [onChange, checkValidity])

	useEffect(() => {
		const { mask: realMask, opts } = fixDbMasks(mascara)
		mask(realMask, opts)(inputRef.current)
	}, [mascara])

	return <InputContainer hasTabs={!mascara} className={containerClass}>
		{!mascara && <MDBNav className="nav-tabs">
			<MDBNavItem>
				<MyNavLink
					link
					to="#"
					active={modoDeComentar === TABS.DIGITAR}
					onClick={changeTab(TABS.DIGITAR)}
				>
					<MDBIcon icon="pencil-alt" />
				</MyNavLink>
			</MDBNavItem>
			{support && <>
				<MDBNavItem>
					<MDBTooltip
						placement="left"
						isVisible={tooltipVisible}
					>
						<MyNavLink
							link
							to="#"
							active={modoDeComentar === TABS.FALAR}
							disabled={disabled}
							onClick={changeTab(modoDeComentar === TABS.FALAR ? TABS.DIGITAR : TABS.FALAR)}
						>
							<PulseIcon icon={modoDeComentar === TABS.FALAR ? "stop" : "microphone"} />
						</MyNavLink>
						<span>{tooltipMessage}</span>
					</MDBTooltip>
				</MDBNavItem>
				<MDBNavItem style={{ maxWidth: '50%', marginLeft: "10px" }}>
					<Interim id="interim">
						{interimTranscript}
					</Interim>
				</MDBNavItem>
			</>}
		</MDBNav>}
		<MDBInputStyled
			inputRef={x => inputRef.current = x}
			id={`${id}`}
			name={name}
			type={mascara ? "text" : "textarea"}
			inputMode={mascara && mascara !== "not_empty" ? "numeric" : "text"}
			label={label}
			rows="3"
			disabled={disabled}
			readOnly={modoDeComentar !== TABS.DIGITAR}
			value={finalTranscript}
			onChange={changeHandler}
			data-prevent-reset-custom-message
			counter={maxLength || null}
			required={required === false ? false : true}
			containerClass={containerClass}
			{...props}
		/>
	</InputContainer>
}

const InputQuestaoDiscursivaDisabled = ({ id, resposta, name }) => {
	return <div>
		<input type="hidden" name={name} value={resposta} id={id} />
		{resposta}
	</div>
}

const InputQuestaoDiscursiva = ({ disabled, ...props }) => {
	return disabled ? <InputQuestaoDiscursivaDisabled {...props} /> :
		<InputQuestaoDiscursivaNotDisabled {...{ ...props, disabled }} />
}

export default InputQuestaoDiscursiva