export const sections = [{
	id: "regStepId",
	title: "Identificação",
	fields: [{
		type: "text",
		name: "nome",
		label: "Nome Completo",
		icon: "user",
		'data-alt-name': 'nome',
	}, {
		type: "text",
		name: "cpfcnpj",
		label: "CPF",
		icon: "id-card",
		mask: "9{3}.9{3}.9{3}-9{2}",
		inputMode: 'numeric',
		'data-alt-name': 'CPF',
	}, {
		type: "text",
		name: "rg",
		label: "RG",
		icon: "id-card",
		inputMode: 'numeric',
		'data-alt-name': 'RG',
	}],
	buttonTxt: "Avançar",
}, {
	id: "regStepContact",
	title: "Contato",
	fields: [{
		type: "text",
		name: "telefone",
		label: "Celular",
		required: true,
		icon: "mobile",
		mask: "(9{2}) [9]9999-9999",
		'data-alt-name': 'Celular',
	}, {
		type: "text",
		name: "telefone2",
		label: "Telefone",
		required: true,
		icon: "phone",
		mask: "(9{2}) 9999-9999",
		'data-alt-name': 'Telefone',
	}],
	buttonTxt: "Avançar",
}, {
	id: "regStepLoc",
	title: "Localização",
	fields: [{
		// type: "Cidade_Estado",
		// name: "cidadeEstado",
		// label: "Cidade",
		// required: true,
		// readonly: false,
		// icon: "city",
		// options: getCities()
		// 	.map(s => [{
		// 		text: s.name, separator: true
		// 	}, ...s.cities
		// 		.map(c => ({
		// 			text: [c, s.code].join(" - "),
		// 			value: [c, s.code].join(" - "),
		// 		}))
		// 	])
		// 	.reduce((acc, cur) => [...acc, ...cur], []),
		// search: true,
		// searchLabel: "Buscar",
		// 'data-alt-name': 'Endereço',
		type: "EstadoCidade",
		name: ["estado", "cidade"],
		label: ["Estado", "Cidade"],
		required: true,
		autoComplete: ["off", "off"], // Devido a https://gitlab.com/nbssistemas/conpub/pwa/-/issues/194 é necessário remover o "address-level1", "address-level2"
		// disabled: true,
		'data-alt-name': 'Cidade_Estado',
	}, {
		name: "endereco",
		label: "Endereço",
		icon: "route",
		autoComplete: "street-address",
		'data-alt-name': 'Endereço',
	}, {
		name: "cep",
		label: "CEP",
		icon: "road",
		mask: "9{5}-9{3}",
		autoComplete: "postal-code",
		'data-alt-name': 'Endereço',
	}, {
		type: "Map",
		name: "latitudeLongitude",
		label: "Localização Geográfica",
		icon: "map-marked",
		'data-alt-name': 'Localização Geográfica',
	}],
	buttonTxt: "Avançar",
}, {
	id: "regStep5",
	title: "Mapeamento",
	fields: [{
		type: "select",
		name: "sexo",
		id: "selectSexo",
		label: "Sexo",
		icon: "venus-mars",
		options: ["Masculino", "Feminino"].map(x => ({text: x, value: x})),
		'data-alt-name': 'Sexo',
	}, {
		type: "select",
		name: "escolaridade",
		id: "selectEscolaridade",
		label: "Escolaridade",
		icon: "chalkboard-teacher",
		options: ["Sem instrução", "Ensino fundamental ou 1º grau incompleto", "Ensino fundamental ou 1º grau completo", "Ensino médio ou 2º grau incompleto", "Ensino médio ou 2° grau completo", "Ensino superior incompleto", "Ensino superior completo", "Ensino superior completo com pós-graduação"].map(x => ({text: x, value: x})),
		// options: [{
		// 	value: "SEM_INSTRUCAO",
		// 	text: "Sem instrução",
		// }, {
		// 	value: "FUNDAMENTAL_INCOMPLETO",
		// 	text: "Ensino fundamental ou 1º grau incompleto",
		// }, {
		// 	value: "FUNDAMENTAL_COMPLETO",
		// 	text: "Ensino fundamental ou 1º grau completo",
		// }, {
		// 	value: "MEDIO_INCOMPLETO",
		// 	text: "Ensino médio ou 2º grau incompleto",
		// }, {
		// 	value: "MEDIO_COMPLETO",
		// 	text: "Ensino médio ou 2° grau completo",
		// }, {
		// 	value: "SUPERIOR_INCOMPLETO",
		// 	text: "Ensino superior incompleto",
		// }, {
		// 	value: "SUPERIOR_COMPLETO",
		// 	text: "Ensino superior completo",
		// }, {
		// 	value: "POS_GRADUACAO",
		// 	text: "Ensino superior completo com pós-graduação",
		// }],
		'data-alt-name': 'Escolaridade',
	}, {
		type: "select",
		name: "faixaEtaria",
		id: "selectFaixaEtaria",
		label: "Faixa Etária",
		icon: "birthday-cake",
		options: ["Até 17 anos", "De 18 até 24 anos", "De 25 até 39 anos", "40 anos ou mais"].map(x => ({text: x, value: x})),
		// options: [
		// 	{ value: "ATE_17", text: "Até 17 anos" },
		// 	{ value: "DE_18_ATE_24", text: "De 18 até 24 anos" },
		// 	{ value: "DE_25_ATE_39", text: "De 25 até 39 anos" },
		// 	{ value: "MAIS_DE_40", text: "40 anos ou mais" },
		// ],
		'data-alt-name': 'Faixa Etária',
	}, {
		type: "select",
		name: "faixaSalarial",
		id: "selectFaixaSalarial",
		label: "Renda Familiar",
		icon: "comments-dollar",
		options: ["Acima de 20 salários mínimos", "Entre 10 e 20 salários mínimos", "Entre 4 e 10 salários mínimos", "Entre 2 e 4 salários mínimos", "Até 2 salários mínimos"].map(x => ({text: x, value: x})),
		// options: [
		// 	{ value: "ACIMA_20", text: "Acima de 20 salários mínimos" },
		// 	{ value: "ENTRE_10_E_20", text: "Entre 10 e 20 salários mínimos" },
		// 	{ value: "ENTRE_4_E_10", text: "Entre 4 e 10 salários mínimos" },
		// 	{ value: "ENTRE_2_E_4", text: "Entre 2 e 4 salários mínimos" },
		// 	{ value: "ATE_2", text: "Até 2 salários mínimos" },
		// ],
		'data-alt-name': 'Renda Familiar',
	}, {
		type: "select",
		name: "tratamento",
		id: "selectTratamento",
		label: "Tratamento",
		icon: "comments",
		options: ["Senhor", "Senhora"].map(x => ({text: x, value: x})),
		'data-alt-name': 'Tratamento',
	}],
	buttonTxt: "Concluir Cadastro",
}]
