import { ContaDados, ContaPerfil } from "pages";
import { Redirect, Route, Switch } from "react-router";

export const Conta = () => <Switch>
	<Route path="/conta/perfil" component={ContaPerfil} />
	{/* <Route path="/conta/seguranca" component={ContaSeguranca} /> */}
	{/* <Route path="/conta/notificacoes" component={ContaNotificacoes} /> */}
	{/* <Route path="/conta/preferencias" component={ContaPreferencias} /> */}
	<Route path="/conta/dados" component={ContaDados} />
	<Route render={() => <Redirect to="/conta/perfil" />} />
</Switch>
