import React, { useState, useEffect } from 'react'
import { MDBListGroup } from 'mdbreact';
import styled from 'styled-components'

import { Spinner, Pagination, Masonry, NonIdealState } from '.';
import { useCallback } from 'react';

const ListGroup = styled(MDBListGroup)`
	margin-left: -17px;
	margin-right: -17px;
`

export default function Listagem({ query, Card, type = 'masonry', maxResults, Empty, ...props}) {
	const [loading, setLoading] = useState(true)
	const [consultas, setConsultas] = useState([])
	const [hasError, setError] = useState(false)

	let wrapper
	switch (type) {
		case 'list':
			wrapper = ListGroup
			break;

		default:
			wrapper = Masonry
			break;
	}

	const runQuery = useCallback(() => {
		setLoading(true)
		setError(false)
		query && query()
			.then(setConsultas)
			.catch(setError)
			.finally(() => setLoading(false))
	}, [query])

	useEffect(() => {
		runQuery()
	}, [runQuery])

	return (
		<>
			{loading ? <Spinner style={{ margin: "100px 0 0 calc(50% - 1rem)" }} /> : hasError ? <NonIdealState
				icon={hasError.icon || "fixing_bugs"}
				title={hasError.title || "Ocorreu um erro"}
				description={hasError.description || ""}
				action={<button onClick={runQuery} className="bp3-button">Tentar novamente</button>}
			/> : consultas.length ? <Pagination
				data={consultas}
				map={c => <Card consulta={c} key={c.id} />}
				maxResults={maxResults || 5}
				{...{ wrapper, ...props }}
			/> : <Empty />}
		</>
	)
}