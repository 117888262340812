import { useCallback, useState, useEffect } from "react"

const stateKey = "cookiesAccepted"
const stateManager = {
	get: () => !!localStorage.getItem(stateKey),
	set: v => v ? localStorage.setItem(stateKey, true) : localStorage.removeItem(stateKey),
}

export const useAcceptCookiesLogic = () => {
	const [hide, setHide] = useState(stateManager.get())
	const [remove, setRemove] = useState(false)

	const actionHandler = useCallback(() => {
		stateManager.set(true)
		setHide(true)
	}, [])

	useEffect(() => {
		if (hide) setTimeout(setRemove, 1000, true)
	}, [hide])

	return {
		hide,
		remove,
		actionHandler,
	}
}
