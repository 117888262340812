import { Icon, UL } from "@blueprintjs/core";
import { MDBInput as Input } from "mdbreact";
import { useCallback } from "react";
import { useState } from "react";
import styled from "styled-components";
import { useToggle } from "utils";
import { checkPassStrength, defaultReq } from "utils/checkPassStrength";

const Container = styled.div`
	position: relative;

	.bp3-icon {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		padding: 10px;
	}

	&& .form-control {
		padding-right: 35px;
	}
`

const ReqList = styled(UL)`
	margin-top: -10px;
	margin-bottom: 24px;
	list-style: none;
	font-size: .9em;

	&& li {
		margin-bottom: 0;
	}

	.bp3-icon {
		margin-right: 5px;
	}
`

const Requirements = ({ password }) => {
	const result = checkPassStrength(password, true)

	const
		atLeast1LowercaseLetter = result.includes("atLeast1LowercaseLetter"),
		atLeast1CapitalLetter = result.includes("atLeast1CapitalLetter"),
		atLeast1Number = result.includes("atLeast1Number"),
		atLeast1SpecialCharacter = result.includes("atLeast1SpecialCharacter"),
		atLeastNCharactersInTotal = result.includes("atLeastNCharactersInTotal")

	return <ReqList>
		{defaultReq.atLeast1LowercaseLetter && <li><Icon icon={!atLeast1LowercaseLetter ? "tick" : "cross"} />Possui 1 minúscula</li>}
		{defaultReq.atLeast1CapitalLetter && <li><Icon icon={!atLeast1CapitalLetter ? "tick" : "cross"} />Possui 1 maiúscula</li>}
		{defaultReq.atLeast1Number && <li><Icon icon={!atLeast1Number ? "tick" : "cross"} />Possui 1 número</li>}
		{defaultReq.atLeast1SpecialCharacter && <li><Icon icon={!atLeast1SpecialCharacter ? "tick" : "cross"} />Possui 1 caractere especial</li>}
		{defaultReq.atLeastNCharactersInTotal && <li><Icon icon={!atLeastNCharactersInTotal ? "tick" : "cross"} />Possui {defaultReq.atLeastNCharactersInTotal} caracteres</li>}
	</ReqList>
}

export const InputPassword = ({ showRequirements, ...props }) => {
	const [state, change] = useToggle(false)

	const [password, setPassword] = useState("")
	const onChange = useCallback(e => {
		const value = e.target.value
		setPassword(value)

		if (typeof props.onChange === 'function') props.onChange(value)
	}, [props])

	return <>
		<Container>
			<Input
				{...props}
				type={state ? "text" : "password"}
				onChange={onChange}
			/>
			<Icon
				icon={state ? "eye-off" : "eye-open"}
				onClick={change}
				htmlTitle="Mostrar senha"
			/>
		</Container>
		{showRequirements && <Requirements {...{ password }} />}
	</>
}