/**
 * @param {React.ReactChildren} children
 * @param {Number} cursor
 * @return {React.ReactChild}
 */
export const useStep = (children, cursor) => {
	let current

	if (children instanceof Array && cursor >= 0) {
		if (children[cursor]) {
			current = children[cursor]
		} else {
			current = null
		}
	} else if (children && cursor >= 0) {
		current = children
	} else {
		current = null
	}

	return current
}
