import React from 'react';
import { Link } from 'react-router-dom';
import {
	MDBCard, MDBCardBody, MDBCardText, MDBCardHeader, MDBCardFooter,
	MDBIcon,
} from 'mdbreact';
import styled from 'styled-components';
import moment from 'moment'

const CardHeader = styled(MDBCardHeader)`
	color: var(--CardHeader_color);
	background-color: var(--CardHeader_bg_color) !important;
	font-weight: 500;
`

const CardBody = styled(MDBCardText)`
	overflow: hidden;
`

function CardButtonWithoutStyle({ to, userToken, className }) {
	return <Link to={to} className={`${className} btn btn-sm`}>
		{userToken ? 'Contribuir' : 'Saiba mais'}
	</Link>
}

const CardButton = styled(CardButtonWithoutStyle)`
	color: var(--CardButton_color) !important;
	background-color: var(--CardButton_bg_color) !important;
	display: inline-block;
`

export default function CardConsultaAtiva({ consulta, userToken }) {
	const dataFinalizada = moment
		.utc(consulta.datatermino.replace("[UTC]", ""))
		.utcOffset(consulta.fusoHorario)
		.local()

	return (
		<MDBCard className={`text-center`}>
			<CardHeader>
				{consulta.nome}
			</CardHeader>
			<MDBCardBody>
				<CardBody>
					<span dangerouslySetInnerHTML={{ __html: consulta.ementa }} />
				</CardBody>
				<CardButton
					to={`detalhes/${consulta.id}`}
					userToken={userToken}
				/>
			</MDBCardBody>
			<MDBCardFooter className="text-muted">
				<small title={dataFinalizada.format("dddd, D [de] MMMM [de] YYYY [às] HH:mm:ss")}>
					<MDBIcon icon="hourglass-end" />
					{' '}Finaliza {dataFinalizada.fromNow()}
				</small>
			</MDBCardFooter>
		</MDBCard>
	)
}
