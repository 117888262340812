import React from 'react'

import { Default as DefaultLayout } from './layouts'
import { LayoutContext, StepperContext } from './utils/contexts'

/**
 * @typedef PresenterProps
 * @property {import('../../utils/useAutoScroll').AutoScrollRef} scrollRef
 * @property {import('./utils/contexts/stepper').StepperContext} StepperContextValue
 * @property {import('./utils/contexts/layout').LayoutContext} LayoutContextValue
 * @property {React.ReactChild} step
 * @property {import('./layouts').StepperLayout} layout
 */

/**
 * @param {PresenterProps} props
 */
export const Presenter = ({
	scrollRef, LayoutContextValue, StepperContextValue, step,
	layout: Layout = DefaultLayout,
}) => <>
	<div ref={scrollRef}></div>
	<LayoutContext.Provider value={LayoutContextValue}>
		<StepperContext.Provider value={StepperContextValue}>
			{step}

			<Layout />
		</StepperContext.Provider>
	</LayoutContext.Provider>
</>
