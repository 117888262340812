import { FaAngleLeft } from "react-icons/fa";
import {
	UsuarioPageAside, UsuarioPageFooter, UsuarioPageHeader, UsuarioPageMain,
	UsuarioPerfil,
} from "./elements";
import { UsuarioPageContainer, UsuarioPageSection } from "./styled";
import { Button } from "./styled/Button";

export const UsuarioPageLayoutPresenter = ({ backUrl, orgName, clearBackUrl, children }) => <UsuarioPageContainer>
	<UsuarioPageHeader />
	{backUrl && <div className="page-container">
		<Button href={backUrl} onClick={clearBackUrl} intent="primary"><FaAngleLeft />Voltar {orgName && <> para <b>{orgName}</b></> }</Button>
	</div>}
	<UsuarioPerfil />
	<UsuarioPageSection className="page-container">
		<UsuarioPageAside />
		<UsuarioPageMain>{children}</UsuarioPageMain>
	</UsuarioPageSection>
	<UsuarioPageFooter />
</UsuarioPageContainer>
