import { FaUserCircle } from "react-icons/fa"
import styled from "styled-components"

const Container = styled.div`
	display: grid;
	grid-template-columns: min-content 1fr;
	grid-template-rows: repeat(2, 1fr);
	grid-column-gap: 15px;
	grid-row-gap: 0px;

	svg {
		grid-area: 1 / 1 / 3 / 2;
		font-size: 60px;
	}
	b {
		grid-area: 1 / 2 / 2 / 3;
		align-self: end;
		font-size: 24px;
		font-weight: bolder;
		line-height: 1em;
	}
	span {
		grid-area: 2 / 2 / 3 / 3;
		align-self: start;
		font-size: 18px;
		font-weight: lighter;
		line-height: 1em;
	}
`

export const UsuarioPerfil = () => {
	const profileStr = sessionStorage.getItem('auth-profile')
	if (!profileStr) return null

	const { nome } = JSON.parse(profileStr)

	return <Container className="page-container">
		<FaUserCircle />
		<b>{nome}</b>
		<span>Sua conta pessoal</span>
	</Container>
}