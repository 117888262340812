import { useRef, useEffect } from 'react'

import { ReactComponent as UndrawAgree } from '../assets/undraw/agree.svg'
import { ReactComponent as UndrawBlankCanvas } from '../assets/undraw/blank_canvas.svg'
import { ReactComponent as UndrawChoice } from '../assets/undraw/choice.svg'
import { ReactComponent as UndrawCompletedTasks } from '../assets/undraw/completed_tasks.svg'
import { ReactComponent as UndrawEmpty } from '../assets/undraw/empty.svg'
import { ReactComponent as UndrawFillForms } from '../assets/undraw/fill_forms.svg'
import { ReactComponent as UndrawFixingBugs } from '../assets/undraw/fixing_bugs.svg'
import { ReactComponent as UndrawInbox } from '../assets/undraw/inbox.svg'
import { ReactComponent as UndrawMyPassword } from '../assets/undraw/my_password.svg'
import { ReactComponent as UndrawNoData } from '../assets/undraw/no_data.svg'
import { ReactComponent as UndrawNotify } from '../assets/undraw/notify.svg'
import { ReactComponent as UndrawPageNotFound } from '../assets/undraw/page_not_found.svg'
import { ReactComponent as UndrawPreferences } from '../assets/undraw/preferences.svg'
import { ReactComponent as UndrawProfile } from '../assets/undraw/profile.svg'
import { ReactComponent as UndrawProjectCompleted } from '../assets/undraw/project_completed.svg'
import { ReactComponent as UndrawQuestions } from '../assets/undraw/questions.svg'
import { ReactComponent as UndrawQuiteTown } from '../assets/undraw/quite_town.svg'
import { ReactComponent as UndrawServerDown } from '../assets/undraw/server_down.svg'
import { ReactComponent as UndrawServer } from '../assets/undraw/server.svg'
import { ReactComponent as UndrawSocialBio } from '../assets/undraw/social_bio.svg'
import { ReactComponent as UndrawSurvey } from '../assets/undraw/survey.svg'
import { ReactComponent as UndrawThrowAway } from '../assets/undraw/throw_away.svg'
import { ReactComponent as UndrawTimeManagement } from '../assets/undraw/time_management.svg'
import { ReactComponent as UndrawUnexpectedFriends } from '../assets/undraw/unexpected_friends.svg'

// const CONVERT_NAMES_TO_IMPORTS = names => names.split("\n").map(x => `import { ReactComponent as ${x} } from '../assets/undraw/${x.replace(/^Undraw/, "").replaceAll(/([A-Z])/g, "_$1").slice(1).toLowerCase()}.svg'`).join("\n")

const COMPONENTS = {
	'agree': UndrawAgree,
	'blank-canvas': UndrawBlankCanvas,
	'choice': UndrawChoice,
	'completed_tasks': UndrawCompletedTasks,
	'empty': UndrawEmpty,
	'fill-forms': UndrawFillForms,
	'fixing_bugs': UndrawFixingBugs,
	'inbox': UndrawInbox,
	'my_password': UndrawMyPassword,
	'no-data': UndrawNoData,
	'notify': UndrawNotify,
	'page_not_found': UndrawPageNotFound,
	'preferences': UndrawPreferences,
	'profile': UndrawProfile,
	'project_completed': UndrawProjectCompleted,
	'questions': UndrawQuestions,
	'quite_town': UndrawQuiteTown,
	'server_down': UndrawServerDown,
	'server': UndrawServer,
	'social_bio': UndrawSocialBio,
	'survey': UndrawSurvey,
	'throw_away': UndrawThrowAway,
	'time_management': UndrawTimeManagement,
	'unexpected_friends': UndrawUnexpectedFriends,
}

const DEFAULT_COLOR = '#6c63ff'

export const Undraw = ({ name, primaryColor = DEFAULT_COLOR, style = {}, ...props }) => {
	const Component = COMPONENTS[name]

	const ref = useRef()

	useEffect(() => {
		if (ref.current) {
			ref.current.setAttribute("width", "100%")
			ref.current.setAttribute("height", "250px")

			const elems = ref.current.querySelectorAll(`[fill="${DEFAULT_COLOR}"]`)

			for (const elem of elems) {
				elem.setAttribute("fill", primaryColor)
			}
		}
	}, [primaryColor, name])

	return <Component ref={ref} style={style} {...props} />
}
