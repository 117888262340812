import compose from 'react-hooks-compose'

import { fields } from './fields'
import { AuthStep } from '../../elements/step'
import { useLogic } from './useLogic'

let realField = {
	nome				: 'nome',
	rg					: 'RG',
	telefone			: 'Celular',
	cep					: 'CEP',
	estado				: 'Cidade_Estado',
	cidade				: 'Cidade_Estado',
	endereco			: 'Endereço',
	escolaridade		: 'Escolaridade',
	faixaEtaria			: 'Faixa Etária',
	latitudeLongitude	: 'Localização Geográfica',
	faixaSalarial		: 'Renda Familiar',
	tratamento			: 'Tratamento',
}

function prepareFields(neededSections, profile) {
	return fields.filter(f => {
		if (!(typeof f.name.map === 'function' ?
			f.name.map(name => realField[name]).reduce((a,b) => a+b) :
			realField[f.name])) return false

		if (typeof neededSections[realField[f.name]] !== 'undefined') return true

		if ((typeof f.name.map === 'function' ?
			f.name.map(name => profile[name]).reduce((a,b) => a+b) :
			profile[f.name])) return true
		
		return false
	}).map(f => {
		f.required = neededSections[realField[f.name]] === true
		return f
	})
}

export const useCheckProfileStep = ({ neededSections, profile }) => compose({
	stepDetails: () => ({
		title: "Perfil",
		formId: "checkProfileForm",
		fields: prepareFields(neededSections, profile),
		buttonTxt: "Avançar",
		data: profile,
	}),
	useLogic,
})(AuthStep)
