export const fields = [{
	type: "email",
	name: "email",
	label: "E-mail",
	autoComplete: "username",
	required: true,
	icon: "envelope",
}, {
	type: "password",
	name: "senha",
	label: "Senha",
	autoComplete: "current-password",
	required: true,
	icon: "lock",
}]
