
import styled from "styled-components";

export const Container = styled.div`
	position: fixed;
	z-index: 999;
	width: 100vw;
	background-color: #222;
	padding: 20px;
	bottom: ${props => props.hide ? "-20vh" : "0"};
	opacity: ${props => props.hide ? "0.5" : "1"};
	transition: bottom .4s, opacity .2s;
	
	.container {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		gap: 50px;
	}

	p {
		color: white;
		margin: 0;
	}

	button {
		background-color: yellowgreen;
		border: 0;
		padding: 5px 25px;
		border-radius: 7px;
	}
`