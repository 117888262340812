import { Button, H1 } from "@blueprintjs/core";
import { FormRecuperar } from "components";
import { UsuarioMainPage } from "components/UsuarioPageLayout";
import { MDBInput as Input } from "mdbreact";

export const Recuperar = () => <UsuarioMainPage>
	<H1>Recuperar senha</H1>
	<FormRecuperar>
		{({ loading }) => <>
			<Input
				outline
				name="email"
				label="E-mail"
				icon="at"
			/>
			<Button
				style={{ alignSelf: "center" }}
				type="submit"
				{...{ loading }}
			>ENVIAR</Button>
		</>}
	</FormRecuperar>
</UsuarioMainPage>
