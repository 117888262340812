import React, { useState, useEffect, useCallback } from 'react'
import { Switch, Route, Redirect, useRouteMatch, useHistory, Link } from 'react-router-dom';
import { Button } from '@blueprintjs/core';

import {
	Home, ConsultasAtivas, ConsultasFinalizadas, ConsultaDetalhes, Auth,
} from '../pages';
import { detalhesOrgao, paramsOrgao } from '../services/Orgao_API'
import { Navbar, Footer, Spinner, ErrorBoundary, NonIdealState } from '.';
import { defineAppColors } from '../utils/defineAppColors';
import setAppManifest from '../utils/setAppManifest';
import {
	restoreOrgaoId, restoreCacheOrgaoInfo, saveCacheOrgaoInfo, clearCacheOrgaoInfo, clearOrgaoId
} from '../utils/cacheOrgaoInfo';
import "../theme.css"
import { checkImageUrl } from '../utils/checkImageUrl';
import styled from 'styled-components';
import urlUtils from 'utils/urlUtils';
import { openLink } from 'utils/openLink';

const Main = styled.main`
	color: var(--PageMain_color);
	background-color: var(--PageMain_bg_color);
`

export default function Orgao({ userToken, setUserToken, install, canInstall, resetOrgao }) {
	const [orgaoInfo, setOrgaoInfo] = useState(null),
		[isLoading, setIsLoading] = useState(true),
		[hasError, setHasError] = useState(false),
		{ path } = useRouteMatch(),
		{ push } = useHistory()

	/** @type {import('../services/Orgao_API').ParamsOrgao} */
	const initialState = null
	const [orgaoParams, setOrgaoParams] = useState(initialState)


	const loadOrgaoInfo = useCallback(() => {
		const cacheInfo = restoreCacheOrgaoInfo(),
			id = restoreOrgaoId(),
			dataIsCached = cacheInfo && cacheInfo.id === id,
			finalize = () => setTimeout(() => {
				setIsLoading()
			}, 1000)

		if (!id) return finalize()

		if (dataIsCached) {
			setOrgaoInfo(cacheInfo)
			finalize()
		}

		const detalhes = detalhesOrgao(id)
			.then(async r => {
				const prefix = process.env.REACT_APP_ENV === "production" ? "https://consulta-arquivos-bucket" : "http://consulta-teste"
				const bannerUrl = `${prefix}.s3-sa-east-1.amazonaws.com/arquivos/banners/bannerTopo${id}.jpg`
				const logoUrl = `${prefix}.s3-sa-east-1.amazonaws.com/arquivos/logomarcas/logomarca${id}.jpg`

				const banner = await checkImageUrl(bannerUrl).catch(() => {})
				const logo = await checkImageUrl(logoUrl).catch(() => {})

				return {
					...r,
					imagem_fundo: banner && bannerUrl,
					brasao: logo && logoUrl,
				}
			})
			.then(setOrgaoInfo)
		
		const params = paramsOrgao()
			.then(r => {
				setOrgaoParams(r)
			})
		
		Promise.all([detalhes, params])
			.catch(err => !dataIsCached && setHasError(err[0] || err[1] || !Array.isArray(err)))
			.finally(() => {
				!dataIsCached && finalize()
			})
	}, [])

	useEffect(() => {
		loadOrgaoInfo()
	}, [loadOrgaoInfo])

	useEffect(() => {
		if (orgaoInfo && orgaoParams) {
			saveCacheOrgaoInfo(orgaoInfo)
			setAppManifest(orgaoInfo)
			defineAppColors(orgaoInfo?.coresPWA)

			if (orgaoInfo.redirecionar_para && !window.alreadyRedirected) {
				window.alreadyRedirected = true
				const prefix = window.isSelfOrigin ? '/' + orgaoInfo.id : ''
				const destination = prefix + '/detalhes/' + orgaoInfo.redirecionar_para
				if (window.location.pathname !== destination) {
					push(destination)
					return
				}
			}

			if (orgaoInfo.dominio && window.isSelfOrigin) {
				const destino = `//${orgaoInfo.dominio}${window.location.pathname.replace(/^\/\d+/g, "")}`

				clearCacheOrgaoInfo()
				clearOrgaoId()
				openLink(destino)

				return
			}

			const selfBranding = process.env.REACT_APP_BRANDING_SELF
			const expectedBrading = orgaoParams.get("PORTAL_CONTRIBUICOES_BRANDING")

			if (!orgaoInfo.dominio && selfBranding !== expectedBrading) {
				const brandingList = JSON.parse(process.env.REACT_APP_BRANDING_ALL)
				const target = `${brandingList[expectedBrading]}${window.location.pathname.substring(1)}`

				clearCacheOrgaoInfo()
				clearOrgaoId()
				openLink(target)
			}
		}
	}, [orgaoInfo, orgaoParams, push])

	useEffect(() => {
		hasError && console.error(hasError)
		hasError && document.body.classList.remove("coloredBg")
	}, [hasError])

	useEffect(() => {
		document.body.classList.add("coloredBg")
	}, [])

	const getWhereRedirect = useCallback(() => {
		const location = sessionStorage.getItem("whereRedirect")
		sessionStorage.removeItem("whereRedirect")
		return location || '/'
	}, [])

	return !isLoading ? !hasError ? <Switch>
		<Route exact {...{ path }}>
			<>
				<Navbar {...{ userToken, setUserToken, orgaoInfo }} fixed />
				<Main>
					<Home {...{ orgaoInfo, canInstall, install }} />
				</Main>
				<Footer {...{
					orgaoInfo, resetOrgao,
					branding: orgaoParams?.get("PORTAL_CONTRIBUICOES_BRANDING"),
				}} />
			</>
		</Route>
		<Route>
			<>
				<Navbar {...{ userToken, setUserToken, orgaoInfo }} />
				<Main>
					<Switch>
						<Route
							exact
							path={urlUtils.join(path, 'ativas')}
							render={props =>
								<ErrorBoundary>
									<ConsultasAtivas {...{ ...props, userToken }} />
								</ErrorBoundary>
							}
						/>
						<Route
							exact
							path={urlUtils.join(path, 'finalizadas')}
							render={props =>
								<ErrorBoundary>
									<ConsultasFinalizadas {...props} />
								</ErrorBoundary>
							}
						/>
						<Route
							exact
							path={urlUtils.join(path, 'detalhes', ':idConsulta')}
							render={props =>
								<ErrorBoundary>
									<ConsultaDetalhes {...{ ...props, userToken, setUserToken, loadOrgaoInfo, orgaoInfo, orgaoParams }} />
								</ErrorBoundary>
							} />
						<Route
							exact
							path={urlUtils.join(path, 'auth')}
							render={routeProps => (!userToken ? (
								<Auth {...routeProps} {...{ setUserToken }} />
							) : (
									<Redirect to={getWhereRedirect()} />
								)
							)}
						/>
						<Route render={() => <Redirect to={path} />} />
					</Switch>
				</Main>
				<Footer {...{
					orgaoInfo, resetOrgao,
					branding: orgaoParams?.get("PORTAL_CONTRIBUICOES_BRANDING"),
				}} />
			</>
		</Route>
	</Switch>
	: <NonIdealState
		title={hasError?.title ?? "Oops!"}
		description={hasError?.description ?? "Ocorreu um problema que iremos tentar resolver o mais rápido possível!"}
		icon={hasError?.icon ?? "server"}
		action={<Link to="/"><Button text="Voltar à página inicial" onClick={resetOrgao} /></Link>}
	/>
	: <Spinner style={{ margin: "100px 0 0 calc(50% - 1rem)" }} />;
}