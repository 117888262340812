import { InputSelect } from "components/CardAuthentication/elements/InputSelect"
import { MDBInput as Input } from "mdbreact";
import { FormPerfil, GridGroup, OpenEmail } from "components";
import { mask } from "utils/mask";
import { MDBSelectEstadoCidade } from "components/CardAuthentication/elements/MDBSelectEstadoCidade";
import { InputMap } from "components/CardAuthentication/elements/InputMap";
import { EnterAccount } from "./elements/EnterAccount.jsx";
import { UsuarioPerfil } from "components/UsuarioPageLayout/elements/UsuarioPerfil.jsx";
import styled from "styled-components";
import { LogoutButton } from "components/UsuarioPageLayout/elements/Header/elements/LogoutButton/index.js";

const Row = styled.div`
	display: flex;
    align-items: center;
	gap: 15px;
    margin: 15px 0;
`

/**
 * @typedef CAWFP_props
 * @prop {import("./useCardAuthenticationWithFieldsLogic").AccountSections} neededSections
 * @prop {boolean} logged
 */

/** @param {CAWFP_props} props */
export const CardAuthenticationWithFieldsPresenter = ({ loading, neededSections, data, logged, onLogin, afterLogout, afterUpdate }) => {
	const fields = <FormPerfil onSuccess={afterUpdate}>
		{({ data, loading }) => <>
			{neededSections.identificação && <>
				<h3>Quem é você</h3>
				<GridGroup>
					{typeof neededSections.identificação.Nome !== "undefined" && <Input
						containerClass="s-9"
						outline
						name="nome"
						label={"Nome Completo" + (neededSections.identificação.Nome ? "" : " (opcional)")}
						icon="user"
						valueDefault={data['nome']}
						required={neededSections.identificação.Nome}
						disabled={!logged}
						readOnly
					/>}
					{typeof neededSections.identificação.CPF !== "undefined" && <Input
						containerClass="s-4"
						outline
						name="cpfcnpj"
						label={"CPF" + (neededSections.identificação.CPF ? "" : " (opcional)")}
						icon="address-card"
						inputMode='numeric'
						valueDefault={data['cpfcnpj']}
						inputRef={mask("9{3}.9{3}.9{3}-9{2}")}
						required={neededSections.identificação.CPF}
						disabled={!logged}
					// readOnly
					/>}
					{typeof neededSections.identificação.RG !== "undefined" && <Input
						containerClass="s-4"
						outline
						name="rg"
						label={"RG" + (neededSections.identificação.RG ? "" : " (opcional)")}
						icon="address-card"
						inputMode='numeric'
						valueDefault={data['rg']}
						required={neededSections.identificação.RG}
						disabled={!logged}
					// readOnly
					/>}
				</GridGroup>
			</>}

			{neededSections.contato && <>
				<h3>Contato</h3>
				<span>Para alterar seu e-mail, entre em contato com <OpenEmail address="falecom@consultapublica.com.br" />.</span>
				<GridGroup>
					{typeof neededSections.contato.Email !== "undefined" && <Input
						containerClass="s-4"
						outline
						name="email"
						label={"E-mail" + (neededSections.contato.Email ? "" : " (opcional)")}
						icon="at"
						valueDefault={data['email']}
						readOnly
						required={neededSections.contato.Email}
						disabled={!logged}
					/>}
					{typeof neededSections.contato.Celular !== "undefined" && <Input
						containerClass="s-4"
						outline
						name="telefone"
						label={"Celular" + (neededSections.contato.Celular ? "" : " (opcional)")}
						icon="mobile-alt"
						valueDefault={data['telefone']}
						inputRef={mask("(9{2}) [9]9999-9999")}
						required={neededSections.contato.Celular}
						disabled={!logged}
					/>}
					{typeof neededSections.contato.Telefone !== "undefined" && <Input
						containerClass="s-4"
						outline
						name="telefone2"
						label={"Telefone" + (neededSections.contato.Telefone ? "" : " (opcional)")}
						icon="phone-alt"
						valueDefault={data['telefone2']}
						inputRef={mask("(9{2}) 9999-9999")}
						required={neededSections.contato.Telefone}
						disabled={!logged}
					/>}
				</GridGroup>
			</>}

			{neededSections.localização && <>
				<h3>Onde você reside</h3>
				<GridGroup>
					{typeof neededSections.localização.Cidade_Estado !== "undefined" && <MDBSelectEstadoCidade
						containerClass="s-4"
						name={["estado", "cidade"]}
						label={[
							"Estado",
							"Cidade",
						]}
						valueDefault={[data['estado'], data['cidade']]}
						required={neededSections.localização.Cidade_Estado}
						disabled={!logged}
					/>}
					{typeof neededSections.localização.Endereço !== "undefined" && <Input
						containerClass="s-5"
						outline
						name="endereco"
						label={"Endereço" + (neededSections.localização.Endereço ? "" : " (opcional)")}
						icon="map-signs"
						valueDefault={data['endereco']}
						autoComplete="street-address"
						required={neededSections.localização.Endereço}
						disabled={!logged}
					/>}
					{typeof neededSections.localização.CEP !== "undefined" && <Input
						containerClass="s-3"
						outline
						name="cep"
						label={"CEP" + (neededSections.localização.CEP ? "" : " (opcional)")}
						icon="road"
						valueDefault={data['cep']}
						inputRef={mask("9{5}-9{3}")}
						autoComplete="postal-code"
						required={neededSections.localização.CEP}
						disabled={!logged}
					/>}
					{typeof neededSections.localização["Localização Geográfica"] !== "undefined" && <InputMap
						className="s-12"
						outline
						name="latitudeLongitude"
						label={"Localização Geográfica" + (neededSections.localização["Localização Geográfica"] ? "" : " (opcional)")}
						icon="map-marked"
						valueDefault={data['latitudeLongitude']}
						required={neededSections.localização["Localização Geográfica"]}
						disabled={!logged}
					/>}
				</GridGroup>
			</>}

			{neededSections.mapeamento && <>
				<h3>Mapeamento</h3>
				<GridGroup>
					{typeof neededSections.mapeamento.Sexo !== "undefined" && <InputSelect
						containerClass="s-3"
						outline
						name="sexo"
						label={"Sexo" + (neededSections.mapeamento.Sexo ? "" : " (opcional)")}
						icon="venus-mars"
						options={["Masculino", "Feminino"].map(x => ({ text: x, value: x }))}
						valueDefault={data['sexo']}
						required={neededSections.mapeamento.Sexo}
						disabled={!logged}
					/>}
					{typeof neededSections.mapeamento.Escolaridade !== "undefined" && <InputSelect
						containerClass="s-6"
						outline
						name="escolaridade"
						label={"Escolaridade" + (neededSections.mapeamento.Escolaridade ? "" : " (opcional)")}
						icon="chalkboard-teacher"
						options={["Sem instrução", "Ensino fundamental ou 1º grau incompleto", "Ensino fundamental ou 1º grau completo", "Ensino médio ou 2º grau incompleto", "Ensino médio ou 2° grau completo", "Ensino superior incompleto", "Ensino superior completo", "Ensino superior completo com pós-graduação"].map(x => ({ text: x, value: x }))}
						valueDefault={data['escolaridade']}
						required={neededSections.mapeamento.Escolaridade}
						disabled={!logged}
					/>}
					{typeof neededSections.mapeamento["Faixa Etária"] !== "undefined" && <InputSelect
						containerClass="s-3"
						outline
						name="faixaEtaria"
						label={"Faixa Etária" + (neededSections.mapeamento["Faixa Etária"] ? "" : " (opcional)")}
						icon="birthday-cake"
						options={["Até 17 anos", "De 18 até 24 anos", "De 25 até 39 anos", "40 anos ou mais"].map(x => ({ text: x, value: x }))}
						valueDefault={data['faixaEtaria']}
						required={neededSections.mapeamento["Faixa Etária"]}
						disabled={!logged}
					/>}
					{typeof neededSections.mapeamento["Renda Familiar"] !== "undefined" && <InputSelect
						containerClass="s-5"
						outline
						name="faixaSalarial"
						label={"Renda Familiar" + (neededSections.mapeamento["Renda Familiar"] ? "" : " (opcional)")}
						icon="comments-dollar"
						options={["Acima de 20 salários mínimos", "Entre 10 e 20 salários mínimos", "Entre 4 e 10 salários mínimos", "Entre 2 e 4 salários mínimos", "Até 2 salários mínimos"].map(x => ({ text: x, value: x }))}
						valueDefault={data['faixaSalarial']}
						required={neededSections.mapeamento["Renda Familia"]}
						disabled={!logged}
					/>}
					{typeof neededSections.mapeamento.Tratamento !== "undefined" && <InputSelect
						containerClass="s-3"
						outline
						name="tratamento"
						label={"Tratamento" + (neededSections.mapeamento.Tratamento ? "" : " (opcional)")}
						icon="comments"
						options={["Senhor", "Senhora"].map(x => ({ text: x, value: x }))}
						valueDefault={data['tratamento']}
						disabled={!logged}
					/>}
				</GridGroup>
			</>}
		</>}
	</FormPerfil>

	return <>
		<h3>Sua conta</h3>
		<span style={{ color: !logged ? 'red' : 'inherit' }}>Utilize sua conta para preencher seus dados pessoais de forma fácil e rápida.</span>
		{!logged && <EnterAccount onLogin={onLogin} />}
		{logged && <>
			<Row>
				<UsuarioPerfil />
				<LogoutButton afterClick={afterLogout} />
			</Row>
	
			{fields}
		</>}


	</>
}
