import compose from 'react-hooks-compose'

import { fields } from './fields'
import { useLogic } from './useLogic'
import { AuthStep } from '../../elements/step'

export const useLoginSteps = hookProps => compose({
	stepDetails: () => ({
		title: "Entrar",
		formId: "loginForm",
		fields,
		buttonTxt: "Entrar",
	}),
	useLogic: () => useLogic(hookProps),
})(AuthStep)
