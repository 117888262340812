import { Button, H1 } from "@blueprintjs/core";
import { FormCadastro, InputPassword } from "components";
import { InputPoliticaPrivacidade } from "components/InputPoliticaPrivacidade";
import { UsuarioMainPage } from "components/UsuarioPageLayout";
import { MDBInput as Input } from "mdbreact";

export const Cadastro = () => <UsuarioMainPage>
	<H1>Cadastro</H1>
	<FormCadastro>
		{({ loading }) => <>
			<Input
				outline
				name="nome"
				label="Nome"
				icon="user"
			/>
			<Input
				outline
				name="email"
				label="E-mail"
				icon="at"
			/>
			<InputPassword
				outline
				type="password"
				name="senha"
				label="Senha"
				icon="lock"
				autoComplete="new-password"
				showRequirements
			/>
			<InputPoliticaPrivacidade orgaoData={{
				sistema: true,
				linkPoliticaPrivacidade: process.env.REACT_APP_BRANDING_POLITICA_PRIVACIDADE
			}} />
			<Button
				style={{ alignSelf: "center" }}
				type="submit"
				{...{ loading }}
			>CADASTRAR</Button>
		</>}
	</FormCadastro>
</UsuarioMainPage>
