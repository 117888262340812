/* eslint-disable no-unused-expressions */
import React, { useCallback, useContext, useEffect, useState } from 'react'
import {
	CardAuthenticationWithFields,
	FormContribuiçãoQuestão, FormContribuiçãoQuestãoDiscursiva, WaitingSegment,
} from '.'
import styled from 'styled-components'
import fixResponsivity from '../utils/fixResponsivity'
import { enviarContribuição, signIn } from '../services'
import { getContribuicao } from '../services/Consultas_API'
import { PageContext } from '../pages'
import { event, timing } from 'services/GA'
import { getFormData, isTokenExpired } from 'utils'
import { MESSAGES } from 'services/enums/Errors'

const FORM = styled.form`
	counter-reset: questoes;
	width: 100%;
`

export function salvarRespostasLocalmente(id, target) {
	const data = {}
	for (const entry of new FormData(target).entries()) {
		if (entry[1] === '') continue;

		let id = target.querySelector(`[name='${entry[0]}']`).getAttribute('data-questao')
		if (id) {
			// let x = [entry[0], entry[1]].join(":")
			// data[id] = data[id] ? [data[id], x].join("_") : x
			let x = entry[1]
			data[id] = data[id] ? [data[id], x].join(",") : x
		} else {
			data[entry[0]] = data[entry[0]] ? [data[entry[0]], entry[1]].join(",") : entry[1]
		}
	}

	for (const select of target.querySelectorAll("select:has(option:checked)")) {
		if (select.value === '') continue

		data[select.name] = [select.value].join(",")
	}

	sessionStorage.setItem(`respostasConsulta${id}`, JSON.stringify(data))
}

export async function recuperarRespostas(id, questoesRecebidas, localOnly) {
	const questoes = JSON.parse(JSON.stringify(questoesRecebidas))

	let segmento, respostas, contribuicao_livre, remote = false
	const respostasLocais = JSON.parse(sessionStorage.getItem(`respostasConsulta${id}`))

	if (respostasLocais) {
		segmento = respostasLocais.segmento
		delete respostasLocais.segmento
		respostas = respostasLocais
		contribuicao_livre = respostasLocais.contribuicao_livre
	} else if (localOnly) {
		segmento = []
		respostas = {}
		contribuicao_livre = ''
	} else {
		const token = sessionStorage.getItem('auth-token')
		let respostasRemotas = await getContribuicao(token, id)
			.catch(err => { if (err !== MESSAGES['contribution/not_found']) throw err }) // suppress error

		if (typeof respostasRemotas === 'object') {
			respostas = respostasRemotas
			contribuicao_livre = respostasRemotas.contribuicao_livre
			respostas.previousResponse = true
			remote = true
		}
	}

	if (respostas) {
		for (const questao of questoes) {
			if (segmento && !segmento.includes(+questao.id)) continue
			if (!respostas[questao.id]) continue;

			if (questao.tipo === 'checkbox') {
				questao.resposta = respostas[questao.id]
					.split(",")
					.map(x => Number(x))
				
				if (respostas[questao.id + '-outros']) {
					questao.respostaTextoOutros = respostas[questao.id + '-outros']
				}
			} else if (questao.tipo === 'objetiva') {
				questao.resposta = [Number(respostas[questao.id])]

				if (respostas[questao.id + '-outros']) {
					questao.respostaTextoOutros = respostas[questao.id + '-outros']
				}
			} else if (questao.tipo === 'tabela_objetiva') {
				questao.resposta = respostas[questao.id]
					.split(",")
					.map(x => Number(x))
				
				questao.pergunta = questao.perguntas.map(p => p.id)
			} else if (questao.tipo === 'cidades') {
				questao.resposta = respostas[questao.id]
					.split(",")
			} else if (questao.tipo === 'condicional') {
				questao.resposta = [Number(respostas[questao.id])]
			} else {
				questao.resposta = respostas[questao.id]
			}
		}
	}

	return {
		questoes, contribuicao_livre, remote, ticket: respostas?.ticket,
		segmento,
	}
}

export function removerRespostasLocalmente(id) {
	window.sessionStorage.removeItem(`respostasConsulta${id}`)
}

const TEMPORARY_DEFAULT_LABEL = 'Escreva livremente sua opinião.'

export default function FormContribuição({
	id, questoes, disabled, label_contribuicao, instrucaoJustificativa, min_chars, max_chars,
	obrigatorio, formRef, submitRef,
	contrLivre, segmentos,
	conditionalSectionHeader, mainSectionHeader, profileSectionHeader
}) {
	useEffect(() => {
		if (formRef.current) fixResponsivity(formRef.current)
	}, [formRef])

	const { setLoading, setPageData } = useContext(PageContext)

	const handleSubmit = useCallback(async e => {
		e.preventDefault()

		submitRef.current.target?.setCustomValidity("")

		const form = formRef.current

		const {
			questoes: answeredQuestions, contribuicao_livre, remote,
			error,
		} = await recuperarRespostas(id, questoes)
			.then(({ questoes, segmento, ...r }) => ({
				questoes: questoes
					.filter(x => !segmento || typeof x.resposta !== 'undefined'),
				...r,
			}))
			.catch(error => ({ error }))

		if (error) return submitRef.current.rej(error)

		if (remote) {
			return submitRef.current.rej({
				icon: "notify",
				title: "Oops!",
				description: "Você não realizou nenhuma alteração na sua contribuição.",
				action: "close",
			})
		}

		if (!form?.reportValidity()) return submitRef.current.res(false)

		if (!disabled) return submitRef.current.res()

		const questions = answeredQuestions.map(c => {
			const r = {
				tipo: c.tipo,
				questao_id: c.id,
			}
			switch (c.tipo) {
				case "discursiva":
					r.resposta = c.resposta
					break;

				case "cidades":
					r.cidades = c.resposta
					break;

				case "objetiva":
				case "checkbox":
					r.texto_outros = c.respostaTextoOutros
				// eslint-disable-next-line
				case "tabela_objetiva":
				case "condicional":
					r.alternativa_id = c.resposta;
					r.pergunta_id = c.pergunta;
					break;

				default:
					break;
			}
			return r;
		})

		let token = sessionStorage.getItem('auth-token')

		if (isTokenExpired(token)) {
			const { email, senha } = getFormData(form)
			if (email && senha) {
				setLoading(true)
				const ok = await signIn({ email, senha })
					.then(() => {
						token = sessionStorage.getItem('auth-token')
						return true
					})
					.catch(error => {
						submitRef.current.target.setCustomValidity(error.message)
						form?.reportValidity()
						setLoading(false)
						submitRef.current.res(false)
						return false
					})
				if (!ok) return
			} else {
				submitRef.current.res("expired-token")
				return
			}
		}

		enviarContribuição(token, id, questions, null, contribuicao_livre)
			.then(r => {
				removerRespostasLocalmente(id)
				r.dataCriacao = new Date(r.dataCriacao.replace("[UTC]", ""))
					.toLocaleString()
				setPageData(x => ({ ...x, ...r }))

				event({
					category: "Consulta",
					action: "Contribuição enviada",
					label: `Consulta ${id} Ticket ${r.id}`,
				})

				return !!r
			})
			.catch(message => {
				submitRef.current.rej(message)
				return false
			})
			.then(v => {
				setLoading(false)
				const start = window.sessionStorage.getItem("timeConfirmQuestionaryStarted")
				if (start) {
					timing({
						category: "Consulta",
						variable: "Tempo de confirmação",
						value: Date.now() - Number(start),
					})
				}
				const { res } = submitRef.current
				res(v)
			})
	}, [disabled, submitRef, formRef, id, questoes, setLoading, setPageData])

	const [selectedSegment, setSelectedSegment] = useState()
	const updateSegment = useCallback(() => {
		let respostasLocais = JSON.parse(sessionStorage.getItem(`respostasConsulta${id}`))
		let shouldSave = true

		if (!respostasLocais && questoes) {
			shouldSave = false
			respostasLocais = {}
			for (const questao of questoes) {
				if (questao.tipo === 'condicional' && questao.resposta) {
					respostasLocais[questao.id] = questao.resposta[0]
				}
			}
			if (Object.keys(respostasLocais) === 0) {
				respostasLocais = null
			}
		}

		if (!respostasLocais || !segmentos) return

		for (const segmento of segmentos) {
			let valid = true
			for (const questaoId in segmento.when) {
				if (+segmento.when[questaoId] !== +respostasLocais[questaoId]) {
					valid = false
					break
				}
			}

			if (valid) {
				setSelectedSegment(segmento)
				shouldSave && sessionStorage.setItem(`respostasConsulta${id}`, JSON.stringify({
					...respostasLocais,
					segmento: segmento.do,
				}))
				break
			}
		}

	}, [id, segmentos, questoes])

	useEffect(() => { updateSegment() }, [updateSegment])

	const handleChange = useCallback(e => {
		salvarRespostasLocalmente(id, e.target.form)

		updateSegment()
	}, [id, updateSegment])

	useEffect(() => {
		sessionStorage.removeItem("timeConfirmQuestionaryStarted")
		if (disabled) {
			sessionStorage.setItem("timeConfirmQuestionaryStarted", Date.now())
		}
	}, [disabled])

	if (!questoes) return null

	let conditionalSection = null

	if (segmentos) {
		conditionalSection = <>
			{conditionalSectionHeader}

			{questoes
				.sort((a, b) => a.posicao - b.posicao)
				.filter(x => x.tipo === 'condicional')
				.map(questao => <FormContribuiçãoQuestão
					key={questao.id}
					{...{ questao, disabled }}
					onChange={handleChange}
				/>)}
		</>
	}

	const mainSection = <>
		{mainSectionHeader}

		{(segmentos && !selectedSegment) ? <WaitingSegment /> : <>
			{questoes
				.sort((a, b) => a.posicao - b.posicao)
				.filter(x => x.tipo !== 'condicional')
				.filter(x => !segmentos || selectedSegment.do.includes(x.id))
				.map(questao => <FormContribuiçãoQuestão
					key={questao.id}
					{...{ questao, disabled }}
					onChange={handleChange}
				/>)}

			<FormContribuiçãoQuestãoDiscursiva
				id="contribuicao_livre"
				titulo={(label_contribuicao || TEMPORARY_DEFAULT_LABEL) + (obrigatorio ? "" : " (opcional)")}
				minLength={min_chars}
				maxLength={max_chars}
				required={obrigatorio}
				disabled={disabled}
				resposta={contrLivre}
				onChange={handleChange}
				instrucao={instrucaoJustificativa}
			/>
		</>}

		<input
			type="submit"
			id="submitFormContribuicao"
			style={{ display: "none" }}
		/>
	</>

	const profileSection = profileSectionHeader ? <>
		{profileSectionHeader}

		<CardAuthenticationWithFields />
	</> : null

	return <FORM
		ref={formRef}
		id="formContrib"
		onSubmit={handleSubmit}
	>
		{conditionalSection}
		{mainSection}
		{profileSection}
	</FORM>
}