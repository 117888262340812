import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from 'react-dom';

import { useRefCallback } from "../utils";

export default function Masonry({children, numCols = 2}) {
		const [actualNumCols, setActualNumCols] = useState(numCols)
		const [masonryRef, setMasonryRef] = useRefCallback()

		const calc = useCallback(nCols => {
				const colHeights = Array(nCols).fill(0);
				const container = ReactDOM.findDOMNode(masonryRef.current);

				Array.from(container.children).forEach(child => {
						child.style.width = `${100/nCols}%`
				})

				Array.from(container.children).forEach(child => {
					const order = colHeights.indexOf(Math.min(...colHeights)),
						childHeight = parseFloat(window.getComputedStyle(child).height)*100
					child.style.order = order

					colHeights[order] += childHeight;
				})

				container.style.height = `${Math.ceil(Math.max(...colHeights))/100}px`;
		}, [masonryRef])

		useEffect(() => {
			calc(actualNumCols)
		}, [calc, actualNumCols])

		useEffect(() => {
				setActualNumCols(numCols)
		}, [children, numCols])

		useEffect(() => {
				const container = ReactDOM.findDOMNode(masonryRef.current)
				function handleResize() {
						const {scrollWidth, clientWidth} = container
						const newNumCols = clientWidth < 690 ? 1 : numCols
						scrollWidth > clientWidth && calc(newNumCols)
						setActualNumCols(newNumCols)
						return newNumCols
				}
				calc(handleResize())

				window.addEventListener('resize', handleResize)

				return () => window.removeEventListener('resize', handleResize)
		}, [children, calc, masonryRef, numCols])

		return (
			<div className="d-flex flex-wrap flex-column" ref={setMasonryRef}>
				{children}
			</div>
		)
}