import { OpenEmail } from "components";
import { Footer } from "./styled/Footer";

export const UsuarioPageFooterPresenter = () => <Footer className="page-container">
	<span>© {new Date().getFullYear()} Copyright: {process.env.REACT_APP_BRANDING_NAME}</span>

	<br />
	<br />

	<small>Solução de problemas: <OpenEmail address="falecom@consultapublica.com.br" /></small>
</Footer>
