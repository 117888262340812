import { useState, useCallback } from 'react'

const prependLinks = [
	['mailto:', ' (abrindo no app padrão)'],
	['https://mail.google.com/mail/?view=cm&to=', ' (abrindo no Gmail)'],
	['https://outlook.live.com/mail/0/deeplink/compose?to=', ' (abrindo no Outlook)'],
	['https://compose.mail.yahoo.com/?to=', ' (abrindo no Yahoo)'],
]

export const OpenEmail = ({ address }) => {
	const [currentIndex, setCurrentIndex] = useState(0)

	const next = useCallback(() => {
		setTimeout(() => {
			setCurrentIndex(x => (x + 1) % prependLinks.length)
		}, 10)
	}, [])
	
	return <a
		href={`${prependLinks[currentIndex][0]}${address}`}
		className="mailto-link"
		target="_blank"
		rel="noreferrer"
		onClick={next}
	>{address}{prependLinks[currentIndex][1]}</a>
}
