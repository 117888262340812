import { useEffect, useState } from "react"

import { useAutoScroll } from "../../utils"

/**
 * @typedef MiscProps
 * @property {import('react').ReactChildren} step
 * @property {() => void} onFinish
 * @property {[Number, Number]} mySize
 */

/** @param {MiscProps} props */
export const useMiscLogic = ({ step, onFinish, mySize, setCursor }) => {
	const scrollRef = useAutoScroll(step)

	const [finished, setFinished] = useState(mySize[1] >= mySize[0]),
		[notified, setNotified] = useState(false)

	useEffect(() => {
		setFinished(mySize[1] >= mySize[0])
	}, [mySize])

	useEffect(() => {
		if (onFinish && finished && !notified) {
			if (typeof onFinish === 'function') onFinish()
			else if (onFinish === 'loop') setCursor(0)
			setNotified(true)
		} else if (!finished && notified) {
			setNotified(false)
		}
	}, [finished, onFinish, notified, setCursor])

	return { scrollRef }
}
