/* eslint-disable no-unused-expressions */
import { useCallback, useEffect, useRef, useState } from "react";
import { getFormData } from "utils";

const DEFAULT_LOCATION = {
	lat: -22.0087974,
	lng: -47.8912785,
}

function placeMarker(marker, location, map, onChange) {
	if (!window.google) return

    if (marker.current != null) {
        marker.current.setMap(null);
    }
    marker.current = new window.google.maps.Marker({
        position: location,
        map: map,
        draggable: true
    });
    window.google.maps.event.addListener(marker.current, 'dragend', function(event) {
		placeMarker(marker, event.latLng, map);
		onChange?.(event.latLng)
    });
}

export const useLogic = () => {
	const [address, setAddress] = useState(""),
		[value, setValue] = useState(""),
		[map, setMap] = useState(),
		containerRef = useRef(),
		[geoCoder, setGeoCoder] = useState(),
		marker = useRef()

	useEffect(() => {
		if (map && window.google) {
			const geoCoder = new window.google.maps.Geocoder();

			setGeoCoder(geoCoder)
		} else {
			setGeoCoder()
		}
	}, [map])

	useEffect(() => {
		(async () => {
			let location, realLocation

			if (geoCoder && address) {
				await new Promise(res => {
					geoCoder.geocode({ address }, (results, status) => {
						if (status === window.google?.maps.GeocoderStatus.OK) {
							location = results[0].geometry.location
							realLocation = {
								lat: location.lat(),
								lng: location.lng(),
							}
						} else {
							console.error('Geocoding failed:', status)
							location = realLocation = DEFAULT_LOCATION
						}
						res()
					})
				})
			} else {
				location = realLocation = DEFAULT_LOCATION
			}

			setValue([realLocation.lat, realLocation.lng].join(", "))

			if (!map) return

			map.setCenter(location)
			map.setZoom(14)

			placeMarker(marker, location, map, location => {
				const realLocation = {
					lat: location.lat(),
					lng: location.lng(),
				}

				setValue([realLocation.lat, realLocation.lng].join(", "))
			})
		})()
	}, [address, map, geoCoder])

	const onLoad = useCallback(map => {
		if (!window.google) return

		setMap(map)
	}, [])

	const onUnmount = useCallback(map => {
		setMap()
	}, [])

	useEffect(() => {
		function handleChange(e) {
			const data = getFormData(e.target.form)
			let newAddress = []
			for (const key in data) {
				if (['estado', 'cidade', 'endereco', 'cep'].includes(key)) {
					newAddress.push(data[key])
				}
			}
			setAddress(newAddress.join(" ").trim())
		}

		const form = containerRef.current?.check.current
			.closest('form')

		if (!form) return

		form.addEventListener('change', handleChange)
		handleChange({ target: { form } })

		const config = { attributes: true, childList: false, subtree: false }

		const observer = new MutationObserver(mutationList => {
			for (const mutation of mutationList) {
				if (mutation.type === 'attributes' && mutation.attributeName === 'value') {
					handleChange({ target: { form } })
				}
			}
		})

		form.querySelectorAll("input[name='estado'], input[name='cidade']")
			.forEach(elem => observer.observe(elem, config))

		return () => {
			form.removeEventListener('change', handleChange)
			observer.disconnect()
		}
	}, [])

	return { onLoad, onUnmount, containerRef, value }
}
