import compose from 'react-hooks-compose'

import { useUsuarioPageLayoutLogic } from './useLogic'
import { UsuarioPageLayoutPresenter } from './presenter'

export const UsuarioPageLayout = compose(props => ({
	useLogic: () => useUsuarioPageLayoutLogic(props),
}))(UsuarioPageLayoutPresenter)

export { MainPage as UsuarioMainPage } from './elements/Main/elements/MainPage/index'
