import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Button } from '@blueprintjs/core'
import { FaUserCircle } from 'react-icons/fa'

import { NonIdealState } from '../components'
import ListaOrgaos from '../components/ListaOrgaos'
import { UpdateNavbar } from 'components/Navbar/components/UpdateNavbar'
import { Link, useHistory } from 'react-router-dom'
import { getAuthState, saveOrgaoId } from 'utils'
import { openLink } from 'utils/openLink'

const HeaderContainer = styled.div`
	width: 100vw;
	height: 90px;
	padding: 13px 5vw;
	box-shadow: 0 5px 11px 0 rgba(50, 50, 50, 0.08);
	display: flex;
	flex-direction: row;
	/* justify-content: space-around; */
	align-items: center;
	gap: 30px;

	img {
		margin-right: auto;
		height: 64px;
	}

	@media (max-width: 500px) {
		height: unset;
		flex-wrap: wrap;
		justify-content: space-around;
		gap: 10px;

		img {
			height: 48px;
			margin-left: auto;
		}
	}
`
const HeaderLink = styled.a`
	color: #0397D1;
	line-height: 15px;
	font-weight: 600;
	text-align: center;

	&:hover {
		color: #e96656;
	}
`

const HeaderAccountLink = styled(Link)`
	color: #0397D1;

	&:hover {
		color: #e96656;
	}
`

const Header = () => {
	return <HeaderContainer>
		<img src="/logo.png" alt={process.env.REACT_APP_BRANDING_NAME} />
		<HeaderLink href="http://consultapublica.com.br/">
			QUEM SOMOS
		</HeaderLink>
		<HeaderAccountLink
			to={getAuthState() ? "/conta" : "/entrar"}
			onClick={() => sessionStorage.setItem('whereRedirect', '/')}
		>
			<FaUserCircle size="32px" />
		</HeaderAccountLink>
	</HeaderContainer>
}

export default function SelectOrgao({ setHasId }) {
	const [hasError, setHasError] = useState(false)
	const history = useHistory();

	const handleSelect = useCallback(x => {
		if (x.dominio) {
			openLink("//" + x.dominio)
		} else {
			saveOrgaoId(x.id)
			setHasId?.(x.id)
			history.push(`/${x.id}`)
		}
	}, [setHasId, history])

	return <>
		<Header />
		<UpdateNavbar />
		{!hasError ? <NonIdealState
			icon="quite_town"
			color="#0895d1"
			title="Selecione um órgão municipal"
			description="Para começar, você precisa escolher o órgão municipal que está realizando a consulta."
			action={<ListaOrgaos {...{ setHasError }} onSelect={handleSelect} />}
		/> : <NonIdealState
			icon="server_down"
			color="#0895d1"
			title="Servidores indisponíveis"
			description="Não foi possível se conectar aos nossos servidores. Tente novamente mais tarde."
			action={<Button onClick={() => setHasError()}>Tentar novamente</Button>}
		/> }
	</>
}