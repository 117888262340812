import changeManifest from "./changeManifest"
import { getTextColorFromBgColor } from "./colorUtilities"

const defaultColor = process.env.REACT_APP_DEFAULT_PRIMARY_COLOR

export default function setAppManifest(info) {
	const color = (info.coresPWA && info.coresPWA.primaria) || defaultColor
	changeManifest({ // TODO: change icons with svg
		start_url: `${window.location.origin}/${info.id}`,
		theme_color: getTextColorFromBgColor(color),
		background_color: color,
	})
}