import React from 'react'
import styled from 'styled-components'
import { MDBIcon } from 'mdbreact'
import { useNavbarNavigation } from '../utils'

const Icon = styled(MDBIcon)`
	margin-right: 16px;
	cursor: pointer;

	&:before {
		transition: all .2s;
		margin: -5px;
		padding: 5px;
	}

	&:hover:before {
		background-color: rgba(0,0,0,.1);
		border-radius: 50%;
	}
`

const NavbarBackButton = () => {
	const { breadCrumb, back } = useNavbarNavigation()

	if (breadCrumb.length <= 1) return null

	return <Icon
		icon="arrow-left"
		onClick={back}
	/>
}

export default NavbarBackButton
