import styled from "styled-components";

export const Aside = styled.aside`
	height: fit-content;
	display: flex;
	flex-direction: column;
	gap: 30px;
	
	@media (min-width: 512px) {
		width: 250px;
	}
`
