
import styled from "styled-components";
import { Container as SelectContainer } from 'components/CardAuthentication/elements/InputSelect/elements'

export const GridGroup = styled.div`
	display: grid;
	gap: 1.5rem 15px;

	@media (min-width: 993px) {
		grid-template-columns: repeat(12, 1fr);

		> .s-1 { grid-column: span 1 }
		> .s-2 { grid-column: span 2 }
		> .s-3 { grid-column: span 3 }
		> .s-4 { grid-column: span 4 }
		> .s-5 { grid-column: span 5 }
		> .s-6 { grid-column: span 6 }
		> .s-7 { grid-column: span 7 }
		> .s-8 { grid-column: span 8 }
		> .s-9 { grid-column: span 9 }
		> .s-10 { grid-column: span 10 }
		> .s-11 { grid-column: span 11 }
		> .s-12 { grid-column: span 12 }
	}
	@media (max-width: 993px) and (min-width: 768px) {
		grid-template-columns: repeat(6, 1fr);

		> .s-1, > .s-2 { grid-column: span 1 }
		> .s-3, > .s-4 { grid-column: span 2 }
		> .s-5, > .s-6 { grid-column: span 3 }
		> .s-7, > .s-8 { grid-column: span 4 }
		> .s-9, > .s-10 { grid-column: span 5 }
		> .s-11, > .s-12 { grid-column: span 6 }
	}
	@media (max-width: 768px) {
		grid-template-columns: repeat(3, 1fr);

		> .s-1, > .s-2, > .s-3, > .s-4, // { grid-column: span 1 }
		> .s-5, > .s-6, > .s-7, > .s-8, // { grid-column: span 2 }
		> .s-9, > .s-10, > .s-11, > .s-12 { grid-column: span 3 }
	}

	.md-form.md-outline, ${SelectContainer} {
		margin: 0;
		margin-top: 5px;
	}
`