import React, { useCallback, useState } from 'react'
import { MDBInput } from 'mdbreact'
import styled from 'styled-components'
import { H4 } from './FormContribuiçãoQuestão'
import { InputQuestaoDiscursiva } from 'components'
import { HTMLSelect } from '@blueprintjs/core';

const Select = styled(HTMLSelect)`
	margin-left: 1.25rem;

	option {
		font-size: 1.2em;
	}
`

const FormContribuiçãoQuestãoObjetiva = ({
	id, alternativas, titulo, instrucao, disabled, resposta = [], opcaoOutros,
	opcaoOutrosTexto, onChange, respostaTextoOutros, variacao
}) => {
	const [selected, setSelected] = useState(resposta)
	const [textoOutros, setTextoOutros] = useState(respostaTextoOutros)

	const resetOutros = useCallback(() => {
		const name = `${id}-outros`
		if (document.querySelector(`[name='${name}']`))
			document.querySelector(`[name='${name}']`).value = ''
		setTextoOutros("")
	}, [id])

	const handleChange = useCallback(e => {
		console.log(e.target)
		const value = Number(e.target.value)

		if (value !== alternativas.find(x => x.opcaoOutros)?.id)
			resetOutros()

		setSelected([value])
		if (typeof onChange === 'function') onChange(e)
	}, [onChange, alternativas, resetOutros])
console.log(resposta, alternativas)
	const alternativasElems = alternativas
		.filter(x => !disabled || resposta[0] === x.id)
		.map(alternativa => variacao === "lista flutuante" ? <option
			value={alternativa.id}
			key={alternativa.id}
			selected={resposta[0] === alternativa.id}
		>{alternativa.texto}</option> : <MDBInput
			key={alternativa.id}
			id={`${alternativa.id}`}
			name={id}
			value={alternativa.id}
			type="radio"
			label={alternativa.texto}
			disabled={disabled}
			defaultChecked={resposta[0] === alternativa.id}
			onChange={handleChange}
			required
			children={alternativa.opcaoOutros && opcaoOutrosTexto ? <InputQuestaoDiscursiva
				name={`${id}-outros`}
				id={`${id}-outros`}
				mascara="not_empty"
				outline={true}
				size="sm"
				className="m-0"
				containerClass="d-inline-block m-0 ml-2"
				required={selected.includes(alternativa.id)}
				disabled={disabled || !selected.includes(alternativa.id)}
				onChange={onChange}
				resposta={textoOutros}
			/> : null}
		/>)
	
	const opcaoOutrosSelected = selected[0] === alternativas.find(x => x.opcaoOutros)?.id

	return <>
		<H4>{titulo}</H4>
		{instrucao ? <div dangerouslySetInnerHTML={{ __html: instrucao }}></div> : null}

		{variacao === "lista flutuante" ? <>
			<Select
				onChange={handleChange}
				name={id}
				disabled={disabled}
				required
			>
				<option disabled selected={!resposta[0]} value="">Selecione uma opção</option>
				{alternativasElems}
			</Select>
			{opcaoOutrosSelected && opcaoOutrosTexto ? <InputQuestaoDiscursiva
				name={`${id}-outros`}
				id={`${id}-outros`}
				mascara="not_empty"
				outline={true}
				size="sm"
				className="m-0"
				containerClass="d-inline-block m-0 ml-2"
				required={opcaoOutrosSelected}
				disabled={disabled || !opcaoOutrosSelected}
				onChange={onChange}
				resposta={textoOutros}
			/> : null}
		</> : alternativasElems}
	</>
}

export default FormContribuiçãoQuestãoObjetiva