import compose from 'react-hooks-compose'

import { fields } from './fields'
import { useLogic } from './useLogic'
import { AuthStep } from '../../elements/step'

export const useChangeSteps = hookProps => compose({
	stepDetails: () => ({
		title: "Alterar senha",
		formId: "changePass",
		fields,
		buttonTxt: "Alterar",
	}),
	useLogic: () => useLogic(hookProps),
})(AuthStep)
