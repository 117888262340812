import React, {
	useCallback, useEffect, useRef, useState, useContext,
} from 'react'

import { FormContribuição, ServiceAlert } from '.'
import { recuperarRespostas } from './FormContribuição'
import {
	TitleSetter, BodySetter, FooterSetter, NextButton,
} from './Stepper/layouts/MDB'
import { Right } from './Stepper/layouts/default'
import { Step } from './Stepper'
import { resetCustomValidity } from '../utils'
import { PageContext } from '../pages'
import { MDBInput as Input } from "mdbreact"
import { H2 } from "@blueprintjs/core"
import retryForm from 'utils/retryForm'
import { useStepper } from './Stepper/utils'

function CardConsultaDetalhesStep3V2() {
	const { consulta, setLoading } = useContext(PageContext),
		{ id, questoes: questoesRecebidas, nome, segmentos } = consulta

	const [questoes, setQuestoes] = useState(),
		[contrLivre, setContrLivre] = useState(),
		[hasError, setError] = useState(false),
		{ next } = useStepper()

	useEffect(() => {
		recuperarRespostas(id, questoesRecebidas)
			.then(({ questoes, contribuicao_livre }) => {
				setContrLivre(contribuicao_livre)
				setQuestoes(questoes)
			})
	}, [id, questoesRecebidas])

	const formRef = useRef(),
		submitRef = useRef({})

	const handleNext = useCallback(async e => {
		resetCustomValidity(e.target)
		resetCustomValidity(formRef.current)
		submitRef.current.target = e.target

		if (formRef.current?.checkValidity()) {
			setLoading(true)
			return new Promise(async res1 => {
				const result = await new Promise((res2, rej2) => {
					submitRef.current.res = res2
					submitRef.current.rej = message => {
						setError(retryForm(message, { target: formRef.current }, () => { setError(); next(e) }))
						rej2()
					}
				}).catch(() => { return false })

				if (result === 'expired-token') {
					setShouldAskLogin(true)
					setTimeout(() => {
						document.querySelector('#login-message').scrollIntoView({
							block: "center",
							behavior: "smooth",
						})
					}, 500)
					setTimeout(() => {
						e.target.setCustomValidity("Faça login novamente")
						formRef.current?.reportValidity()
					}, 1500)
					res1(false)
				} else {
					res1(result)
				}
			}).catch(() => { return false })
			.finally(setLoading)
		}

		return false
	}, [next, setLoading])

	const [shouldAskLogin, setShouldAskLogin] = useState(false)

	return <Step onNext={handleNext}>
		<TitleSetter>{nome}</TitleSetter>
		<BodySetter>
			{hasError && <ServiceAlert message={hasError} onClose={() => setError()} autoClose />}
			<div className="text-center red-text">
				Confirme sua contribuição.
			</div>
			{questoes && <FormContribuição
				{...{ id, questoes, formRef, submitRef, contrLivre, segmentos }}
				disabled
			/>}
			{shouldAskLogin && <>
				<hr />
				<H2>Faça login novamente</H2>
				<p id="login-message">Sua sessão expirou (1 hora). Por favor, faça o login novamente.</p>

				<Input
					outline
					name="email"
					label="E-mail"
					icon="at"
					form="formContrib"
				/>
				<Input
					outline
					type="password"
					name="senha"
					label="Senha"
					icon="lock"
					form="formContrib"
				/>
			</>}
		</BodySetter>
		<FooterSetter stickyFooter>
			<Right>
				<NextButton
					type="submit"
					form="formContrib"
				>Confirmar</NextButton>
			</Right>
		</FooterSetter>
	</Step>
}

export default CardConsultaDetalhesStep3V2
