function wrap(el, wrapper) {
	el.parentNode.insertBefore(wrapper, el);
	wrapper.appendChild(el);
}

function fix_it(elem) {
	const container = document.createElement("div")
	switch (elem.tagName) {
		case "IFRAME":
			Object.assign(container.style, {
				overflow: "hidden",
				paddingBottom: "56.25%",
				position: "relative",
				height: 0,
			})
			Object.assign(elem.style, {
				left: 0,
				top: 0,
				height: "100%",
				width: "100%",
				position: "absolute",
			})
			break;

		case "TABLE":
		default:
			container.style.overflowX = "auto"
			break;
	}

	wrap(elem, container)
}

function fix_recur(elem) {
	const elemWidth = +elem.getBoundingClientRect().width.toFixed(0)
	const parentWidth = +elem.parentElement.getBoundingClientRect().width.toFixed(0)

	if (elemWidth > parentWidth) {
		fix_it(elem)
	} else {
		for (const e of elem.children) {
			fix_recur(e)
		}
	}
}

export default function fixResponsivity(elem) {
	for (const e of elem.children) {
		fix_recur(e)
	}
}
