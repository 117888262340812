import styled from "styled-components"
import { Button, ButtonGroup } from "@blueprintjs/core";
import { FormCadastro, FormLogin, InputPassword } from "components";
import { MDBInput as Input } from "mdbreact";
import { useToggle } from "utils";
import { InputPoliticaPrivacidade } from "components/InputPoliticaPrivacidade";

const Container = styled.div`
	margin-left: 10px;
`

export const EnterAccount = ({onLogin, ...props}) => {
	const [active, toggle] = useToggle(true)

	return <Container>
		<ButtonGroup large>
			<Button onClick={toggle} active={active}>Já possuo uma conta</Button>
			<Button onClick={toggle} active={!active}>Criar conta agora</Button>
		</ButtonGroup>

		{active && <FormLogin style={{ margin: "20px 0" }} onSuccess={onLogin}>
			{({ loading, handleSubmit }) => <>
				<Input
					outline
					name="email"
					label="E-mail"
					icon="at"
				/>
				<InputPassword
					outline
					type="password"
					name="senha"
					label="Senha"
					icon="lock"
				/>
				<span>
					Esqueceu a <a href="#!" onClick={null}>senha?</a>
				</span>
				<span>
					Não tem uma conta? <a href="#!" onClick={toggle}>Cadastre-se</a>
				</span>
				<Button
					style={{ alignSelf: "flex-start" }}
					type="submit"
					intent="success"
					{...{ loading }}
				>ENTRAR</Button>
			</>}
		</FormLogin>}

		{!active && <FormCadastro style={{ margin: "20px 0" }} onSuccess={onLogin}>
			{({ loading }) => <>
				<Input
					outline
					name="nome"
					label="Nome"
					icon="user"
				/>
				<Input
					outline
					name="email"
					label="E-mail"
					icon="at"
				/>
				<InputPassword
					outline
					type="password"
					name="senha"
					label="Senha"
					icon="lock"
					autoComplete="new-password"
					showRequirements
				/>
				<InputPoliticaPrivacidade orgaoData={{
					sistema: true,
					linkPoliticaPrivacidade: process.env.REACT_APP_BRANDING_POLITICA_PRIVACIDADE
				}} />
				<Button
					style={{ alignSelf: "flex-start" }}
					type="submit"
					intent="success"
					{...{ loading }}
				>CADASTRAR</Button>
			</>}
		</FormCadastro>}
	</Container>
}