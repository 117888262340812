import React, { useEffect, memo, useState, useContext, useRef, useCallback } from 'react'
import { MDBListGroup, MDBListGroupItem, MDBIcon, MDBBadge } from 'mdbreact'
import prettyBytes from 'pretty-bytes'
import moment from 'moment'

import { event, modalView, timing } from 'services/GA'

// import { LoadingRead } from '.'
import { Center, Left, Right } from './Stepper/layouts/default'
import {
	NextButton, TitleSetter, BodySetter, FooterSetter,
} from './Stepper/layouts/MDB'

import fixResponsivity from '../utils/fixResponsivity'
import getAnexoIcon from 'utils/getAnexoIcon'
import { PageContext, PageContextLoadingValue } from '../pages'
import { useSharePage } from 'utils'
import { SHARE_SCHEDULED_TEXT, SHARE_OPEN_TEXT, SHARE_CLOSED_TEXT } from 'utils/useSharePage'
import { Step } from './Stepper'
import NonIdealState from './NonIdealState'

const Anexo = memo(({ tipo, caminho, comentarios, tamanho }) => {
	return <MDBListGroupItem
		href={caminho}
		target="_blank"
		rel="noopener noreferrer"
		className="d-flex justify-content-between align-items-center"
	>
		<span>
			<MDBIcon icon={getAnexoIcon(tipo)} style={{ marginRight: 5 }} fixed />
			{comentarios}
		</span>
		{tipo !== "_url" && <MDBBadge color="primary" pill>{prettyBytes(Number(tamanho), { locale: true })}</MDBBadge>}
	</MDBListGroupItem>
})

/** @param {String} descricao */
function getCustomButtom(descricao) {
	const pattern = /<!-- CUSTOM BUTTON: \[0:(.+)\]\[1:(.+)\]\[2:(.+)\] -->/g
	const result = pattern.exec(descricao)

	if (!result) return null

	return [result[1].trim(), result[2].trim(), result[3].trim()]
}

function CardConsultaDetalhesStep1() {
	const { consulta } = useContext(PageContext),
		{ loading } = useContext(PageContextLoadingValue),
		{ /*id, */datainicio, datatermino, fusoHorario, nome, descricao, arquivos } = consulta || {}

	const [estáFinalizada, setEstáFinalizada] = useState(true)
	useEffect(() => {
		if (!datatermino) return

		const dataTermino = moment
			.utc(datatermino.replace("[UTC]", ""))
			.utcOffset(fusoHorario)
			.local()

		setEstáFinalizada(moment().isAfter(dataTermino))
	}, [datatermino, fusoHorario])

	const [estaProgramada, setEstaProgramada] = useState(false)
	useEffect(() => {
		if (!datainicio) return

		const dataInicio = moment
			.utc(datainicio.replace("[UTC]", ""))
			.utcOffset(fusoHorario)
			.local()

		setEstaProgramada(moment().isBefore(dataInicio))
	}, [datainicio, fusoHorario])

	const [readingComplete/*, setReadingComplete*/] = useState(true)

	const dataInicio = !datainicio ? '' : moment
			.utc(datainicio)
			.utcOffset(fusoHorario)
			.local()
			.format("dddd, D [de] MMMM [de] YYYY [às] HH:mm:ss")
		
	const textDefault = estaProgramada ? SHARE_SCHEDULED_TEXT(dataInicio) : estáFinalizada ? SHARE_CLOSED_TEXT : SHARE_OPEN_TEXT
	const [shareAPI, sharePageHandler] = useSharePage()
	const [shareLoading, setShareLoading] = useState(false)
	const sharePage = useCallback(() => {
		const title = consulta?.nome || ""
		const text = consulta?.compartilhamento?.msgInicio || textDefault
		setShareLoading(true)
		sharePageHandler(title, text)
			.finally(() => setShareLoading(false))

		event({
			category: "Consulta",
			action: "Compartilhamento na capacitação",
		})
	}, [sharePageHandler, consulta, textDefault])

	const timeOpened = useRef()
	useEffect(() => { timeOpened.current = Date.now() }, [])
	const onNext = useCallback(() => {
		modalView("Avanço dos detalhes da Consulta")

		const duration = Date.now() - timeOpened.current
		timing({
			category: "Consulta",
			variable: "Capacitação",
			value: duration,
		})
	}, [])

	const customButton = getCustomButtom(descricao)

	return <Step {...{ onNext, loading }}>
		<TitleSetter>{nome}</TitleSetter>
		<BodySetter>
			{!estaProgramada ? <div id="consulta-desc">
				<p
					ref={x => x && fixResponsivity(x)}
					dangerouslySetInnerHTML={{ __html: descricao }}
				/>
				{arquivos && !!arquivos.length && <MDBListGroup>
					{arquivos.map((a, i) => <Anexo
						key={a.tipo + i}
						{...a}
					/>)}
				</MDBListGroup>}
			</div> : <NonIdealState
				icon="time_management"
				title="Aguarde"
				description={<>Esta {process.env.REACT_APP_BRANDING_OBJECT} ainda não está aberta. Volte a partir de <b>{dataInicio}</b> e faça sua contribuição!</>}
			/> }
		</BodySetter>
		<FooterSetter stickyFooter={!estáFinalizada}>
			{!customButton ? <><Left>
					{/* {!estáFinalizada && <LoadingRead
						id={id}
						text={descricao}
						{...{ setReadingComplete }}
					/>} */}
					<NextButton
						onClick={sharePage}
						disabled={shareLoading}
					>Compartilhar {process.env.REACT_APP_BRANDING_OBJECT_SHORT} <MDBIcon
							icon={shareLoading ? "sync" : shareAPI ? "share" : "facebook-square"}
							fab={!shareAPI}
							spin={shareLoading}
						/></NextButton>
				</Left>
				<Right>
					<NextButton
						disabled={estáFinalizada || !readingComplete}
					>{estaProgramada ? `${process.env.REACT_APP_BRANDING_OBJECT_SHORT} programada` : estáFinalizada ? `${process.env.REACT_APP_BRANDING_OBJECT_SHORT} finalizada` : 'Contribuir'}</NextButton>
				</Right></> : <Center>
					<NextButton>{estaProgramada ? customButton[0] : estáFinalizada ? customButton[2] : customButton[1]}</NextButton>
			</Center>}
		</FooterSetter>
	</Step>
}

export default memo(CardConsultaDetalhesStep1)
