import { PageContext } from 'pages'
import React, { useCallback, memo, useState } from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'

import {
	Stepper,
	CardConsultaDetalhesStep1 as Step1Capacitacao,
	CardConsultaDetalhesStep2 as Step2Autenticacao,
	CardConsultaDetalhesStep2V2 as Step2ContribuicaoV2,
	CardConsultaDetalhesStep2V3 as Step2ContribuicaoV3,
	CardConsultaDetalhesStep3 as Step3Contribuicao,
	CardConsultaDetalhesStep3V2 as Step3ConfirmacaoV2,
	CardConsultaDetalhesStep3V3 as Step3ConfirmacaoV3,
	CardConsultaDetalhesStep4 as Step4Confirmacao,
	CardConsultaDetalhesStep4V3 as Step4AutenticacaoV3,
	CardConsultaDetalhesStep5 as Step5Final,
} from '.'

import { MDB as MDBLayout } from './Stepper/layouts'

function CardConsultaDetalhes() {
	const handleFinish = useCallback(() => {
		window.location.reload()
	}, [])

	const [useLoop, setUseLoop] = useState(false)
	const { orgaoInfo, orgaoParams } = useContext(PageContext)
	useEffect(() => {
		// TODO: criar um hook para checar os parâmetros do órgão
		setUseLoop(orgaoInfo?.config?.REVER_CONSULTA_SEM_RECARREGAR)
	}, [orgaoInfo])

	if (orgaoParams) {
		if (orgaoParams.get("SEQUENCIA_CONSULTA") === 2) {
			return <Stepper layout={MDBLayout} onFinish={useLoop ? 'loop' : handleFinish}>
				<Step1Capacitacao />
				<Step2ContribuicaoV2 />
				<Step3ConfirmacaoV2 />
				<Step5Final />
			</Stepper>
		} else if (orgaoParams.get("SEQUENCIA_CONSULTA") === 3) {
			return <Stepper layout={MDBLayout} onFinish={useLoop ? 'loop' : handleFinish}>
				<Step1Capacitacao />
				<Step2ContribuicaoV3 />
				<Step3ConfirmacaoV3 />
				<Step4AutenticacaoV3 />
				<Step5Final />
			</Stepper>
		}
	}

	return <Stepper layout={MDBLayout} onFinish={useLoop ? 'loop' : handleFinish}>
		<Step1Capacitacao />
		<Step2Autenticacao />
		<Step3Contribuicao />
		<Step4Confirmacao />
		<Step5Final />
	</Stepper>
}

export default memo(CardConsultaDetalhes)
