import { useEffect } from 'react'

import { useLayout } from '.'

export const useSetter = (prop, value) => {
	const layoutCtx = useLayout()
	const setLayoutData = ((prop !== 'body' && layoutCtx.parentCtx) || layoutCtx).dataState[1]

	useEffect(() => {
		setLayoutData(x => ({
			...x,
			[prop]: value,
		}))
	}, [setLayoutData, prop, value])
}
