import { H1 } from "@blueprintjs/core"
import { NewFormContribuicao } from "components/NewFormContribuicao"
import { UsuarioMainPage } from "components/UsuarioPageLayout"
import { FaAngleLeft } from "react-icons/fa"
import { NavLink } from "react-router-dom"

export const Resposta = () => {

	return <UsuarioMainPage>
		<H1>Minhas contribuições</H1>
		<div>
			<NavLink
				to={`/consultas`}
				className="bp3-button bp3-outlined"
			><FaAngleLeft />Voltar</NavLink>
		</div>
		<NewFormContribuicao />
	</UsuarioMainPage>
}
