import { Route, Switch } from "react-router";
import { H1 } from "@blueprintjs/core";
import { UsuarioMainPage } from "components/UsuarioPageLayout";
import { ListaContribuicoes } from "components";
import { ConsultasResposta } from "pages";

const Home = () => <UsuarioMainPage>
	<H1>Minhas contribuições</H1>
	<ListaContribuicoes />
</UsuarioMainPage>

export const Consultas = () => <Switch>
	<Route path="/consultas/resposta" component={ConsultasResposta} />
	<Route component={Home} />
</Switch>
