import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { MDBContainer } from 'mdbreact';

import { CardConsultaDetalhes, NonIdealState, Spinner } from '../components';
import { detalhesConsulta } from '../services';
import { pageView } from '../services/GA'
import { Mask } from '../components/MDBStyled';
import { PageContext, PageContextLoadingValue } from '.';
import { restoreOrgaoId, saveOrgaoId } from '../utils';
import CardConsultaFinalizadaRelatorios from 'components/CardConsultaFinalizadaRelatorios';
import { useHistory } from 'react-router';

export default function ConsultaDetalhes({ match, loadOrgaoInfo, orgaoInfo, orgaoParams }) {
	useEffect(pageView, [])

	const [loading, setLoading] = useState(true),
		[hasError, setHasError] = useState(false),
		[consulta, setConsulta] = useState(),
		[pageData, setPageData] = useState({})

	const runQuery = useCallback(() => {
		setLoading(true)
		setHasError(false)
		detalhesConsulta(match.params.idConsulta)
			.then(setConsulta)
			.catch(setHasError)
			.finally(() => setLoading(false))
	}, [match.params.idConsulta])

	useEffect(() => {
		runQuery()
	}, [runQuery])

	const history = useHistory()

	useEffect(() => {
		if (consulta && consulta.omId !== restoreOrgaoId()) {
			if (restoreOrgaoId()) {
				history.replace(`/${consulta.omId}/detalhes/${consulta.id}`)
				setTimeout(() => {
					window.alert(`A ${process.env.REACT_APP_BRANDING_OBJECT} que você abriu pela URL está presente em um órgão diferente do que estava aberto, alteramos ele para uma melhor experiência de utilização.`)
				}, 1000)
			}

			saveOrgaoId(consulta.omId)
			loadOrgaoInfo()
		}
	}, [consulta, loadOrgaoInfo, history])

	const pageContextValue = useMemo(() => ({
		setLoading, setHasError,
		consulta, pageData, setPageData,
		orgaoInfo, orgaoParams,
	}), [consulta, orgaoInfo, orgaoParams, pageData])

	const pageLoadingValue = useMemo(() => ({ loading }), [loading])

	return (
		<MDBContainer className="my-4">
			{!hasError ?
				<PageContext.Provider value={pageContextValue}>
					<PageContextLoadingValue.Provider value={pageLoadingValue}>
						<CardConsultaFinalizadaRelatorios />
						<CardConsultaDetalhes />
					</PageContextLoadingValue.Provider>
				</PageContext.Provider> :
				<NonIdealState
					title={hasError.title}
					description={hasError.description}
					icon={hasError.icon}
					action={hasError.action === 'retry' ? <button onClick={runQuery} className="bp3-button">Tentar novamente</button> : null}
				/>}
			{loading && <Mask overlay="teal-slight" ><Spinner /></Mask>}
		</MDBContainer>
	)
}
