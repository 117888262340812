import { clearAuthStorage } from "components/CardAuthentication/utils"
import { useCallback } from "react"
import { useHistory } from "react-router"

export const useLogoutButtonLogic = ({ afterClick }) => {

	// Faz logout
	const history = useHistory()
	const handleClick = useCallback(() => {
		sessionStorage.removeItem('auth-token')
		clearAuthStorage()
		if (typeof afterClick === 'function')
			afterClick()
		else
			history.push("/entrar")
	}, [history, afterClick])

	return { handleClick }
}