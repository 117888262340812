import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { MDBContainer } from 'mdbreact';
import styled, { createGlobalStyle } from 'styled-components'

import { pageView } from '../services/GA'
import { CardAuthentication } from '../components';
import { SEQUENCIA } from '../components/CardAuthentication';

const GlobalStyle = createGlobalStyle`
	body {
		--primaryBg: var(--Branding-Color);
	}
`

const Logo = styled.img`
    margin: 0 auto 30px;
    display: block;
`

export function ForgotPass() {
	useEffect(pageView, [])

	const history = useHistory()
	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const token = urlParams.get('token')

		if (token) {
			sessionStorage.setItem('auth-token', token)
			history.replace('/forgotPass')
		} else if (!sessionStorage.getItem('auth-token')) {
			history.push('/')
		}
	}, [history])

	return <MDBContainer className="my-4">
		<GlobalStyle />
		<Logo src="/logo_on_branding.png" alt={process.env.REACT_APP_BRANDING_NAME} />
		<CardAuthentication initSequencia={SEQUENCIA.CHANGE} />
	</MDBContainer>
}
