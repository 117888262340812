/** @param {HTMLFormElement} formTarget */
export function getFormData(formTarget) {
	const data = {}

	for (const entry of new FormData(formTarget)) {
		data[entry[0]] = data[entry[0]] ? [data[entry[0]], entry[1]].join(",") : entry[1]
	}
	
	return data
}
