import styled from "styled-components";

export const UsuarioPageContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	min-height: calc(var(--vh, 1vh) * 100);

	.page-container {
		width: 100%;
		max-width: min(1140px, 90vw);
		margin: 0 auto;
	}
`
