/* eslint-disable no-unused-expressions */
import React, { useMemo, useContext, useRef, useCallback, useEffect, useState } from "react"

import { PageContext } from "../../../../pages"
import { useStepper } from "../../../Stepper/utils"
import { checkPassStrength, getFormData, resetCustomValidity } from "../../../../utils"
import { checkEmail } from "../../../../services/Auth_API";
import { updateStorageProfile } from "../../utils";
import { SEQUENCIA } from "../..";
import retryForm from "utils/retryForm";

export const useLogic = ({ switchSequencia }) => {
	const formRef = useRef(),
		submitRef = useRef({}),
		{ next } = useStepper(),
		{ setLoading } = useContext(PageContext),
		[hasError, setError] = useState(false)

	const validateForm = useCallback(() => {
		const data = getFormData(formRef.current)

		let msg = ""

		if (data.senha !== data.resenha) msg = "As senhas não conferem."
		else if (!checkPassStrength(data.senha)) msg = "Senha muito fraca. Por favor, utilize letras minúsculas, maiúsculas, números, com pelo menos 6 caracteres no total."

		formRef.current?.querySelector('[name=resenha]')
			.setCustomValidity(msg)
	}, [])

	const handleNext = useCallback(async e => {
		resetCustomValidity(e.target)
		resetCustomValidity(formRef.current)
		submitRef.current.target = e.target

		setLoading(true)
		validateForm()

		if (formRef.current?.checkValidity()) {
			return new Promise(res => {
				submitRef.current.res = res
			})
		}

		formRef.current?.reportValidity()
		setLoading(false)
		return false
	}, [setLoading, validateForm])

	const handleSubmit = useCallback(e => {
		e.preventDefault()

		setError(false)

		const form = formRef.current
		const data = getFormData(form)

		updateStorageProfile(data)

		checkEmail(data.email).then(r => !!r)
			.catch(message => {
				setError(retryForm(message, e, () => setError(false)))
				return false
			})
			.then(v => {
				const { res } = submitRef.current
				res?.(v)
			})
			.finally(() => setLoading(false))
	}, [setLoading])

	const bottomLinks = useMemo(() => [<>
		Já tem uma conta?
		<a href="#!" onClick={switchSequencia(SEQUENCIA.LOGIN)}>Faça login</a>
	</>], [switchSequencia])

	useEffect(() => {
		window.sessionStorage.setItem("timeRegistrationStarted", Date.now())
	}, [])

	return {
		formRef, bottomLinks,
		handleNext, handleSubmit, handleBtnClick: next,
		hasError,
	}
}
