import React, { useState } from 'react'
import { MDBView } from "mdbreact"

import { Spinner, CardAuthentication } from '../components'
import { Container, Mask } from '../components/MDBStyled'
import { PageContext } from '.'

export function Auth() {
	const [loading, setLoading] = useState(false)
	
	return <MDBView>
		<Container>
			<PageContext.Provider value={{ setLoading }}>
				<CardAuthentication />
			</PageContext.Provider>
		</Container>
		{loading && <Mask overlay="teal-slight" ><Spinner /></Mask>}
		{/* TODO: fix overlay color to match the theme */}
	</MDBView>
}
