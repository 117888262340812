import React, { useState, useEffect, useCallback } from 'react'
import { Spinner as RadialProgress } from '@blueprintjs/core';
import styled from 'styled-components'

import { timeToRead } from "../utils"

const TextCenter = styled(props => <div {...props} />)`
	text-align: center;
`

const StyledRadialProgress = styled(RadialProgress)`
	margin-right: 6px;
`

function LoadingRead({ id, text, setReadingComplete }) {
	const [readStage, setReadStage] = useState(0)

	const startReading = useCallback((readTime, lastReadStage) => {
		if (!id) return

		let localReadStage = lastReadStage || 0
		if (lastReadStage >= 1) {
			setReadStage(1)
			setReadingComplete(true)
			return
		} else {
			setReadingComplete(false)
		}

		let readStartAt = new Date()
		const interval = setInterval(() => {
			const timeReaded = localReadStage * readTime
			const newTimeReaded = timeReaded + (new Date() - readStartAt)
			const newReadStage = newTimeReaded / readTime
			window.sessionStorage.setItem("readStage" + id, newReadStage)

			setReadStage(newReadStage)
			localReadStage = newReadStage
			readStartAt = new Date()
			if (newReadStage >= 1) {
				clearInterval(interval)
				setReadingComplete(true)
			}
		}, 50)

		return () => clearInterval(interval)
	}, [id, setReadingComplete])

	useEffect(() => {
		const readTime = timeToRead(text)
		const lastReadStage = window.sessionStorage.getItem("readStage" + id)
		const stop = startReading && startReading(readTime, lastReadStage)
		if (stop)
			return () => {
				stop()
			}
	}, [id, text, startReading])

	if (readStage < 1)
		return <>
			<StyledRadialProgress
				value={readStage}
				size={RadialProgress.SIZE_SMALL}
			/>
			<TextCenter>Por favor, leia todo o conteúdo.</TextCenter>
		</>
	else
		return <></>
}

export default LoadingRead
