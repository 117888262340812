import React, { useCallback } from 'react'

import { Step } from "../../../Stepper"
import { TitleSetter, BodySetter, FooterSetter, NextButton } from "../../../Stepper/layouts/MDB"
import { Right } from "../../../Stepper/layouts/default"
import { SEQUENCIA } from '../..'
import { useStepper } from '../../../Stepper/utils'
import { NonIdealState } from 'components'

export const ForgotPasswordWarningStep = ({ switchSequencia }) => {
	const { setCursor } = useStepper()
	const handleNext = useCallback(() => {
		setCursor(0)
		switchSequencia(SEQUENCIA.LOGIN)()
	}, [switchSequencia, setCursor])

	return <Step>
		<TitleSetter>Email enviado</TitleSetter>
		<BodySetter>
			<NonIdealState
				icon="my_password"
				description="Verifique sua caixa de entrada por um email de recuperação de senha."
			/>
		</BodySetter>
		<FooterSetter>
			<Right>
				<NextButton
					onClick={handleNext}
				>Entrar</NextButton>
			</Right>
		</FooterSetter>
	</Step>
}