import { setUser } from "services/GA"

export function updateStorageProfile(data) {
	const oldSessionProfile = sessionStorage.getItem('auth-profile')
	const oldProfile = JSON.parse(oldSessionProfile)

	const newProfile = { ...oldProfile, ...data }
	const newSessionProfile = JSON.stringify(newProfile)
	setUser(newSessionProfile.id)
	sessionStorage.setItem('auth-profile', newSessionProfile)

	return newProfile
}
