import { useCallback, useState } from 'react';
import { getStates, getCities } from '@brazilian-utils/brazilian-utils';

const states = getStates().map(s => ({
	text: `(${s.code}) ${s.name}`,
	value: s.code,
}))

export const useLogic = ({ valueDefault }) => {
	const updateCities = useCallback(state => {
		if (!state) return []
		return getCities(state).map(c => ({ text: c, value: c }))
	}, [])

	const [cities, setCities] = useState(updateCities(valueDefault?.[0]))

	const handleStateChange = useCallback(e => {
		const cities = e ? updateCities(e.value) : []
		setCities(cities)
	}, [updateCities])

	return { states, cities, handleStateChange }
}
