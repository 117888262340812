import React, { useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom'
import { MDBContainer, MDBBtn, MDBIcon } from "mdbreact";
import styled from 'styled-components';

import { pageView } from '../services/GA'

import { ReactComponent as skyline } from "../assets/skyline.svg";
import { Brasao, A2HSModal, MyMask } from '../components';

const HomeMask = styled(MyMask)`
	color: var(--HomeMask_color);
	background-color: var(--HomeMask_bg_color);
	position: relative;

	& > svg {
		width: 100vw;
		height: calc(var(--vh) * 100);
		position: absolute;
		transform: translate(-50%, -50%);
		left: 50%;
		top: 50%;
		color: var(--HomeMask_svg);
	}
`
const HomeContainer = styled.div`
	max-width: min(550px, 100%) !important;
	padding: 10px 100px;
	margin-left: -100px;

	@media (max-width: 993px) {
		padding-left: 10px;
		padding-right: 10px;
	}

	:before {
		content: '';
		position: absolute;
		left: 0; right: 0;
		top: 0; bottom: 0;
		background-color: var(--HomeMask_bg_color);
		opacity: .9;
		z-index: 5;
		pointer-events: none;

		@media (min-width: 993px) {
			right: 50vw;
		}
	}

	> * {
		z-index: 10;
	}

	@media (min-width: 993px) {
		margin-right: auto;
	}

	@media (max-width: 993px) {
		margin: auto;
	}
`

function Button({ url, icon, text, className }) {
	return <Link to={url}>
		<MDBBtn tag="span" color="" className={className}>
			<span className="d-inline mr-1">
				<MDBIcon icon={icon} fixed />
			</span>
			{text}
		</MDBBtn>
	</Link>
}
const HomeButton = styled(Button)`
	color: var(--CardButton_color) !important;
	background-color: var(--CardButton_bg_color) !important;
`

const ButtonContainer = styled.div`
	display: flex;
	width: 350px;
	max-width: 100%;

	> a {
		flex: 1;
		display: flex;

		> span {
			flex: 1;
			padding: .84rem 1.14rem !important;
			white-space: nowrap;
		}
	}
`

function Home({ orgaoInfo, install, canInstall }) {
	const { url } = useRouteMatch();

	useEffect(pageView, [])

	return (
		<HomeMask image={orgaoInfo?.imagem_fundo} defaultImage={skyline} >
			<MDBContainer className="flex-center">
				<HomeContainer
					className="flex-center flex-column text-center"
					withOffset={!!orgaoInfo?.brasao}
				>
					<ButtonContainer>
						<HomeButton
							url={`${url.endsWith('/') ? url : url+'/'}ativas`}
							icon="clipboard-check"
							text="Ativas"
						/>
						<HomeButton
							url={`${url.endsWith('/') ? url : url+'/'}finalizadas`}
							icon="clipboard-list"
							text="Finalizadas"
						/>
					</ButtonContainer>
					<Brasao className="mt-4" filled src={orgaoInfo?.brasao} />
					<h2 className="mt-4 mb-0">{orgaoInfo?.nome}</h2>
					<h5>{orgaoInfo?.cidade} {orgaoInfo?.estado}</h5>
					<p
						className="mt-4"
						dangerouslySetInnerHTML={{ __html: orgaoInfo?.textoInicio }}
					></p>
				</HomeContainer>
				<A2HSModal
					canInstall={canInstall}
					install={install}
				/>
			</MDBContainer>
		</HomeMask>
	);
}

export default Home;

export { HomeButton }