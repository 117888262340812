import { useCallback, useEffect, useReducer } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { getAuthState, restoreCacheOrgaoInfo } from "utils";
import { defineAppColors, resetAppColors } from "utils/defineAppColors";

export const useUsuarioPageLayoutLogic = () => {
	const { path } = useRouteMatch()
	const history = useHistory()

	// Check if needs to redirect due to auth state
	useEffect(() => {
		const authState = getAuthState()

		const onlyNoAuth = ["/entrar", "/cadastro"]
		const onlyWithAuth = ["/consultas", "/conta"]
		if (authState && onlyNoAuth.includes(path)) {
			history.push("/conta")
		} else if (!authState && onlyWithAuth.includes(path)) {
			history.push("/entrar")
		}
	}, [path, history])

	useEffect(() => {
		defineAppColors()
		document.body.classList.remove("coloredBg")
		resetAppColors()
	}, [])

	const [{ backUrl, orgName }, setData] = useReducer((prev, cur) => ({...prev, ...cur}), {})
	useEffect(() => {
		const backUrl = sessionStorage.getItem("whereRedirect") || "/"
		const orgaoInfo = restoreCacheOrgaoInfo() || {}
		setData({ backUrl, orgName: orgaoInfo.nome })
	}, [])

	const clearBackUrl = useCallback(() => {
		sessionStorage.removeItem("whereRedirect")
	}, [])

	return { backUrl, orgName, clearBackUrl }
}
