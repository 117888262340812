import React, {
	useCallback, useEffect, useRef, useState, useContext,
} from 'react'

import { ContribuicaoSection, FormContribuição, ServiceAlert } from '.'
import { recuperarRespostas, removerRespostasLocalmente } from './FormContribuição'
import {
	TitleSetter, BodySetter, FooterSetter, NextButton,
} from './Stepper/layouts/MDB'
import { Left, Right } from './Stepper/layouts/default'
import { Step } from './Stepper'
import { useStepper } from './Stepper/utils'
import { resetCustomValidity } from '../utils'
import { removerContribuição } from '../services/Consultas_API'
import { PageContext } from '../pages'
import { modalView, timing } from 'services/GA'
import retryForm from 'utils/retryForm'

function CardConsultaDetalhesStep3() {
	const { consulta, setPageData, setLoading } = useContext(PageContext),
		{
			id, questoes: questoesRecebidas, nome,
			obrigatorio, label_contribuicao, instrucaoJustificativa, max_chars, min_chars,
			segmentos,
		} = consulta

	const [questoes, setQuestoes] = useState(),
		[contrLivre, setContrLivre] = useState(),
		[removable, setRemovable] = useState(false),
		[hasError, setError] = useState(false)

	useEffect(() => {
		function loadAnswers() {
			setLoading(true)
			setError(false)

			sessionStorage.removeItem("timeQuestionaryStarted")
			recuperarRespostas(id, questoesRecebidas)
				.then(({ questoes, contribuicao_livre, remote, ticket }) => {
					setContrLivre(contribuicao_livre)
					setQuestoes(questoes)
					setRemovable(remote)
					setPageData(x => ({
						...x, action: remote ? 'alterada' : 'enviada', id: ticket,
					}))

					if (remote) modalView("Respondendo consulta já respondida")
					else {
						modalView("Respondendo pela primeira vez")
						sessionStorage.setItem("timeQuestionaryStarted", Date.now())
					}
				})
				.catch(message => {
					console.error(message)
					setError({ ...message, onClick: loadAnswers })
				})
				.finally(() => setLoading(false))
		}
		loadAnswers()
	}, [id, questoesRecebidas, setPageData, setLoading])

	const formRef = useRef(),
		{ next } = useStepper(),
		submitRef = useRef({ res: next })

	const handleNext = useCallback(async e => {
		resetCustomValidity(e?.target)
		resetCustomValidity(formRef.current)
		submitRef.current.target = e?.target

		if (formRef.current?.checkValidity()) {
			setLoading(true)
			return new Promise((res, rej) => {
				submitRef.current.res = res
				submitRef.current.rej = message => {
					setError(retryForm(message, { target: formRef.current }, () => setError()))
					rej()
				}
			}).then(x => {
				const start = window.sessionStorage.getItem("timeQuestionaryStarted")
				if (start) {
					timing({
						category: "Consulta",
						variable: "Tempo de resposta",
						value: Date.now() - Number(start),
					})
				}
				return x
			}).catch(() => { return false })
			.finally(setLoading)
		}

		return false
	}, [setLoading])

	const { setCursor } = useStepper()

	const handleDelete = useCallback(evt => {
		const message = "Você tem certeza que deseja apagar sua contribuição?"

		if (!window.confirm(message)) return

		const token = sessionStorage.getItem('auth-token')

		removerContribuição(token, id)
			.then(() => {
				setPageData(x => ({ ...x, action: 'deletada' }))
				removerRespostasLocalmente(id)
				setCursor(4)
			})
			.catch(message => setError(retryForm(message, evt, () => setError(), true)))
	}, [id, setCursor, setPageData])

	// Código para pular o login ao voltar, como parte do #132
	// Manterei comentado para não alterar o comportamento atual
	// const handleBack = useCallback(() => {
	// 	setCursor(0)
	// }, [setCursor])

	return <Step
		onNext={handleNext}
		// handleBack={handleBack}
	>
		<TitleSetter>{nome}</TitleSetter>
		<BodySetter>
			{hasError && <ServiceAlert message={hasError} onClose={() => setError()} autoClose />}
			<FormContribuição
				{...{
					id, questoes, formRef, submitRef,
					obrigatorio, label_contribuicao, instrucaoJustificativa, max_chars, min_chars,
					contrLivre, segmentos,
				}}
				conditionalSectionHeader={<ContribuicaoSection
					title="Seu perfil"
					description="Definir o seu perfil é importante para direcionarmos melhor as perguntas."
					illustration="choice"
				/>}
				mainSectionHeader={<ContribuicaoSection
					title="Questões"
					description="Queremos saber o que você tem a dizer! Expresse a sua opinião sobre o tema nos campos abaixo."
					illustration="questions"
				/>}
			/>
		</BodySetter>
		<FooterSetter stickyFooter>
			{removable && <Left>
				<NextButton
					outline
					onClick={handleDelete}
					type="button"
				>
					Remover
				</NextButton>
			</Left>}
			<Right>
				<NextButton
					type="submit"
					form="formContrib"
				>Contribuir</NextButton>
			</Right>
		</FooterSetter>
	</Step>
}

export default CardConsultaDetalhesStep3
