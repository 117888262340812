import { useCallback, useEffect, useState } from "react"

export const useLogic = () => {
	const [show, setShow] = useState(false)

	useEffect(() => {
		let int = setInterval(() => {
			if (sessionStorage.getItem('pwa-update-available'))
				setShow(true)
		}, 1000)
		
		setTimeout(clearInterval, 60 * 1000, int)
	}, [])

	const handleClick = useCallback(() => {
		sessionStorage.removeItem('pwa-update-available')

		navigator.serviceWorker.ready.then((reg) => {
			const waitingServiceWorker = reg.waiting

			waitingServiceWorker.addEventListener("statechange", event => {
				if (event.target.state === "activated")
					window.location.reload()
			})
			waitingServiceWorker.postMessage({ type: "SKIP_WAITING" })
		})
	}, [])

	return { show, onClick: handleClick }
}
