import styled from "styled-components"
import { MDBContainer } from "mdbreact"

export const Container = styled(MDBContainer)`
	max-width: 350px !important;
	margin: 0 auto;

	padding-bottom: 3rem;
	padding-left: 0;
	padding-right: 0;
	margin-top: 3rem;
`
