import { PageContext, PageContextLoadingValue } from "pages"
import { useCallback, useEffect, useState, useReducer, useContext } from "react"
import { useParams } from "react-router-dom"
import { configuracaoCadastro } from "services"
import mergeDeep from "utils/mergeDeep"

/**
 * @typedef AccountSections
 * @prop {object} identificação
 * @prop {boolean} identificação.Nome
 * @prop {boolean} identificação.CPF
 * @prop {boolean} identificação.RG
 * @prop {object} contato
 * @prop {boolean} contato.Email
 * @prop {boolean} contato.Telefone
 * @prop {boolean} contato.Celular
 * @prop {object} localização
 * @prop {boolean} localização.Cidade_Estado
 * @prop {boolean} localização.Endereço
 * @prop {boolean} localização.CEP
 * @prop {boolean} localização.Localização Geográfica
 * @prop {object} mapeamento
 * @prop {boolean} mapeamento.Sexo
 * @prop {boolean} mapeamento.Escolaridade
 * @prop {boolean} mapeamento.Faixa Etária
 * @prop {boolean} mapeamento.Renda Familiar
 * @prop {boolean} mapeamento.Tratamento
 */

/** @type {AccountSections} */
const defaultSections = {
	identificação: {
		Nome: true,
	},
	contato: {
		Email: true,
	}
}

/**
 * @returns {import("./presenter").CAWFP_props}
 */
export const useCardAuthenticationWithFieldsLogic = () => {
	const { idConsulta } = useParams()
	const { setLoading } = useContext(PageContext)
	const { loading } = useContext(PageContextLoadingValue)
	const [neededSections, setNeededSections] = useState(defaultSections)

	useEffect(() => { (async () => {
		setLoading(true)
		const result = idConsulta ? await configuracaoCadastro(idConsulta) : []
		
		const newData = {}
		for (const field of result) {
			newData[field.categoria] = {
				...(newData[field.categoria] || {}),
				[field.nome]: field.obrigatorio,
			}
		}

		setNeededSections(x => mergeDeep({}, x, newData))
		setLoading(false)
	})()
	}, [idConsulta, setLoading])
	
	const [logged, setLogged] = useState(false)

	const [data, setData] = useReducer((prev, cur) => cur === 'reset' ? {} : ({ ...prev, ...cur }), {})
	const onLogin = useCallback(profile => {
		setData(profile.contribuidor)
		setLogged(true)
	}, [])

	const afterLogout = useCallback(() => {
		setData('reset')
		setLogged()
	}, [])

	return {
		loading,
		neededSections,
		logged,
		data,
		onLogin,
		afterLogout,
	}
}
