import { Spinner, ServiceAlert } from "components";
import { ContribuicaoItem } from "./elements/ContribuicaoItem";
import { DataItem } from "./elements/DataItem";
import { ListaVazia } from "./elements/ListaVazia";
import { Container } from "./styled/Container";

export const ListaContribuicoesPresenter = ({ lista, hasError }) => hasError ? <ServiceAlert message={hasError} autoClose /> : lista ? lista.length ? <Container>
	{lista.map(item => {
		if (item.contribuicao) {
			return <ContribuicaoItem
				key={item.contribuicao.token}
				{...item}
			/>
		} else if (item.data) {
			return <DataItem
				key={item.data}
				data={item.data}
			/>
		}
		return null
	})}
</Container> : <ListaVazia /> : <div><Spinner style={{ margin: "100px 0 0 calc(50% - 1rem)" }} /></div>
