import { useCallback, useEffect } from 'react'

import { useLayout } from './utils'

/**
 * @param {import('./utils').LayoutContext} LayoutContextValue
 * @param {import('./core').CoreProps} CoreValue
 * */
export const useRecursiveLogic = ({ sizesState, dataState }, { back, next }) => {
	const parentCtx = useLayout()
	const parentCtxSizesSetter = (parentCtx && parentCtx.sizesState[1]) || null
	const parentCtxDataStateSetter = (parentCtx && parentCtx.dataState[1]) || null

	const sizesRealState = sizesState[0]
	const currentStep = sizesRealState[sizesRealState.length - 1][1]
	const isFirstStep = currentStep === 0
	const isLastStep = currentStep === sizesRealState[sizesRealState.length - 1][0] - 1

	const overrideBackAndNext = useCallback(() => {
		if (back && next && parentCtxDataStateSetter) {
			parentCtxDataStateSetter(x => ({
				...x,
				back: !isFirstStep && back,
				next: !isLastStep && next,
			}))
		}
	}, [back, next, parentCtxDataStateSetter, isFirstStep, isLastStep])

	useEffect(() => {
		if (parentCtxSizesSetter) {
			parentCtxSizesSetter(s => [s[0], ...sizesRealState])
			overrideBackAndNext()
		}
	}, [parentCtxSizesSetter, sizesRealState, overrideBackAndNext])

	const selfDataStateSetter = dataState[1]
	useEffect(() => {
		if (sizesState[0].length === 1 && !parentCtxDataStateSetter) {
			selfDataStateSetter(x => {
				if (x.next || x.back) {
					delete x.next
					delete x.back
					return {...x}
				}
				return x
			})
		}
	}, [sizesState, parentCtxDataStateSetter, selfDataStateSetter])
}
