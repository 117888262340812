import React from 'react';
import ReactDOM from 'react-dom';
import initGa from './services/GA'
import App from './App';
import isIosSafari from 'utils/isIosSafari';
import * as serviceWorker from './serviceWorker';
import "@blueprintjs/core/lib/css/blueprint.css"
import "@blueprintjs/select/lib/css/blueprint-select.css"
import "@blueprintjs/popover2/lib/css/blueprint-popover2.css"
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import './index.css';

console.clear()

if (!process.env.REACT_APP_ENV)
	throw new Error("You have to configure the environment variables before starting the App.")

if (!isIosSafari()) initGa()

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
const registerOptions = {}
registerOptions.onUpdate = reg => {
	if (reg.waiting)
		sessionStorage.setItem('pwa-update-available', true)
}
serviceWorker.register(registerOptions);
