import { createContext } from 'react'

/**
 * @typedef ReactState
 * @type {[T, React.Dispatch<React.SetStateAction<T>>]}
 * @template T
 */

/**
 * @typedef LayoutContext
 * @property {ReactState<[Number, Number][]>} sizesState
 * @property {ReactState<Any>} dataState
 * @property {Boolean} disabledGoBack
 * @property {LayoutContext} [parentCtx]
 */

/** @type {LayoutContext} */
const LayoutContextDefault = null
export const LayoutContext = createContext(LayoutContextDefault)
