import { Button, H1 } from "@blueprintjs/core";
import { FormLogin, InputPassword } from "components";
import { UsuarioMainPage } from "components/UsuarioPageLayout";
import { MDBInput as Input } from "mdbreact";

export const Entrar = () => <UsuarioMainPage>
	<H1>Entrar</H1>
	<FormLogin>
		{({ loading }) => <>
			<Input
				outline
				name="email"
				label="E-mail"
				icon="at"
			/>
			<InputPassword
				outline
				type="password"
				name="senha"
				label="Senha"
				icon="lock"
			/>
			<Button
				style={{ alignSelf: "center" }}
				type="submit"
				{...{ loading }}
			>ENTRAR</Button>
		</>}
	</FormLogin>
</UsuarioMainPage>
