import React, { useState, useEffect, useCallback } from 'react'
import { Button, MenuItem, Spinner } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'

import { getList } from '../services/Orgao_API';

import { removeAcento } from '../utils/removeAcentos'
import { exception } from 'services/GA';

const orgaoFilter = (query, item) => {
	query = removeAcento(query)
		.toLowerCase()
	return [item.nome, item.cidade, item.estado]
		.find(x => removeAcento(x).toLowerCase().indexOf(query) >= 0);
}

const OrgaoItem = (item, { modifiers, handleClick }) => {
	if (!modifiers.matchesPredicate) {
		return null;
	}
	return <MenuItem
		key={item.id}
		active={modifiers.active}
		text={`${item.nome} - ${item.cidade} ${item.estado}`}
		multiline
		onClick={handleClick}
		shouldDismissPopover={false}
	/>
}

export default function ListaOrgaos({ onSelect, setHasError, intent, large }) {
	const [isLoading, setIsLoading] = useState(true),
		[selected, setSelected] = useState(),
		[options, setOptions] = useState([])

	useEffect(() => {
		setTimeout(() => {
			getList()
				.then(r => setOptions(r))
				.finally(setIsLoading)
				.catch(err => {
					setHasError?.(err)
					exception({
						description: "Servidores indisponíveis",
					})
				})
		}, 2000)
	}, [setHasError])

	const handleItemSelect = useCallback(x => {
		setSelected(x)
		onSelect?.(x)
	}, [onSelect])

	return <Select
		items={options}
		itemRenderer={OrgaoItem}
		onItemSelect={handleItemSelect}
		itemPredicate={orgaoFilter}
		disabled={isLoading}
		inputProps={{
			placeholder: "Buscar..."
		}}
		popoverProps={{
			position: "top"
		}}
	>
		<Button
			text={selected ? selected.nome : "Lista de órgãos"}
			rightIcon={isLoading ? <Spinner size={Spinner.SIZE_SMALL} /> : "double-caret-vertical"}
			intent={intent || "none"}
			disabled={isLoading}
			large={large}
		/>
	</Select>
}