import React, { useEffect } from 'react';
import { MDBContainer } from 'mdbreact';

import {
	Listagem, NonIdealState, ListItemConsultaFinalizada
} from '../components';
import { consultasFinalizadas } from '../services';
import { pageView } from '../services/GA'
import { HomeButton } from './Home';
import { useRouteMatch } from 'react-router';

export default function ConsultasFinalizadas({history}) {
	const { params } = useRouteMatch();

	useEffect(pageView, [])

	return  (
		<MDBContainer className="my-4">
			<Listagem
				{...{ history }}
				query={consultasFinalizadas}
				Card={ListItemConsultaFinalizada}
				Empty={() => <NonIdealState
					icon="blank-canvas"
					title={`Ainda não finalizamos nenhuma ${process.env.REACT_APP_BRANDING_OBJECT_SHORT}`}
					action={<HomeButton
						url={(window.isSelfOrigin ? `/${params.idOrgao}` : '') + '/ativas'}
						icon="clipboard-list"
						text="Ver ativas"
					/>}
				/>}
				type="list"
				maxResults={7}
				searchField="nome"
				searchLabel={`Busque por ${process.env.REACT_APP_BRANDING_OBJECT}`}
			/>
		</MDBContainer>
	)
}
