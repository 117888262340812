export const fields = [{
	type: "email",
	name: "email",
	label: "E-mail",
	autoComplete: "username",
	required: true,
	icon: "envelope",
	'data-alt-name': 'email',
}, {
	type: "password",
	name: "senha",
	label: "Senha",
	autoComplete: "current-password",
	required: true,
	icon: "lock",
	'data-alt-name': 'senha',
	showRequirements: true,
}, {
	type: "password",
	name: "resenha",
	label: "Repita a Senha",
	autoComplete: "current-password",
	required: true,
	icon: "lock",
	'data-alt-name': 'senha',
}, {
	type: "politicaPrivacidade",
	name: "acceptPoliticaPrivacidade",
	required: true,
}]
