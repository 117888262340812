import { useState, useCallback, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import urlUtils from './urlUtils';

function useNavbarNavigation() {
	const [breadCrumb, setBreadCrumb] = useState([]);
	const history = useHistory()

	useEffect(() => {
		return history.listen(location => {
			if (location.pathname.includes("ativas")) {
				if (!breadCrumb.includes("Ativas"))
					setBreadCrumb(["Início", "Ativas"])
			} else if (location.pathname.includes("finalizadas")) {
				if (!breadCrumb.includes("Finalizadas"))
					setBreadCrumb(["Início", "Finalizadas"])
			} else if (location.pathname.includes("detalhes")) {
				if (!breadCrumb.includes("Detalhes")) {
					if (breadCrumb.includes("Ativas") ||
						breadCrumb.includes("Finalizadas")) {
						setBreadCrumb([...breadCrumb, "Detalhes"])
					} else {
						setBreadCrumb(["Início", "Detalhes"])
					}
				}
			} else {
				setBreadCrumb([])
			}
		})
	}, [history, breadCrumb])

	const { url } = useRouteMatch()

	const back = useCallback(() => {
		if (!breadCrumb.length) return

		switch (breadCrumb[breadCrumb.length - 2]) {
			case "Ativas":
				history.push(urlUtils.join(url, "ativas"))
				break;
			case "Finalizadas":
				history.push(urlUtils.join(url, "finalizadas"))
				break;
			case "Início":
				history.push(urlUtils.join(url, "/"))
				break;
			
			default:
				break;
		}
	}, [url, breadCrumb, history])

	return { breadCrumb, back };
}

export default useNavbarNavigation;