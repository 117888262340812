import compose from 'react-hooks-compose'

import { fields } from './fields'
import { AuthStep } from '../../elements/step'
import { useLogic } from './useLogic'

export const useCheckEmailStep = hookProps => compose({
	stepDetails: () => ({
		title: "Cadastre-se",
		formId: "checkEmailForm",
		fields,
		buttonTxt: "Avançar",
	}),
	useLogic: () => useLogic(hookProps),
})(AuthStep)
