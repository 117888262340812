import React, { memo } from 'react'
import {
	MDBCard, MDBProgress, MDBBtn, MDBCardFooter, MDBCardBody, MDBIcon,
	MDBCardTitle, MDBCardHeader,
} from 'mdbreact'
import styled from 'styled-components'

import { Spinner } from '../..'
import { useLayout, useSetter, useStepper } from '../utils'
import { NextButton as DefaultNextButton, BackButton } from './default'
import { Classes } from '@blueprintjs/core'

const StyledBackButton = styled.button`
	padding: 6px;
	cursor: pointer;
	--bgColor: rgba(0,0,0,.03);
	background-color: var(--bgColor);
	border: 0;
	border-radius: 50%;
	transition: background-color .4s;
	display: flex;

	:hover {
		--bgColor: rgba(0,0,0,.1);
	}
`,
	Title = styled(MDBCardTitle)`
		text-align: center;
		margin: 0;
		font-weight: 500;
	`,
	TitleContainer = styled(MDBCardHeader)`
		display: flex;
		justify-content: space-between;
	`,
	TitleSpacer = styled.div`
		flex-grow: 1;
		flex-basis: 0;
	`

const Header = ({ children }) => {
	return <TitleContainer>
		<TitleSpacer>
			<BackButton component={StyledBackButton}>
				<MDBIcon icon="arrow-left" />
			</BackButton>
		</TitleSpacer>
		<Title>{children}</Title>
		<TitleSpacer />
	</TitleContainer>
}

export const TitleSetter = memo(({ children }) => {
	useSetter('title', children)
	return null
})

const colors = ["primary", "secondary"]

const MyMDBProgress = styled(({ color, ...p }) => <MDBProgress {...p} />)`
	.progress-bar {
		background-color: ${props => ({
			primary: "var(--primaryBg)",
			secondary: "var(--secondaryBg)",
		}[props.color])}
	}
`

const Progress = memo(function Progress() {
	const progresses = useLayout().sizesState[0]

	return <>
		{progresses.map(([t, c], i) => <MyMDBProgress
			color={colors[i] || colors[colors.length - 1]}
			key={i}
			height="3px"
			value={(c / (t - 1) * 100) || 0}
		/>)}
	</>
})

const StyledBody = styled(MDBCardBody)`
		& > *:first-child {
			margin-top: 0;
		}
		& > *:last-child {
			margin-bottom: 0;
		}
	`

const Body = ({ children, ...props }) => {
	return <StyledBody {...props}>
		{children || <Spinner
			style={{ margin: "20px 0 20px calc(50% - 1rem)" }}
		/>}
	</StyledBody>
}

export const BodySetter = memo(({ children }) => {
	useSetter('body', children)
	return null
})

const StyledButton = styled(MDBBtn)`
		flex-shrink: 0;
		font-weight: 600 !important;

		&.btn:disabled, &.btn:not(:disabled):not([disabled]):not(.disabled) {
			color: var(--color) !important;
			background-color: var(--bg-color) !important;
			border-color: var(--color) !important;
		}

		&:disabled, &:disabled:hover {
			--color: var(--secondaryBgDark);
			--bg-color: transparent;
		}

		&:not(:disabled), &:not(:disabled):hover {
			--theme-color: var(--CardButton_color);
			--theme-bg-color: var(--CardButton_bg_color);

			--color: ${props => props.outline ?
				"var(--CardButton_outline_color)" :
				"var(--theme-color)"};
			--bg-color: ${props => props.outline ?
				"transparent" :
				"var(--theme-bg-color)"};
		}

		&:not(:disabled):active {

		}
	`

export const NextButton = props => <DefaultNextButton
	component={StyledButton}
	{...props}
/>

const StickyContainer = styled.div`
	position: sticky;
	bottom: 0px;
	z-index: 15;

	&, .card-footer {
		border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
	}
`

const StickyShadow = styled.div`
	pointer-events: none;
	position: sticky;
	bottom: 82px; // Footer hight
	height: 10px;
	width: 100%;
	margin-top: -10px;
	background: radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0)) 0 100%;
	mix-blend-mode: screen;

	/* TODO: fazer funcionar em telas pequenas (o rodapé pode (nem sempre) mudar de tamanho, para comportar os botões de forma vertical) */
	@media (max-width: 768px) {
		bottom: 140px;
	}
	flex-basis: 100%;
`

export const Footer = memo(({ children, stickyFooter, ...props }) => {
	return stickyFooter ? <StickyContainer>
		<MDBCardFooter className="d-flex" {...props}>
		{children}
		</MDBCardFooter>
		<StickyShadow />
	</StickyContainer> : <MDBCardFooter className="d-flex flex-wrap" {...props}>
		{children}
	</MDBCardFooter>
})

export const FooterSetter = memo(({ children, stickyFooter }) => {
	useSetter('footer', children)
	useSetter('stickyFooter', stickyFooter)
	return null
})

export const StepperLayout = memo(() => {
	const { _stepRef } = useStepper()
	const loading = _stepRef.current?.loading
	const layoutCtx = useLayout()
	const {
		title, body, footer, className, ref, stickyFooter,
	} = layoutCtx.dataState[0]

	if (layoutCtx.parentCtx && body) {
		return body
	}

	return <MDBCard
		{...{ ref }}
		className={`${[className, loading && Classes.SKELETON].filter(x => !!x).join(", ")}`}
	>
		<Header>{title}</Header>
		<Progress />
		<Body>{body}</Body>
		<Footer {...{ stickyFooter }}>{footer}</Footer>
	</MDBCard>
})
