import { useCallback, useState } from "react"
import { openLink } from "./openLink"

export const SHARE_SCHEDULED_TEXT = dia => `Essa ${process.env.REACT_APP_BRANDING_OBJECT} será aberta em ${dia}, vamos aguardar para contribuir!`
export const SHARE_OPEN_TEXT = "Veja! Acho importante que você contribua!"
export const SHARE_CLOSED_TEXT = "Dá uma olhada!"
export const SHARE_OPEN_AFTER_TEXT = "Contribua também!"

const canShare = !!(navigator.canShare?.({
	url		: window.location.href,
	title	: '',
	text	: '',
}))

export const useSharePage = (url) => {
	const [shareAPI, resetAPI] = useState(canShare)

	const handler = useCallback(async (title, text) => {
		const finalUrl = url || window.location.href

		if (shareAPI) {
			return navigator.share({ url: finalUrl, title, text }).catch(err => {
				resetAPI()
				throw err
			})
		} else {
			openLink(`http://www.facebook.com/sharer/sharer.php?u=${finalUrl}&quote=${text}`)
		}
	}, [shareAPI, url])

	return [shareAPI, handler]
}
