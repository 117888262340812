import { Spinner, ServiceAlert } from "components";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import { Container } from "./styled/Container";
import { FormContribuição } from "components"
import { FaSave, FaTrash } from "react-icons/fa";
import { H2 } from "@blueprintjs/core";
import { Actions } from "./styled/Actions";
import { PageContext } from "pages";
import { capitalizeFirstLetter } from "utils";

export const NewFormContribuicaoPresenter = ({ loading, setLoading, message, formRef, submitRef, data, handleSave, handleDelete, disabled, estaFinalizada }) => {
	return <>
		{message && <ServiceAlert message={message} autoClose />}

		{!loading && !data && <Route render={() => <Redirect to="/consultas" />} />}

		{data && <Container>
			<br />
			<H2>{data.detalhes.nome}</H2>
			<PageContext.Provider value={{ setLoading, setPageData: () => {} }}>
				<FormContribuição
					{...{
						...data.formContribuicao,
						formRef, submitRef,
						disabled
					}}
				/>
			</PageContext.Provider>

			{!data.estaFinalizada && <Actions>
				<button
					className="bp3-button bp3-outlined bp3-intent-primary"
					type="submit"
					form="formContrib"
					onClick={handleSave}
				><FaSave />ATUALIZAR MINHA CONTRIBUIÇÃO</button>
				<button
					className="bp3-button bp3-outlined bp3-intent-danger"
					onClick={handleDelete}
				><FaTrash />EXCLUIR MINHA CONTRIBUIÇÃO</button>
			</Actions>}
			{data.estaFinalizada && <><hr /><small>{capitalizeFirstLetter(process.env.REACT_APP_BRANDING_OBJECT_SHORT)} encerrada.</small></>}
		</Container>}
		
		{loading && <div><Spinner style={{ margin: "100px 0 0 calc(50% - 1rem)" }} /></div>}
	</>
}