import React, { memo } from 'react'
import { BodySetter } from './Stepper/layouts/MDB'
import { CardAuthentication } from './CardAuthentication'
import { useStepper } from './Stepper/utils'

function CardConsultaDetalhesStep2() {
	const { next } = useStepper()

	return <>
		<BodySetter>
			<CardAuthentication onFinish={next} />
		</BodySetter>
	</>
}

export default memo(CardConsultaDetalhesStep2)
