import React from 'react'
import styled from 'styled-components'
import { MenuItem, Icon } from '@blueprintjs/core'
import { Select as BlueprintSelect } from '@blueprintjs/select'

import { removeAcento } from 'utils/removeAcentos'

export const IconPrefix = styled.i.attrs(props => ({
	className: `fa fa-${props.icon}`,
}))`
	top: .25rem;
	font-size: 1.75rem;
	position: absolute;
	transition: color .2s;

	${props => props.focus && `color: #4285f4;`}
`

export const Input = styled.input`
	display: block;
	width: 100%;
	height: calc(1.5em + .75rem + 2px);
	padding: .375rem .75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

	margin: 0 0 .5rem;

	border: none;
	border-bottom: 1px solid #ced4da;
	border-radius: 0;
	outline: none;
	box-shadow: none;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

	:focus:not([readonly]) {
		border-bottom: 1px solid #4285f4;
		box-shadow: 0 1px 0 0 #4285f4;
	}

	:disabled {
		background-color: rgba(0,0,0,.1);
	}
`

const StyledIcon = styled(Icon).attrs(props => ({
	className: `fa fa-${props.icon}`,
}))`
	cursor: pointer;
	color: grey;
`

export const Caret = styled(StyledIcon)`
	position: absolute;
	right: 10px;
	top: .7rem;
`

export const Reset = styled(StyledIcon)`
	position: absolute;
	right: 40px;
	top: .7rem;
`

export const Label = styled.label`
	position: absolute;
	top: 0;
	left: 0;
	font-size: 1rem;
	color: #757575;
	cursor: text;
	transition: transform .2s ease-out,color .2s ease-out;
	transform: translateY(6px);
	transform-origin: 0 100%;
`

export const Container = styled.div`
	position: relative;
	/* margin-top: 1.5rem; */
	margin-bottom: 1.5rem;

	${props => props.outline && `
		> ${Input} {
			border: 1px solid #dadce0;
			border-radius: 4px;
			transition: all .3s;

			:-webkit-autofill {
				+ ${Label} {
					font-size: .8rem;
					transform: translateY(-25px);
					transform-origin: 0 0;
				}
			}

			:focus:not([readonly]) {
				border-color: #4285f4;
				box-shadow: inset 0 0 0 1px #4285f4;
			}

			:focus ~ ${Label},
			:focus ~ ${Caret},
			:focus ~ ${Reset} {
				color: #4285f4;
			}
		}
	`}

	> ${IconPrefix} {
		${props => props.outline && `
			top: .6rem;
			font-size: 20px;
		`}

		~ ${Input} {
			${props => props.outline ? `
				width: calc(100% - 2rem);
				margin-left: 2rem;
				padding-right: 65px;
			` : `
				width: calc(100% - 2.5rem);
				margin-left: 2.5rem;
			`}
		}

		~ ${Label} {
			${props => props.outline && `
				margin-left: 2rem;
			`}
		}
	}

	> ${Label} {
		font-weight: 300;
		max-width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		${props => props.outline && `
			padding-left: 10px;
		`}

		${props => props.active ? `
			left: 8px;
			padding-right: 5px;
			padding-left: 5px;
			font-weight: 500;
			background: #fff;
			font-size: 1rem;
			transform: translateY(-14px) scale(.8);
		` : `
			pointer-events: none;
		`}
	}
`

export const SelectWrapper = styled(BlueprintSelect)`
	.bp3-popover-target {
		display: block !important;
	}
`

export const basicSearch = (query, item) => {
	query = removeAcento(query).toLowerCase()
	return [item.text]
		.find(x => removeAcento(x).toLowerCase().indexOf(query) >= 0)
}

export const BasicSelectItem = (item, { modifiers, handleClick }) => {
	if (!modifiers.matchesPredicate) {
		return null;
	}
	return <MenuItem
		key={item.value}
		active={modifiers.active}
		text={item.text}
		multiline
		onClick={handleClick}
		shouldDismissPopover={false}
	/>
}
