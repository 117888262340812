import { H1 } from "@blueprintjs/core";
import { ModalPoliticaPrivacidade } from "components/ModalPoliticaPrivacidade";
import { UsuarioMainPage } from "components/UsuarioPageLayout";

export const PoliticaPrivacidade = () => <UsuarioMainPage>
	<H1>Política de Privacidade</H1>
	<p style={{ textAlign: 'justify' }}>Realizar uma contribuição é um ato público. Todos os dados coletados através de uma {process.env.REACT_APP_BRANDING_OBJECT} devem ser armazenados e disponibilizados de forma pública. Estes dados pertencem unicamente ao órgão público que realizou a {process.env.REACT_APP_BRANDING_OBJECT} e portanto cada órgão possui sua política própria.</p>

	<div style={{ textAlign: 'center' }}>
		<ModalPoliticaPrivacidade />
	</div>
</UsuarioMainPage>
