import React, {
	memo,
	useCallback, useState, useContext,
} from 'react'
import {
	TitleSetter, BodySetter, FooterSetter, NextButton,
} from './Stepper/layouts/MDB'
import { Right } from './Stepper/layouts/default'
import { useStepper } from './Stepper/utils'
import { PageContext } from '../pages'
import { CardAuthenticationWithFields, ServiceAlert } from '.'
import { recuperarRespostas, removerRespostasLocalmente } from './FormContribuição'
import { Step } from './Stepper'
import { enviarContribuição } from 'services'

function CardConsultaDetalhesStep4V3() {
	const { next } = useStepper()
	const { consulta, setLoading, setPageData } = useContext(PageContext),
		{ id, questoes: questoesRecebidas } = consulta,
		[hasError, setError] = useState(false),
		[shouldNext, setShouldNext] = useState(false)

	const handleNext = useCallback(async e => {
		setLoading(true)
		// e.preventDefault()
		const { questoes, contribuicao_livre } = await recuperarRespostas(id, questoesRecebidas)
		const questions = questoes.map(c => {
			const r = {
				tipo: c.tipo,
				questao_id: c.id,
			}
			switch (c.tipo) {
				case "discursiva":
					r.resposta = c.resposta
					break;

				case "cidades":
					r.cidades = c.resposta
					break;

				case "objetiva":
				case "checkbox":
					r.texto_outros = c.respostaTextoOutros
				// eslint-disable-next-line
				case "tabela_objetiva":
				case "condicional":
					r.alternativa_id = c.resposta;
					r.pergunta_id = c.pergunta;
					break;

				default:
					break;
			}
			return r;
		})
		
		let token = sessionStorage.getItem('auth-token')
		const response = await enviarContribuição(token, id, questions, null, contribuicao_livre)
			.then(r => {
				removerRespostasLocalmente(id)
				r.dataCriacao = new Date(r.dataCriacao.replace("[UTC]", ""))
					.toLocaleString()
				setPageData(x => ({ ...x, ...r, action: 'enviada' }))

				return !!r
			})
			.catch(message => {
				setError(message)
				return false
			})
		setLoading(false)

		if (response) {
			setShouldNext(true)
			next()
		}
	}, [id, questoesRecebidas, setPageData, next, setLoading])

	return <Step onNext={() => shouldNext}>
		<TitleSetter>Enviar</TitleSetter>
		<BodySetter>
			{hasError && <ServiceAlert message={hasError} onClose={() => setError()} autoClose />}

			<CardAuthenticationWithFields afterUpdate={handleNext} />
		</BodySetter>

		<FooterSetter stickyFooter>
			<Right>
				<NextButton
					type="submit"
					form="formInfo"
				>Contribuir</NextButton>
			</Right>
		</FooterSetter>
	</Step>
}

export default memo(CardConsultaDetalhesStep4V3)
