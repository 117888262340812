import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import { serviceToken } from './utils';
import useA2HS from './utils/useA2HS';
import Orgao from './components/Orgao';
import { ErrorScreen, Spinner, UsuarioPageLayout, AcceptCookies } from './components';
import { restoreOrgaoId, clearOrgaoId, saveOrgaoId, clearCacheOrgaoInfo } from './utils/cacheOrgaoInfo';
import SelectOrgao from './pages/SelectOrgao';
import { useCallback } from 'react';
import { resetAppColors } from './utils/defineAppColors';
import { Cadastro, Conta, Entrar, ForgotPass, Recuperar } from './pages';
// import "./utils/css/MDBProFormCheck.css"
import 'moment/locale/pt-br';
import { event } from './services/GA'
import { Consultas } from 'pages/Consultas';
import { Politicas } from 'pages/Politicas';
import { findOrgaoByOrigin } from 'services/Orgao_API';
import urlUtils from 'utils/urlUtils';
import { Button } from '@blueprintjs/core';
import { Desativar } from 'pages/Conta/Desativar';
import isIosSafari from 'utils/isIosSafari';
import 'utils/updateVh'

export default function App() {
	const onAppInstalled = useCallback(() => {
		event({
			category: "PWA",
			action: "Instalado",
		})
	}, [])

	const [userToken, setUserToken] = useState(serviceToken.get()),
		[install, canInstall] = useA2HS(onAppInstalled),
		[isLoading, setIsLoading] = useState(true),
		[hasId, setHasId] = useState(false)

	const resetOrgao = useCallback(() => {
		setHasId()
		clearOrgaoId()
		clearCacheOrgaoInfo()
		resetAppColors()
		event({
			category: "Navegação",
			action: "Alterar Órgão",
		})
		delete window.alreadyRedirected
	}, [])

	useEffect(() => {
		userToken && serviceToken.set(userToken)
		!userToken && serviceToken.delete()
	}, [userToken])

	const [customOriginOrgaoData, setCustomOriginOrgaoData] = useState()
	const loadCustomOrigin = useCallback(async () => {
		const origin = window.location.origin.replace(/^https?:\/\//g, "")
		console.log(`Looking for ${origin}`)
		const orgao = await findOrgaoByOrigin(origin).catch(() => { })

		if (!orgao) return

		event({
			category: "Navegação",
			action: "Domínio customizado",
		})

		setCustomOriginOrgaoData(orgao)
	}, [])

	let orgaoRoute = null
	if (customOriginOrgaoData) {
		orgaoRoute = <Route
			path="/"
			component={props => {
				saveOrgaoId(customOriginOrgaoData.id)
				return <Orgao
					{...{
						...props,
						install,
						canInstall,
						userToken,
						setUserToken,
						resetOrgao,
					}}
				/>
			}}
		/>
	} else {
		orgaoRoute = <Route
			path="/:idOrgao(\d+)"
			component={props => {
				saveOrgaoId(props.match.params.idOrgao)
				return <Orgao
					{...{
						...props,
						install,
						canInstall,
						userToken,
						setUserToken,
						resetOrgao,
					}}
				/>
			}}
		/>
	}

	const origin = window.location.origin + "/"
	window.isSelfOrigin = origin.endsWith(process.env.REACT_APP_SELF_ORIGIN)

	useEffect(() => {
		(async () => {
			setIsLoading(true)
			const id = restoreOrgaoId(),
				path = window.location.pathname,
				targetPath = 'detalhes/',
				inDetails = path.includes(targetPath)

			setHasId(!!id || inDetails)
			if (!window.isSelfOrigin) {
				await loadCustomOrigin()
			}
			setIsLoading(false)
		})()
	}, [loadCustomOrigin])

	if (isLoading) {
		return <Spinner
			style={{ margin: "100px 0 0 calc(50% - 1rem)" }}
		/>
	}

	if (!isLoading && !window.isSelfOrigin && !orgaoRoute) {
		let url = urlUtils.join(process.env.REACT_APP_SELF_ORIGIN, '-')
		// window.location = url
		return <ErrorScreen
			desc={`Parece que este domínio não foi configurado corretamente para hospedar o ${process.env.REACT_APP_BRANDING_NAME}.`}
			action={<a href={url}><Button text="Acessar o site oficial" /></a>}
		/>
	}

	return <Router basename={process.env.REACT_APP_BASE_PATH}>
		<Switch>
			<Redirect
				from="/.well-known/change-password"
				to="/conta/seguranca"
			/>
			<Route path="/forgotPass">
				<ForgotPass />
			</Route>
			<Route path="/conta/desativar">
				<Desativar />
			</Route>
			<Route
				path="/-"
				exact
				render={() => {
					resetOrgao()
					return <Redirect to="/" />
				}}
			/>
			<Route
				path={["/entrar", "/cadastro", "/recuperar", "/consultas", "/conta", "/politicas"]}
				render={() => <UsuarioPageLayout>
					<Switch>
						<Route path="/entrar" component={Entrar} />
						<Route path="/cadastro" component={Cadastro} />
						<Route path="/recuperar" component={Recuperar} />

						<Route path="/consultas" component={Consultas} />

						<Route path="/conta" component={Conta} />
						<Route path="/politicas" component={Politicas} />
					</Switch>
				</UsuarioPageLayout>}
			/>
			{orgaoRoute}
			<Route
				path="/"
				exact
				render={() => {
					if (hasId) {
						return <Redirect to={`${restoreOrgaoId()}`} />
					}

					return <SelectOrgao {...{ setHasId }} />
				}}
			/>
			<Route render={() => <Redirect to="/" />} />
		</Switch>
		{!isIosSafari() && <AcceptCookies />}
	</Router>;
}
