import { Link } from 'react-router-dom';
import {
	MDBListGroupItem, MDBCollapse, MDBRow, MDBCol,
} from 'mdbreact';
import styled from 'styled-components'
import moment from 'moment'
import { Popover2 } from '@blueprintjs/popover2';
import { Icon as BPIcon, AnchorButton, Button, ButtonGroup, Menu, MenuItem } from '@blueprintjs/core';

const Title = styled(props => <div {...props} />)`
	max-width: calc(100% - 20px - 1rem);
`
const Name = styled(props => <h4 {...props}>{props.children}</h4>)`
	font-size: 1.2em;
	font-weight: 500;
`

const Body = styled.div`
	overflow: hidden;
`

const Dates = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;

	li > span {
		font-weight: 500;
	}
`

export default function ListItemConsultaFinalizada({ consulta }) {
	const inicio = moment
		.utc(consulta.datainicio.replace("[UTC]", ""))
		.utcOffset(consulta.fusoHorario)
		.local()
		
	const fim = moment
		.utc(consulta.datatermino.replace("[UTC]", ""))
		.utcOffset(consulta.fusoHorario)
		.local()
	const duração = inicio.from(fim, true)

	return <MDBListGroupItem>
		<div
			className="d-flex justify-content-between align-items-center"
		>
			<Title>
				<Name className="ellipsis-text two-lines">{consulta.nome}</Name>
				<Body dangerouslySetInnerHTML={{ __html: consulta.ementa }}></Body>
			</Title>
		</div>
		<MDBCollapse isOpen={true}>
			<Dates>
				<li><span>Teve início em: </span>{inicio.format("dddd, D [de] MMMM [de] YYYY [às] HH:mm:ss")}</li>
				<li><span>Encerrou em: </span>{fim.format("dddd, D [de] MMMM [de] YYYY [às] HH:mm:ss")}</li>
				<li><span>Duração: </span>{duração}</li>
			</Dates>
			<MDBRow>
				<MDBCol xs="12" sm="auto" className="my-2">
					<Link
						to={`detalhes/${consulta.id}`}
						className="bp3-button bp3-outlined"
					>
						<BPIcon icon="label" />
						<span className="bp3-button-text">Ver detalhes</span>
					</Link>
				</MDBCol>
				{consulta.urlRelatorio && <MDBCol xs="12" sm="auto" className="my-2">
					<ButtonGroup>
						<AnchorButton
							outlined
							href={consulta.urlRelatorio}
							target="_blank"
							rel="noopener noreferrer"
							text={consulta.urlRelatorioCsv ? "Relatório" : "Relatório PDF"}
							icon="document" />
						{consulta.urlRelatorioCsv && <Popover2 placement="bottom" content={<Menu>
							<MenuItem
								text="Formato PDF"
								href={consulta.urlRelatorio}
								target="_blank"
								rel="noopener noreferrer"
								icon="document"
							/>
							<MenuItem
								text="Formato CSV"
								href={consulta.urlRelatorioCsv}
								target="_blank"
								rel="noopener noreferrer"
								icon="panel-table"
							/>
						</Menu>}>
							<Button outlined rightIcon="caret-down" />
						</Popover2>}
					</ButtonGroup>
				</MDBCol>}
				{consulta.urlRelatorioPersonalizado && <MDBCol xs="12" sm="auto" className="my-2">
					<AnchorButton
						outlined
						href={consulta.urlRelatorioPersonalizado}
						target="_blank"
						rel="noopener noreferrer"
						icon="book"
						text="Publicação"
					/>
				</MDBCol>}
			</MDBRow>
		</MDBCollapse>
	</MDBListGroupItem>
}
