export function getTextLegibility(color, textColor) {
	return color ? getTextLegibilityFromParams(color, textColor) : null
}

export function getTextLegibilityFromParams(e, t) {
	if (!e)
		return null;
	var r = getMinAndRecAlphasFromBgAndTextColors(e, t, "large");
	if (null === r.minAlpha)
		return 2;
	var o = getMinAndRecAlphasFromBgAndTextColors(e, t, "normal");
	return null === o.minAlpha ? 1 : 0
}

export function getMinAndRecAlphasFromBgAndTextColors(e, t) {
	var r = arguments.length <= 2 || void 0 === arguments[2] ? "large" : arguments[2]
		, o = "large" === r ? 3 : 4.5
		, i = minAcceptableAlpha(e, t, o);
	return {
		minAlpha: i,
		recAlpha: i ? Math.max(i, .87) : null
	}
}
export function minAcceptableAlpha(e, t, r) {
	var o = hex2Rgb(e)
		, i = hex2Rgb(t)
		, n = 0
		, c = 1
		, a = blendForegroundContrast(o, i, n);
	if (a >= r)
		return null;
	var l = blendForegroundContrast(o, i, c);
	if (r > l)
		return null;
	for (var s = 0, m = 10, d = .01; m >= s && c - n > d; ) {
		var h = (n + c) / 2
			, p = blendForegroundContrast(o, i, h);
		if (r > p) n = h; else c = h;
		++s
	}
	return s > m ? null : c
}
export function hex2Rgb(e) {
	if (!e)
		return null;
	var t = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	e = e.replace(t, function(e, t, r, o) {
		return t + t + r + r + o + o
	});
	var r = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(e);
	return r ? {
		r: parseInt(r[1], 16),
		g: parseInt(r[2], 16),
		b: parseInt(r[3], 16)
	} : null
}
export function blendForegroundContrast(e, t, r) {
	if (1 > r) {
		var o = overlayOn(e, t, r);
		return opaqueContrast(o, e)
	}
	return opaqueContrast(e, t)
}
export function overlayOn(e, t, r) {
	var o = {};
	return r >= 1 ? e : (o.r = t.r * r + e.r * (1 - r),
	o.g = t.g * r + e.g * (1 - r),
	o.b = t.b * r + e.b * (1 - r),
	o.a = r + 1 * (1 - r),
	o)
}
export function opaqueContrast(e, t, r) {
	var o = getLFromRgbColor(t) + .05
		, i = getLFromRgbColor(e) + .05
		, n = o / i;
	i > o && (n = 1 / n)
	return n
}
export function isTextLegibleOverBackground(e, t) {
	var r = arguments.length <= 2 || void 0 === arguments[2] ? 14 : arguments[2]
		, o = arguments.length <= 3 || void 0 === arguments[3] ? 300 : arguments[3]
		, i = getLFromHex(e)
		, n = getLFromHex(t)
		, c = !1;
	if (i !== !1 && n !== !1) {
		var a = (14 === r && o >= 700) || r >= 18
			, l = (Math.max(i, n) + .05) / (Math.min(i, n) + .05);
		c = l >= 3 && a ? !0 : l >= 3 && 4.5 > l && !a ? !1 : l >= 4.5 && !a ? !0 : !1
	}
	return c
}
export function getLFromHex(e) {
	var t = hex2Rgb(e);
	return getLFromRgbColor(t)
}
export function getLFromRgbValue(e) {
	var t = e / 255;
	return .03928 > t ? t / 12.92 : Math.pow((t + .055) / 1.055, 2.4)
}
export function getLFromRgbColor(e) {
	var t = {};
	t.r = getLFromRgbValue(e.r)
	t.g = getLFromRgbValue(e.g)
	t.b = getLFromRgbValue(e.b)
	return .2126 * t.r + .7152 * t.g + .0722 * t.b
}
export function getRgbaFromHexAndAlpha(e, t) {
	var r = hex2Rgb(e);
	t = t ? t.toFixed(2) : 1
	return "rgba(" + r.r + ", " + r.g + ", " + r.b + ", " + t + ")"
}
export function formatHex(e) {
	return e ? "#" !== e[0] ? "#" + e : e : null
}
export function rgb2Hex(e) {
	var t = Math.round(e.b) + 256 * Math.round(e.g) + 65536 * Math.round(e.r);
	return "#" + ("000000" + t.toString(16)).substr(-6)
}
export function rgb2Hsv(e, t, r) {
	var o = 0
		, i = 0
		, n = 0;
	e = parseInt(("" + e).replace(/\s/g, ""), 10)
	t = parseInt(("" + t).replace(/\s/g, ""), 10)
	r = parseInt(("" + r).replace(/\s/g, ""), 10)
	if (!(null === e || null === t || null === r || isNaN(e) || isNaN(t) || isNaN(r) || 0 > e || 0 > t || 0 > r || e > 255 || t > 255 || r > 255)) {
		e /= 255
		t /= 255;
		r /= 255;
		var c = Math.min(e, Math.min(t, r))
			, a = Math.max(e, Math.max(t, r));
		if (c === a) {
			n = c
			return {
				h: 0,
				s: 0,
				v: n
			};
		}

		var l = e === c ? t - r : r === c ? e - t : r - e
			, s = e === c ? 3 : r === c ? 1 : 5;
		o = 60 * (s - l / (a - c))
		i = (a - c) / a
		n = a
		return {
			h: o,
			s: i,
			v: n
		}
	}
}
export function distanceBetweenTwoPoints(e, t) {
	var r = e.x
		, o = e.y
		, i = t.x
		, n = t.y;
	return Math.sqrt(Math.pow(r - i, 2) + Math.pow(o - n, 2))
}
export function hsv2Rgb(e, t, r) {
	var o = r * t
		, i = e / 60
		, n = o * (1 - Math.abs(i % 2 - 1))
		, c = r - o
		, a = [];
	"undefined" === typeof e ? a = [0, 0, 0] : 1 > i ? a = [o, n, 0] : 2 > i ? a = [n, o, 0] : 3 > i ? a = [0, o, n] : 4 > i ? a = [0, n, o] : 5 > i ? a = [n, 0, o] : 6 >= i && (a = [o, 0, n]);
	var l = 255 * (a[0] + c)
		, s = 255 * (a[1] + c)
		, m = 255 * (a[2] + c);
	return {
		r: l,
		g: s,
		b: m
	}
}
export function hex2Hsv(e) {
	var t = hex2Rgb(e);
	return rgb2Hsv(t.r, t.g, t.b)
}
export function hsv2Hex(e, t, r) {
	var o = hsv2Rgb(e, t, r);
	return rgb2Hex({
		r: o.r,
		g: o.g,
		b: o.b
	})
}
export function rgba2rgb(e) {
	var t = {
		r: e.r,
		g: e.g,
		b: e.b
	};
	return e.a ? (t.r = Math.round(255 * (1 - e.a) + e.a * e.r),
	t.g = Math.round(255 * (1 - e.a) + e.a * e.g),
	t.b = Math.round(255 * (1 - e.a) + e.a * e.b),
	t) : e
}
export function rgba2hex(e) {
	var t = rgba2rgb(e);
	return rgb2Hex(t)
}
export function darkenHex(e, t) {
	return rgb2Hex(darkenRgb(hex2Rgb(e), t))
}
export function darkenRgb(e, t) {
	var r = {}
		, o = {
		r: 0,
		g: 0,
		b: 0
	};
	for (var i in o)
		r[i] = parseInt((o[i] - e[i]) * t + e[i], 10);
	return r
}
export function lightenHex(e, t) {
	return rgb2Hex(lightenRgb(hex2Rgb(e), t))
}
export function lightenRgb(e, t) {
	var r = {}
		, o = {
		r: 255,
		g: 255,
		b: 255
	};
	for (var i in o)
		r[i] = parseInt((o[i] - e[i]) * t + e[i], 10);
	return r
}

export function getTextColorFromBgColor(color) {
	const w = getTextLegibility(color, "#FFFFFF"),
		b = getTextLegibility(color, "#000000")

	return (w < b) ? "#FFFFFF" : "#000000"
}