import compose from 'react-hooks-compose'

import { fields } from './fields'
import { useLogic } from './useLogic'
import { AuthStep } from '../../elements/step'

export const useForgotSteps = hookProps => compose({
	stepDetails: () => ({
		title: "Recuperar senha",
		formId: "forgotPass",
		fields,
		buttonTxt: "Recuperar",
	}),
	useLogic: () => useLogic(hookProps),
})(AuthStep)
