import { throwIfNotOk } from '.'
import { MESSAGES } from '../services/enums/Errors'

/**
 * @function defaultFetch
 * @param {RequestInfo} url
 * @param {RequestInit} init
 * @return {Promise}
 */
export default function defaultFetch(url, init) {
	return fetch(url, {
		...init,
		headers: {
			"Accept": "application/json",
			"Content-Type": "application/json",
			...((init && init.headers) || {}),
		}
	})
		.then(throwIfNotOk)
		// .then(throwIfNotJson)
		.then(r => {
			if (r.status === 204) return r.ok

			if (r.headers.get("content-type")?.includes("application/json")) {
				return r.clone().json()
			}

			return r.ok
		})
		.catch(async err => {
			if (err?.headers?.get("content-type")?.includes("application/json")) {
				throw await err.clone().json()
			}

			throw err
		})
		.catch(async err => {
			let error = MESSAGES.default

			if (err.message === "Failed to fetch") { // fetch errors
				error = MESSAGES["server/unreachable"]
			} else if (err.code) { // new API
				error = MESSAGES[err.code] || MESSAGES.default

				if (err.code === 'auth/fail') {
					sessionStorage.removeItem('auth-token')
				}
			} else if (err.status) { // old API and non-json errors
				switch (err.status) {
					case 500:
						error.mensagem = "Ops, parece que este serviço está indisponível."
						break;

					default:
						// leave error as is
				}
				error.mensagem += ` Código: ${err.status || err.statusCode}.`
			}

			throw error
		})
}