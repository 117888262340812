import { useState, useEffect, useCallback } from "react";
import { NavLink } from "react-router-dom";
import { Dialog } from "@blueprintjs/core";

import { NonIdealState } from ".";
import styled from "styled-components";
import { clearAuthStorage } from "./CardAuthentication/utils";

const DEFAULT_COLOR = "#0895d1"

const ACTIONS = {
	login: () => <NavLink
		to="/entrar"
		className="bp3-button"
		onClick={() => { clearAuthStorage(); sessionStorage.setItem('whereRedirect', window.location.pathname) }}
	>Fazer login</NavLink>,
	retry: ({ message }) => <button onClick={message.onClick} className="bp3-button">Tentar novamente</button>,
	close: ({ handleClose }) => <button onClick={handleClose} className="bp3-button">Voltar</button>,
}

const DialogStyled = styled(Dialog)`
	color: var(--primaryTxLight);
`

export const ServiceAlert = ({ message, isOpen = true, title, onClose, autoClose = false, ...props }) => {
	const [internalIsOpen, setIsOpen] = useState(!!(message && isOpen))

	useEffect(() => { setIsOpen(!!(message && isOpen)) }, [message, isOpen])

	const handleClose = useCallback(x => {
		if (autoClose) setIsOpen(false)

		if (typeof onClose === 'function') onClose(x)
	}, [onClose, autoClose])

	if (!message) return null

	const Action = ACTIONS[message.action]

	return <DialogStyled
		isOpen={internalIsOpen}
		onClose={handleClose}
		title={title || (autoClose ? "" : null)}
		{...props}
	>
		<NonIdealState
			icon={message.icon || "fixing_bugs"}
			title={message.title || "Ocorreu um erro"}
			description={message.description || ""}
			color={message.color || DEFAULT_COLOR}
			action={Action ? <Action {...{ message, handleClose }} /> : null}
		/>
	</DialogStyled>
}
