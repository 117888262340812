import React, {
	useCallback, useEffect, useRef, useState, useContext,
} from 'react'

import { ContribuicaoSection, FormContribuição, ServiceAlert } from '.'
import {
	TitleSetter, BodySetter, FooterSetter, NextButton,
} from './Stepper/layouts/MDB'
import { Right } from './Stepper/layouts/default'
import { Step } from './Stepper'
import { useStepper } from './Stepper/utils'
import { resetCustomValidity } from '../utils'
import { PageContext } from '../pages'
import { timing } from 'services/GA'
import retryForm from 'utils/retryForm'

function CardConsultaDetalhesStep2V2() {
	const { consulta, setPageData, setLoading } = useContext(PageContext),
		{
			id, questoes, nome,
			obrigatorio, label_contribuicao, instrucaoJustificativa, max_chars, min_chars,
			segmentos,
		} = consulta
	
	useEffect(() => {
		setPageData(x => ({
			...x, action: 'enviada',
		}))
	}, [setPageData])

	const [hasError, setError] = useState(false)

	const formRef = useRef(),
		{ next } = useStepper(),
		submitRef = useRef({ res: next })

	const handleNext = useCallback(async e => {
		resetCustomValidity(e?.target)
		resetCustomValidity(formRef.current)
		submitRef.current.target = e?.target

		if (formRef.current?.checkValidity()) {
			setLoading(true)
			return new Promise((res, rej) => {
				submitRef.current.res = res
				submitRef.current.rej = message => {
					setError(retryForm(message, { target: formRef.current }, () => setError()))
					rej()
				}
			}).then(x => {
				const start = window.sessionStorage.getItem("timeQuestionaryStarted")
				if (start) {
					timing({
						category: "Consulta",
						variable: "Tempo de resposta",
						value: Date.now() - Number(start),
					})
				}
				return x
			}).catch(() => { return false })
			.finally(setLoading)
		}

		return false
	}, [setLoading])

	// Código para pular o login ao voltar, como parte do #132
	// Manterei comentado para não alterar o comportamento atual
	// const handleBack = useCallback(() => {
	// 	setCursor(0)
	// }, [setCursor])

	return <Step
		onNext={handleNext}
		// handleBack={handleBack}
	>
		<TitleSetter>{nome}</TitleSetter>
		<BodySetter>
			{hasError && <ServiceAlert message={hasError} onClose={() => setError()} autoClose />}
			<FormContribuição
				{...{
					id, questoes, formRef, submitRef,
					obrigatorio, label_contribuicao, instrucaoJustificativa, max_chars, min_chars,
					segmentos,
				}}
				conditionalSectionHeader={<ContribuicaoSection
					title="Seu perfil"
					description="Definir o seu perfil é importante para direcionarmos melhor as perguntas."
					illustration="choice"
				/>}
				mainSectionHeader={<ContribuicaoSection
					title="Questões"
					description="Queremos saber o que você tem a dizer! Expresse a sua opinião sobre o tema nos campos abaixo."
					illustration="questions"
				/>}
				profileSectionHeader={<ContribuicaoSection
					title="Sobre você"
					description="Saber quem você é, nos ajuda a entender melhor as respostas de um grupo. Evite fraudes, envie somente dados legítimos ou sua contribuição pode ser invalidada."
					illustration="social_bio"
				/>}
			/>
		</BodySetter>
		<FooterSetter stickyFooter>
			<Right>
				<NextButton
					type="submit"
					form="formContrib"
				>Contribuir</NextButton>
			</Right>
		</FooterSetter>
	</Step>
}

export default CardConsultaDetalhesStep2V2
