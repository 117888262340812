import React from 'react'
import styled from 'styled-components'

function Spinner({ className, ...props }) {
	return (
		<div className={`spinner-grow fast ${className}`} role="status" {...props}>
			<span className="sr-only">Loading...</span>
		</div>
	)
}

export default styled(Spinner)`
	color: var(--Spinner_${props => props.white ? "normal" : "primary"})
`