import React from "react";
import { MDBFooter, MDBContainer } from "mdbreact";
import { AnchorButton, Icon } from "@blueprintjs/core";
import styled from "styled-components";

import { AvaliarComASES } from "./AvaliarComASES";
import { OpenEmail } from "./OpenEmail";
import { InstallTWA } from "."

const domains = {
	conpub: "consultapublica.com.br",
	avacid: "avaliecidades.com.br",
}

const admUrl = branding => process.env.REACT_APP_ENV !== 'production'
	? `http://insiders.adm.${domains[branding] ?? domains["conpub"]}:8080`
	: `https://sistema.${domains[branding] ?? domains["conpub"]}`

const Footer = ({ orgaoInfo, resetOrgao, className, branding }) => {
	return (
		<MDBFooter
			className={`font-small footer-copyright text-center py-3 ${className}`}
			style={{ color: "inherit", zIndex: 10 }}
		>
			<MDBContainer fluid>
				<AnchorButton
					text="Acesso Administrativo"
					href={`${admUrl(branding)}/?id=${orgaoInfo?.id}&redirect=adm`}
					minimal
					rightIcon={<Icon
						icon="shield"
						style={{ color: "inherit" }}
					/>}
					style={{ textDecoration: 'underline' }}
				/>

				{' | '}

				<AnchorButton
					rel="noopener noreferrer"
					text="Feedback"
					href="http://consultapublica.com.br/feedback/"
					target="_blank"
					minimal
					rightIcon={<Icon
						icon="chat"
						style={{ color: "inherit" }}
					/>}
					style={{ textDecoration: 'underline' }}
				/>

				<br />
				<br />

				<AvaliarComASES />
				<InstallTWA />

				<br />

				&copy; {new Date().getFullYear()} Copyright: <a href={`https://www.${domains[branding]}`}> {process.env.REACT_APP_BRANDING_NAME}</a> v{process.env.REACT_APP_VERSION}

				<br />
				<br />

				<small>Solução de problemas: <OpenEmail address={`falecom@${domains[branding]}`} /></small>
			</MDBContainer>
		</MDBFooter>
	);
}

export default styled(Footer)`
	&, & * {
		color: var(--Footer_color) !important;
	}

	background-color: var(--Footer_bg_color);

	a {
		text-decoration: underline;
	}
`;