import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { MDBContainer } from 'mdbreact';
import styled, { createGlobalStyle } from 'styled-components'

import { pageView } from '../../services/GA'
import { FormDeleteAccount, Stepper } from 'components';
import { MDB as MDBLayout } from 'components/Stepper/layouts';
import { BodySetter, FooterSetter, TitleSetter } from 'components/Stepper/layouts/MDB';
import { Step } from 'components/Stepper';
import { Button } from '@blueprintjs/core';
import { Center } from 'components/Stepper/layouts/default';

const GlobalStyle = createGlobalStyle`
	body {
		--primaryBg: var(--Branding-Color);
	}
`

const Logo = styled.img`
    margin: 0 auto 30px;
    display: block;
`

export function Desativar() {
	useEffect(pageView, [])

	const history = useHistory()
	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const token = urlParams.get('token')

		if (token) {
			sessionStorage.setItem('auth-token', token)
			history.replace('/conta/desativar')
		} else if (!sessionStorage.getItem('auth-token')) {
			// history.push('/')
		}
	}, [history])

	return <MDBContainer className="my-4">
		<GlobalStyle />
		<Logo src="/logo_on_branding.png" alt={process.env.REACT_APP_BRANDING_NAME} />
		<Stepper layout={MDBLayout} onFinish={() => {}}>
			<Step>
				<TitleSetter>Desativar conta</TitleSetter>
				<BodySetter>
					<p>Você está prestes a desativar a sua conta no {process.env.REACT_APP_BRANDING_NAME}. Tenha absoluta certeza antes de realizar esta ação.</p>
				</BodySetter>
				<FooterSetter><Center>
					<FormDeleteAccount
						onFinish={() => history.push('/')}
						now
					>
						{({ loading }) => <Button
							intent="danger"
							type="submit"
							{...{ loading }}
						>DESATIVAR CONTA</Button>}
					</FormDeleteAccount>
				</Center></FooterSetter>
			</Step>
		</Stepper>
	</MDBContainer>
}
