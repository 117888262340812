import { useState, useCallback, useRef, useMemo } from 'react'
import { useStep } from './utils'

/**
 * @typedef CoreProps
 * @property {import('react').ReactChildren} children
 */

/** @param {CoreProps} props */
export const useCoreLogic = ({ children }) => {
	const [cursor, setCursor] = useState(0)

	const step = useStep(children, cursor)

	const _stepRef = useRef()

	const next = useCallback(async e =>
		(await _stepRef.current?.doNext?.(e) ?? 1) && setCursor(cursor + 1), [cursor])

	const back = useCallback(async e =>
		(await _stepRef.current?.doBack?.(e) ?? 1) && setCursor(cursor - 1), [cursor])

	const StepperContextValue = useMemo(() => ({
		cursor, setCursor, next, back, _stepRef
	}), [cursor, setCursor, next, back])

	return { StepperContextValue, step }
}
