export const InstallTWA = () => (
	<div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px", marginTop: "5px"}}>
		<a href="https://apps.apple.com/app/1631937502" target="_blank" rel="noreferrer">
			{/* https://developer.apple.com/app-store/marketing/guidelines/ and https://tools.applemediaservices.com/app-store/ */}
			<img
				style={{ width: "120px" }}
				src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/pt-br.svg"
				alt="Baixar na App Store" />
		</a>
		<a href='https://play.google.com/store/apps/details?id=br.com.consultapublica.app.twa' target="_blank" rel="noreferrer">
			{/*  https://play.google.com/intl/pt-BR/badges/ */}
			<img
				style={{ width: "135px" }}
				alt='Disponível no Google Play'
				src='https://play.google.com/intl/pt-BR/badges/static/images/badges/pt-br_badge_web_generic.png' />
		</a>
	</div>
)
