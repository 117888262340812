/* eslint-disable no-unused-expressions */
import { useEffect } from 'react'

export const useLogic = ({ formId }) => {
	useEffect(() => {
		setTimeout(() => {
			document.getElementById(formId)
				?.querySelector('input:not(:read-only), select')
				?.focus()
		}, 0)
	}, [formId])
}
