import React from 'react'

import { Step } from "../../../Stepper"
import { TitleSetter, BodySetter, FooterSetter, NextButton } from "../../../Stepper/layouts/MDB"
import { Right } from "../../../Stepper/layouts/default"
import { useStepper } from "../../../Stepper/utils"
import { NonIdealState } from '../../..'

export const CheckProfileWarningStep = () => {
	const { next } = useStepper()

	return <Step>
		<TitleSetter>Verifique seu cadastro</TitleSetter>
		<BodySetter>
			<NonIdealState
				icon="fill-forms"
				title="Seu perfil é importante"
				description="Percebemos que já faz um tempo que você não atualiza seus dados cadastrados. Eles são parte integrante da sua contribuição, e portanto gostaríamos que você verifique que estão todos atualizados"
			/>
		</BodySetter>
		<FooterSetter>
			<Right>
				<NextButton
					onClick={next}
				>Vamos lá!</NextButton>
			</Right>
		</FooterSetter>
	</Step>
}