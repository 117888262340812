import React, { memo, useContext, useState, useEffect } from 'react'

import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardHeader } from 'mdbreact'
import styled from 'styled-components'
import moment from 'moment'
import { PageContext } from '../pages'
import { AnchorButton, Button, ButtonGroup, Menu, MenuItem } from '@blueprintjs/core'
import { Popover2 } from '@blueprintjs/popover2'
import { capitalizeFirstLetter } from 'utils'

const Header = styled(MDBCardHeader)`
	background-color: var(--HeaderDetailsFinalizada_bg_color) !important;
	color: var(--HeaderDetailsFinalizada_color);
`
const Title = styled(MDBCardTitle)`
	text-align: center;
	margin: 0 !important;
	font-weight: 500;
`
const Container = styled(MDBCard)`
	margin-bottom: 10px;
`

const List = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;

	li > span {
		font-weight: bold;
	}
`

const Files = styled.div`
	display: flex;
	gap: 10px;
	margin-top: 10px;
`

function CardConsultaFinalizadaRelatorios() {
	const { consulta } = useContext(PageContext)
	const [loading, setLoading] = useState(true)
	const [estáFinalizada, setEstáFinalizada] = useState(true)
	const [arquivos, setArquivos] = useState(null)
	const [datas, setDatas] = useState({})

	useEffect(() => {
		if (!consulta || !consulta.datatermino) return

		const dataTermino = moment
			.utc(consulta.datatermino.replace("[UTC]", ""))
			.utcOffset(consulta.fusoHorario)
			.local()

		setEstáFinalizada(moment().isAfter(dataTermino))

		const { urlRelatorio, urlRelatorioCsv, urlRelatorioPersonalizado } = consulta
		if (urlRelatorio || urlRelatorioCsv || urlRelatorioPersonalizado) {
			let arquivos = {}

			if (urlRelatorio) arquivos.relatorio = urlRelatorio
			if (urlRelatorioCsv) arquivos.relatorioCsv = urlRelatorioCsv
			if (urlRelatorioPersonalizado) arquivos.personalizado = urlRelatorioPersonalizado

			setArquivos(arquivos)
		}


		const inicio = moment
			.utc(consulta.datainicio.replace("[UTC]", ""))
			.utcOffset(consulta.fusoHorario)
			.local()

		const fim = moment
			.utc(consulta.datatermino.replace("[UTC]", ""))
			.utcOffset(consulta.fusoHorario)
			.local()
		const duração = inicio.from(fim, true)
		setDatas({ inicio, fim, duração })

		setLoading(false)
	}, [consulta])

	const texto = arquivos
		? `Esta ${process.env.REACT_APP_BRANDING_OBJECT} já encerrou! Veja aqui as informações finais:`
		: `Esta ${process.env.REACT_APP_BRANDING_OBJECT} já encerrou! Em poucos dias a análise das contribuições será concluída e então publicaremos os relatórios aqui.`

	return !loading && estáFinalizada && <Container>
		<Header>
			<Title>{capitalizeFirstLetter(process.env.REACT_APP_BRANDING_OBJECT_SHORT)} finalizada</Title>
		</Header>
		<MDBCardBody>
			<p>{texto}</p>
			<List>
				<li><span>Teve início em: </span>{datas?.inicio.format("dddd, D [de] MMMM [de] YYYY [às] HH:mm:ss")}</li>
				<li><span>Encerrou em: </span>{datas?.fim.format("dddd, D [de] MMMM [de] YYYY [às] HH:mm:ss")}</li>
				<li><span>Duração: </span>{datas?.duração}</li>
			</List>
			{arquivos && <Files>
				{arquivos.relatorio && <ButtonGroup>
					<AnchorButton
						outlined
						href={arquivos.relatorio}
						target="_blank"
						rel="noopener noreferrer"
						text={arquivos.relatorioCsv ? "Relatório" : "Relatório PDF"}
						icon="document" />
					{arquivos.relatorioCsv && <Popover2 placement="bottom" content={<Menu>
						<MenuItem
							text="Formato PDF"
							href={arquivos.relatorio}
							target="_blank"
							rel="noopener noreferrer"
							icon="document"
						/>
						<MenuItem
							text="Formato CSV"
							href={arquivos.relatorioCsv}
							target="_blank"
							rel="noopener noreferrer"
							icon="panel-table"
						/>
					</Menu>}>
						<Button outlined rightIcon="caret-down" />
					</Popover2>}
				</ButtonGroup>}
				{arquivos.personalizado && <AnchorButton
					outlined
					href={arquivos.personalizado}
					target="_blank"
					rel="noopener noreferrer"
					text="Abrir publicação"
					icon="book"
				/>}
			</Files>}
		</MDBCardBody>
	</Container>
}

export default memo(CardConsultaFinalizadaRelatorios)
