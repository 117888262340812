import {
	useRef,
	useCallback
} from "react";

export default function useRefCallback(attach = null, detach = null) {
	const ref = useRef(null)
	const setRef = useCallback(node => {
		if (ref.current) {
			// Make sure to cleanup any events/references added to the last instance
			attach && attach()
		}

		if (node) {
			// Check if a node is actually passed. Otherwise node would be null.
			// You can now do what you need to, addEventListeners, measure, etc.
			detach && detach()
		}

		// Save a reference to the node
		ref.current = node
	}, [attach, detach])

	return [ref, setRef]
}