import styled from "styled-components";

export const UsuarioPageSection = styled.section`
	flex: 1;
	display: flex;
	gap: 20px;

	@media (min-width: 512px) {
		flex-direction: row;
	}
	@media (max-width: 512px) {
		flex-direction: column;
	}
`
