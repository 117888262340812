import styled from "styled-components";

export const Container = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: 16px;

	hr { width: 100%; }
`
