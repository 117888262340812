import styled from "styled-components";

export const Actions = styled.div`
	display: flex;
	justify-content: space-between;

	.bp3-button svg {
		margin-right: 5px;
	}
`
