import React, { useEffect } from 'react';
import { MDBContainer } from 'mdbreact';

import { CardConsultaAtiva, Listagem, NonIdealState } from '../components';
import { consultasAtivas } from '../services';
import { pageView } from '../services/GA'
import { HomeButton } from './Home';
import { useRouteMatch } from 'react-router';

export default function ConsultasAtivas({ history, userToken }) {
	const { params } = useRouteMatch();

	useEffect(pageView, [])
	return (
		<MDBContainer className="my-4">
			<Listagem
				{...{ history }}
				query={consultasAtivas}
				Card={props =>
					<div className="p-2">
						<CardConsultaAtiva {...{ ...props, userToken }} />
					</div>}
				Empty={() => <NonIdealState
					icon="empty"
					title={`Não há ${process.env.REACT_APP_BRANDING_OBJECT_SHORT} ativas no momento`}
					action={<HomeButton
						url={(window.isSelfOrigin ? `/${params.idOrgao}` : '') +'/finalizadas'}
						icon="clipboard-list"
						text="Ver finalizadas"
					/>}
				/>}
			/>
		</MDBContainer>
	)
}

