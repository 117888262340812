export function saveCacheOrgaoInfo(orgaoInfo) {
	const data = JSON.stringify(orgaoInfo)
	if (data !== "{}") {
		window.sessionStorage.setItem("orgaoInfo", data)
		// orgaoInfo?.id && window.localStorage.setItem("orgaoId", orgaoInfo?.id)
	}
}

export function restoreCacheOrgaoInfo() {
	const data = window.sessionStorage.getItem("orgaoInfo")
	return data ? JSON.parse(data) : undefined
}

export function clearCacheOrgaoInfo() {
	window.sessionStorage.removeItem("orgaoInfo")
}

export function restoreOrgaoId() {
	const id = localStorage.getItem("orgaoId")
	return id && Number(id)
}

export function saveOrgaoId(id) {
	localStorage.setItem("orgaoId", id)
}

export function clearOrgaoId() {
	localStorage.removeItem("orgaoId")
}