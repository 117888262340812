import React from 'react'

import { Step } from "../../../Stepper"
import { TitleSetter, BodySetter, FooterSetter, NextButton } from "../../../Stepper/layouts/MDB"
import { Right } from "../../../Stepper/layouts/default"
import { MDBIcon, MDBInput } from "mdbreact"
import { BottomLink, Container, Form } from './styled'
import { MDBSelectEstadoCidade } from '../MDBSelectEstadoCidade'
import { InputSelect } from '../InputSelect'
import { InputMap } from '../InputMap'
import { mask } from 'utils/mask'
import { InputPoliticaPrivacidade } from 'components/InputPoliticaPrivacidade'
import { InputPassword, ServiceAlert } from 'components'

export const Presenter = ({
	title, fields, data, buttonTxt,
	handleNext, formRef, formId, handleSubmit, bottomLinks, handleBtnClick,
	hasError, loading
}) => <Step onNext={handleNext}>
	<TitleSetter>{title}</TitleSetter>
	<BodySetter>
		<Container>
			<Form ref={formRef} id={formId} onSubmit={handleSubmit}>
				{hasError && <ServiceAlert message={hasError} autoClose />}
				{fields.map(f => {
					const C = {
						select: InputSelect,
						EstadoCidade: MDBSelectEstadoCidade,
						Map: InputMap,
						politicaPrivacidade: InputPoliticaPrivacidade,
						password: InputPassword,
					}[f.type] || MDBInput

					const label = (typeof f.label === 'string')
						? (f.label + (f.required ? "" : " (opcional)"))
						: f.label

					const valueDefault = data && (typeof f.name.map === 'function' ?
						f.name.map(name => data[name]) :
						data[f.name])

					if (f.mask) {
						f.inputRef = mask(f.mask)
						delete f.mask
					}

					if (f.type === 'politicaPrivacidade') {
						f.orgaoData = JSON.parse(sessionStorage.getItem("orgaoInfo") || {})
					}

					return !f.disabled && <C
						outline
						{...f}
						label={label}
						key={formId + f.name}
						valueDefault={valueDefault}
					/>
				})}
			</Form>
				{bottomLinks && bottomLinks.map((link, i) =>
					<BottomLink key={i}>{link}</BottomLink>)}
		</Container>
	</BodySetter>
	<FooterSetter>
		<Right>
			<NextButton
				type="submit"
				form={formId}
				onClick={handleBtnClick}
			>{loading ? <MDBIcon icon="sync" spin className="mr-1" /> : null}{buttonTxt}</NextButton>
		</Right>
	</FooterSetter>
</Step>
