import styled from 'styled-components'

export const Container = styled.ul`
	margin: 0;
	padding: 0;
	list-style: none;
	background-image: linear-gradient(90deg, transparent 40px, var(--Branding-Grey-5), transparent 41px);
	display: flex;
	flex-direction: column;
	gap: 16px;
`
