import { recuperarRespostas, removerRespostasLocalmente } from "components/FormContribuição"
import moment from "moment"
import { useRef } from "react"
import { useEffect, useState } from "react"
import { detalhesConsulta, removerContribuição } from "services/Consultas_API"
import { useHistory } from "react-router-dom";
import { useQuery } from "utils"
import retryForm from "utils/retryForm"
import { MESSAGES } from "services/enums/Errors"

export const useNewFormContribuicaoLogic = () => {
	const [loading, setLoading] = useState(true)
	const [message, setMessage] = useState(false)
	const [data, setData] = useState()
	const formRef = useRef()
	const submitRef = useRef({})
	const query = useQuery()
	const [disabled, setDisabled] = useState(false)
	const history = useHistory()

	useEffect(() => {
		const omid = query.get("omid")
		const id = query.get("id")

		if (!omid || !id) {
			setLoading(false)
			return
		}

		detalhesConsulta(id)
			.then(async detalhes => {
				const dataTermino = moment
					.utc(detalhes.datatermino.replace("[UTC]", ""))
					.utcOffset(detalhes.fusoHorario)
					.local()

				const estaFinalizada = moment().isAfter(dataTermino)

				if (estaFinalizada) setDisabled(true)

				const { questoes, contribuicao_livre } = await recuperarRespostas(id, detalhes.questoes)
				const formContribuicao = {
					id,
					questoes,
					obrigatorio: detalhes.obrigatorio,
					label_contribuicao: detalhes.label_contribuicao,
					max_chars: detalhes.max_chars,
					min_chars: detalhes.min_chars,
					contrLivre: contribuicao_livre,
					segmentos: detalhes.segmentos,
				}
				setData({ detalhes, formContribuicao, estaFinalizada })
				setLoading(false)
			})
			.catch(setMessage)
	}, [query])

	async function handleSave(evt) {
		submitRef.current.target = evt.target

		const id = query.get("id")
		const { questoes, contribuicao_livre } = await recuperarRespostas(id, data.formContribuicao.questoes)

		setData(prevState => ({
			...prevState,
			formContribuicao: {
				...prevState.formContribuicao,
				questoes,
				contrLivre: contribuicao_livre,
			}
		}))

		setLoading(true)
		setDisabled(true)

		new Promise((res, rej) => {
			submitRef.current.res = () => {
				setMessage(MESSAGES["contribution/updated"])
				res()
			}
			submitRef.current.rej = message => {
				setMessage(retryForm(message, { target: formRef.current }, () => setMessage()))
				rej()
			}
		})
			.catch(console.error)
			.finally(() => {
				setLoading()
				setDisabled()
			})
	}

	function handleDelete(evt) {
		const message = "Você tem certeza que deseja apagar sua contribuição?"

		if (!window.confirm(message)) return

		const token = sessionStorage.getItem('auth-token')
		const id = query.get("id")

		removerContribuição(token, id)
			.then(() => {
				removerRespostasLocalmente(id)
				window.alert("Suas respostas foram excluídas.")
				history.push("/consultas")
			})
			.catch(message => setMessage(retryForm(message, evt, () => setMessage(), true)))
	}

	return {
		loading, setLoading,
		message,
		data,
		formRef,
		submitRef,
		handleSave,
		handleDelete,
		disabled,
	}
}
