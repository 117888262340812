import { UsuarioPageNav } from "./Nav";

export const PoliticasNav = () => <UsuarioPageNav
	title="Políticas do site"
	links={[/*{
		key: "termos",
		href: "/politicas/termos",
		text: "Termos de serviço",
	}, */{
		key: "politicas",
		href: "/politicas/politica-de-privacidade",
		text: "Política de privacidade",
	}/*, {
		key: "praticas",
		href: "/politicas/praticas-de-privacidade",
		text: "Práticas de privacidade",
	}*/]}
/>
