import { useEffect, useState } from "react"
import { useRouteMatch } from "react-router"

export const useUsuarioPageHeaderLogic = () => {

	// Check if needs to show logout button
	const { path } = useRouteMatch()
	const [showLogout, setShowLogout] = useState(false)
	useEffect(() => {
		const onlyWithAuth = ["/consultas", "/conta"]
		setShowLogout(onlyWithAuth.includes(path))
	}, [path])

	return { showLogout }
}
