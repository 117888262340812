import styled from "styled-components";

export const Header = styled.header`
	display: flex;
	background-color: var(--Branding-Color);
	/* width: 100vw; */
	/* height: 80px; */
	padding: 20px 0;
	box-shadow: 0 5px 11px 0 rgba(50, 50, 50, 0.08);

	img {
		height: 32px;
	}

	@media (max-width: 500px) {
		/* height: 60px; */

		img {
			height: 48px;
		}
	}
`
