import compose from 'react-hooks-compose'
import PropTypes from 'prop-types';

import { useStepLogic } from './step'
import { Presenter } from './presenter'

/**
 * @typedef StepProps
 * @property {Promise<Boolean>} onBack WARNING: return falsy values may have
 * unexpected behaviors. Ex: `undefined` and `null` will allow the step, but
 * empty strings still preventing the step.
 * @property {Promise<Boolean>} onNext WARNING: return falsy values may have
 * unexpected behaviors. Ex: `undefined` and `null` will allow the step, but
 * empty strings still preventing the step.
 */

/** @type {import('react').FunctionComponent<StepProps>} */
export const Step = compose(props => ({
	useLogic: () => useStepLogic(props),
}))(Presenter)

Step.propTypes = {
	onBack: PropTypes.func,
	onNext: PropTypes.func,
}
