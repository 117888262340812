import React, { useState, useEffect } from 'react'
import { useLayout } from './utils'

/**
 * @typedef LayoutProps
 * @property {React.ReactChildren} props.children
 * @property {Number} props.cursor
 */

/** @param {LayoutProps} props */
export const useLayoutLogic = ({ children, cursor }) => {
	const mySize = React.Children.count(children)
	const sizesState = useState([[mySize, cursor]])
	const sizesStateSetter = sizesState[1]
	const dataState = useState({})
	const parentCtx = useLayout()

	useEffect(() => {
		sizesStateSetter(() => [[mySize, cursor]])
	}, [sizesStateSetter, mySize, cursor])

	const disableGoBackState = useState(false)
	const handleBackState = useState()

	const LayoutContextValue = {
		sizesState, dataState, parentCtx, disableGoBackState, handleBackState,
	}

	return { LayoutContextValue }
}
