import { useState, useEffect, useCallback } from "react";
import isIosSafari from "./isIosSafari";
import isInStandaloneMode from "./isInStandaloneMode";

export default function useA2HS(onAppInstalled) {
	const [deferredPrompt, setDeferredPrompt] = useState(),
		[canInstall, setCanInstall] = useState()

	const handleBeforeInstallPrompt = useCallback(e => {
		e.preventDefault();
		setDeferredPrompt(e)
		setCanInstall(true)
	}, [])

	const handleAppInstalled = useCallback(e => {
		onAppInstalled && onAppInstalled(e)
		console.log('The A2HS was successfully installed.', e)
	}, [onAppInstalled])

	useEffect(() => {
		if (isIosSafari() && !isInStandaloneMode()) {
			setCanInstall(true)
		}
	}, [])

	useEffect(() => {
		window.addEventListener('beforeinstallprompt',
			handleBeforeInstallPrompt);
		window.addEventListener('appinstalled',
			handleAppInstalled);
			
		return () => {
			window.removeEventListener('beforeinstallprompt',
				handleBeforeInstallPrompt)
			window.removeEventListener('appinstalled',
				handleAppInstalled);
		}

	}, [handleBeforeInstallPrompt, handleAppInstalled])

	const install = useCallback(async () => {
		if (!deferredPrompt) return

		deferredPrompt.prompt()
		const choiceResult = await deferredPrompt.userChoice
			
		switch (choiceResult.outcome) {
			case 'accepted':
				console.log('User accepted the A2HS prompt.');
				break;

			case 'dismissed':
				console.warn('User dismissed the A2HS prompt.');
				break;

			default:
				console.error(
					'Unknown user responde for A2HS prompt.',
					choiceResult
				)
				break;
		}

		setDeferredPrompt()
		setCanInstall()

		return choiceResult
	}, [deferredPrompt])

	return [install, canInstall]
}