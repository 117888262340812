import { Button, H1, H2 } from "@blueprintjs/core";
import { InputMap } from "components/CardAuthentication/elements/InputMap";
import { InputSelect } from "components/CardAuthentication/elements/InputSelect";
import { FormPerfil, GridGroup, OpenEmail } from "components";
import { UsuarioMainPage } from "components/UsuarioPageLayout";
import { MDBInput as Input } from "mdbreact";
import { mask } from "utils/mask";
import { MDBSelectEstadoCidade } from "components/CardAuthentication/elements/MDBSelectEstadoCidade";

export const Perfil = () => <UsuarioMainPage>
	<H1>Perfil</H1>
	{/* <Callout intent="warning" title="Cuidamos do seus dados" className="mb-3">
		<p>Nós <b>não compartilhamos</b> esses dados com as entidades que realizam as pesquisas. Coletamos estes dados exclusivamente para fins estatísticos de cada {process.env.REACT_APP_BRANDING_OBJECT}. Cada entidade pode exigir que você preencha um conjunto de dados diferentes. Eles fazem parte de suas contribuições, portanto a cada contribuição enviada solicitaremos que você revise estas informações.</p>

		<p>Leia mais sobre como tratamos seus dados na nossa Política de privacidade e nas nossas Práticas de privacidade.</p>
	</Callout> */}
	<FormPerfil>
		{({ data, loading }) => <>
			<section>
				<H2>Quem é você</H2>
				{/* <a href="#!">Por que não posso alterar esses dados?</a> */}
				<GridGroup>
					<Input
						containerClass="s-9"
						outline
						name="nome"
						label="Nome Completo"
						icon="user"
						valueDefault={data['nome']}
						readOnly
					/>
					<Input
						containerClass="s-3"
						outline
						type="number"
						name="id"
						label="ID"
						icon="id-badge"
						valueDefault={data['id']}
						readOnly
					/>
					<Input
						containerClass="s-4"
						outline
						name="cpfcnpj"
						label="CPF"
						icon="address-card"
						inputMode='numeric'
						valueDefault={data['cpfcnpj']}
						inputRef={mask("9{3}.9{3}.9{3}-9{2}")}
						// readOnly
					/>
					<Input
						containerClass="s-4"
						outline
						name="rg"
						label="RG"
						icon="address-card"
						inputMode='numeric'
						valueDefault={data['rg']}
						// readOnly
					/>
				</GridGroup>
			</section>
			<section>
				<H2>Contato</H2>
				{/* <Link to="/conta/seguranca">Altere seu e-mail na seção "Segurança"</Link> */}
				<span>Para alterar seu e-mail, entre em contato com <OpenEmail address="falecom@consultapublica.com.br" />.</span>
				<GridGroup>
					<Input
						containerClass="s-4"
						outline
						name="email"
						label="E-mail"
						icon="at"
						valueDefault={data['email']}
						readOnly
					/>
					<Input
						containerClass="s-4"
						outline
						name="telefone"
						label="Celular"
						icon="mobile-alt"
						valueDefault={data['telefone']}
						inputRef={mask("(9{2}) [9]9999-9999")}
					/>
					<Input
						containerClass="s-4"
						outline
						name="telefone2"
						label="Telefone"
						icon="phone-alt"
						valueDefault={data['telefone2']}
						inputRef={mask("(9{2}) 9999-9999")}
					/>
				</GridGroup>
			</section>
			<section>
				<H2>Onde você reside</H2>
				<GridGroup>
					<MDBSelectEstadoCidade
						containerClass="s-4"
						name={["estado", "cidade"]}
						label={["Estado", "Cidade"]}
						valueDefault={[data['estado'], data['cidade']]}
					/>
					<Input
						containerClass="s-5"
						outline
						name="endereco"
						label="Endereço"
						icon="map-signs"
						valueDefault={data['endereco']}
						autoComplete="street-address"
					/>
					<Input
						containerClass="s-3"
						outline
						name="cep"
						label="CEP"
						icon="road"
						valueDefault={data['cep']}
						inputRef={mask("9{5}-9{3}")}
						autoComplete="postal-code"
					/>
					<InputMap
						className="s-12"
						outline
						name="latitudeLongitude"
						label="Localização Geográfica"
						icon="map-marked"
						valueDefault={data['latitudeLongitude']}
					/>
				</GridGroup>
			</section>
			<section>
				<H2>Mapeamento</H2>

				<GridGroup>
					<InputSelect
						containerClass="s-3"
						outline
						name="sexo"
						label="Sexo"
						icon="venus-mars"
						options={["Masculino", "Feminino"].map(x => ({ text: x, value: x }))}
						valueDefault={data['sexo']}
					/>
					<InputSelect
						containerClass="s-6"
						outline
						name="escolaridade"
						label="Escolaridade"
						icon="chalkboard-teacher"
						options={["Sem instrução", "Ensino fundamental ou 1º grau incompleto", "Ensino fundamental ou 1º grau completo", "Ensino médio ou 2º grau incompleto", "Ensino médio ou 2° grau completo", "Ensino superior incompleto", "Ensino superior completo", "Ensino superior completo com pós-graduação"].map(x => ({ text: x, value: x }))}
						valueDefault={data['escolaridade']}
					/>
					<InputSelect
						containerClass="s-3"
						outline
						name="faixaEtaria"
						label="Faixa Etária"
						icon="birthday-cake"
						options={["Até 17 anos", "De 18 até 24 anos", "De 25 até 39 anos", "40 anos ou mais"].map(x => ({ text: x, value: x }))}
						valueDefault={data['faixaEtaria']}
					/>
					<InputSelect
						containerClass="s-5"
						outline
						name="faixaSalarial"
						label="Renda Familiar"
						icon="comments-dollar"
						options={["Acima de 20 salários mínimos", "Entre 10 e 20 salários mínimos", "Entre 4 e 10 salários mínimos", "Entre 2 e 4 salários mínimos", "Até 2 salários mínimos"].map(x => ({ text: x, value: x }))}
						valueDefault={data['faixaSalarial']}
					/>
					<InputSelect
						containerClass="s-3"
						outline
						name="tratamento"
						label="Tratamento"
						icon="comments"
						options={["Senhor", "Senhora"].map(x => ({ text: x, value: x }))}
						valueDefault={data['tratamento']}
					/>
				</GridGroup>
			</section>
			<Button
				style={{ display: "flex", margin: "15px auto 0 auto" }}
				type="submit"
				{...{ loading }}
			>SALVAR</Button>
		</>}
	</FormPerfil>
</UsuarioMainPage>
