import { useRef, useEffect } from 'react'

/**
 * Scroll to the top of an element
 * @param {HTMLElement} elem Element to scroll to
 */
const scrollToElem = elem => elem.scrollIntoView({ block: "center", behavior: "smooth" })

/**
 * @typedef AutoScrollRef
 * @type {React.MutableRefObject<HTMLElement>}
 */

/**
 * Scroll to the returned ref when the dependencies changes
 *
 * @param {React.DependencyList} deps
 */
function useAutoScroll(deps) {
	/** @type {AutoScrollRef} */
	const ref = useRef(null),
		isFirstRun = useRef(true)

	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false
			return
		}

		scrollToElem(ref.current)
	}, [deps])

	return ref
}

export { useAutoScroll }
