// import React from 'react'
import compose from 'react-hooks-compose'
// import PropTypes from 'prop-types';

import { sections } from './sections'
import { AuthStep } from '../../elements/step'
import { useLogic } from './useLogic'

export const useCompleteProfile = hookProps => sections
	.map(sec => ({
		...sec,
		fields: sec.fields.map(field => ({
			...field,
			required: hookProps.neededSections[field['data-alt-name']],
			disabled: hookProps.neededSections[field['data-alt-name']] === undefined,
		}))
	}))
	.filter(sec => sec.fields.find(f => !f.disabled))
	.map((sec, i, arr) => compose(compProps => ({
		useLogic: () => useLogic({
			...hookProps, ...compProps,
			_map: { sec, arr, i },
		}),
    }))(AuthStep))
    // .map(sec => () => <h1>Hi</h1>)
