import { AnchorButton } from "@blueprintjs/core";
import styled from "styled-components";

export const Button = styled(AnchorButton)`
	.bp3-button-text {
		display: flex;
		align-items: center;
		gap: 5px;
	}
`
