import React, { memo } from 'react'
import styled from 'styled-components'

import { useStepper, useLayout } from '../utils'

export const BackButton = props => {
	const { cursor, back } = useStepper(),
		layoutCtx = useLayout(),
		{ disableGoBackState, handleBackState } = layoutCtx,
		disableGoBack = disableGoBackState?.[0],
		handleBack = handleBackState?.[0],
		{ back: recurBack } = layoutCtx.dataState[0],
		Component = props.component || 'button'

	if (cursor !== 0 && !disableGoBack) {
		return <Component {...props} onClick={recurBack || handleBack || back}>
			{props.children}
		</Component>
	}
	return null
}

export const Header = memo(({ children }) => {
	return <div>
		<BackButton>Voltar</BackButton>
		<h1>{children}</h1>
	</div>
})

export const Body = ({ children }) => <>{children}</>

export const NextButton = memo((props) => {
	const { next } = useStepper(),
		layoutCtx = useLayout(),
		{ next: recurNext } = layoutCtx.dataState[0],
		Component = props.component || 'button'

	return <Component onClick={recurNext || next} {...props}>
		{props.children}
	</Component>
}),
	Left = styled.div`
		display: flex;
		align-items: center;
		/* margin-right: auto !important; */
		flex: 1;
	`,
	Right = styled.div`
		display: flex;
		align-items: center;
		/* margin-left: auto !important; */
		flex: 1;
		justify-content: flex-end;
	`,
	Center = styled.div`
		display: flex;
		align-items: center;
		/* margin-left: auto !important; */
		flex: 1;
		justify-content: center;
	`

export const Footer = ({ children, ...props }) => {
	return <div className="d-flex" {...props}>{children}</div>
}

export const StepperLayout = memo(() => {
	const layoutCtx = useLayout()
	const {
		title, body, footer, className, ref,
	} = layoutCtx.dataState[0]

	if (layoutCtx.parentCtx && body) {
		return body
	}

	return <div {...{ className, ref }}>
		<Header>{title}</Header>
		<Body>{body}</Body>
		<Footer>{footer}</Footer>
	</div>
})
