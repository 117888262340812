import { useCallback, useState } from "react"
import { disableAccount, disableAccountNow } from "services/Auth_API"
import { MESSAGES } from "services/enums/Errors"
import retryForm from "utils/retryForm"
import { ServiceAlert } from "./ServiceAlert"

export default function FormDeleteAccount({ now, onFinish, children: Children }) {
	const [message, setMessage] = useState()
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState()

	const handleSubmit = useCallback(e => {
		e.preventDefault()

		setLoading(true)

		const handler = now ? disableAccountNow : disableAccount

		/** @type {keyof MESSAGES} */
		const successMessage = "user/account_disabled"
		// const successMessage = now ? "user/account_disabled" : "user/disable_request_sent"
		// TODO: fix this, the message should be returned by the service

		const token = sessionStorage.getItem('auth-token')
		handler(token)
			.then(() => {
				setSuccess(true)
				sessionStorage.removeItem('auth-token')
				setMessage(MESSAGES[successMessage])
			})
			.catch(err => setMessage(retryForm(err, e, () => setMessage())))
			.finally(() => setLoading(false))
	}, [now])

	return <form onSubmit={handleSubmit}>
		<ServiceAlert
			{...{ message }}
			onClosed={() => {
				if (success && typeof onFinish === 'function')
					onFinish()
				else
					setMessage()
			}}
			autoClose
		/>
		<Children {...{ loading }} />
	</form>
}
