import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { getAllContribuicoes } from "services/Consultas_API"

export const useListaContribuicoesLogic = () => {
	const [lista, setLista] = useState()
	const [hasError, setError] = useState(false)

	const getAll = useCallback(() => {
		const token = sessionStorage.getItem('auth-token')
		setError(false)
		getAllContribuicoes(token)
			.then(contribuicoes => {
				const newList = []
				const dates = []

				for (const contribuicao of contribuicoes) {
					const dataContribuicao = moment
						.utc(contribuicao.contribuicao.datacontribuicao.replace("[UTC]", ""))
						.utcOffset(contribuicao.consulta.fusoHorario)
						.local()
					const dataString = dataContribuicao.format('DD/MM/YYYY')

					if (!dates.includes(dataString)) {
						dates.push(dataString)
						newList.push({ data: dataString })
					}

					const dataTermino = moment
						.utc(contribuicao.consulta.datatermino.replace("[UTC]", ""))
						.utcOffset(contribuicao.consulta.fusoHorario)
						.local()

					const estaFinalizada = moment().isAfter(dataTermino)

					newList.push({
						...contribuicao,
						estaFinalizada,
						hora: dataContribuicao.format('HH[h]mm[m]')
					})
				}
				

				setLista(newList)
			})
			.catch(err => setError({ ...err, onClick: getAll }))
	}, [])

	useEffect(getAll, [getAll])

	return {
		lista,
		hasError,
	}
}
