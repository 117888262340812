import { createContext } from 'react'

/**
 * @typedef StepperContext
 * @property {Number} cursor
 * @property {React.Dispatch<React.SetStateAction<Number>>} setCursor
 * @property {() => void} next
 * @property {() => void} back
 */

/** @type {StepperContext} */
const ContextDefault = null
export const StepperContext = createContext(ContextDefault)
