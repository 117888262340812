import React, { useCallback, useContext, useEffect, useState } from 'react'

import {
	TitleSetter, BodySetter, FooterSetter, NextButton,
} from './Stepper/layouts/MDB'
import { Left, Right } from './Stepper/layouts/default'
import { restoreCacheOrgaoInfo, useSharePage } from '../utils'
import { PageContext } from '../pages'
import NonIdealState from './NonIdealState'
import styled from 'styled-components'
import { Step } from './Stepper'
import { MDBIcon } from 'mdbreact'
import { SHARE_OPEN_AFTER_TEXT } from 'utils/useSharePage'
import { event } from 'services/GA'

const TableContainer = styled.div`
	width: 100%;
	overflow: auto;
	background:
		linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)),
		linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 100% 50%,
		radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0)),
		radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0)) 100% 50%;
	background-repeat: no-repeat;
	background-color: white;
	background-size: 40px 100%, 40px 100%, 14px 100%, 14px 100%;
	background-attachment: local, local, scroll, scroll;

	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
	border: 2px solid var(--TableContriSendedHeader_border_color);
`

const Table = styled.table`
    width: 100%;
	
	thead {
		background-color: var(--TableContriSendedHeader_bg_color);
		color: var(--TableContriSendedHeader_color);

		th {
			font-weight: 900;
		}
	}

	th, td {
		padding: 6px 12px;
	}
`

function CardConsultaDetalhesStep5() {
	const [message, setMessage] = useState(""),
		{ pageData: { action, id, dataCriacao }, consulta } = useContext(PageContext)
	// Action can be: 'enviada', 'alterada' and 'deletada'

	const [orgaoName, setOrgaoName] = useState(""),
		consultaName = consulta?.nome,
		[contribuinteName, setContribuinteName] = useState("")

	useEffect(() => {
		const info = restoreCacheOrgaoInfo()
		setMessage(info.textoFim)
		setOrgaoName(info.nome)

		const profile = JSON.parse(sessionStorage.getItem('auth-profile'))
		setContribuinteName(profile.nome)
	}, [])

	const icon = {
		enviada: 'completed_tasks',
		alterada: 'preferences',
		deletada: 'throw_away',
	}[action] || 'no-data'

	const details = ['enviada', 'alterada'].includes(action) && <details>
		<summary><u>Clique aqui</u> para ver os detalhes da sua contribuição.</summary>
		<TableContainer>
			<Table>
				<thead>
					<tr>
						<th>Ticket</th>
						<th>Data</th>
						<th>Órgão</th>
						<th style={{ minWidth: "250px" }}>Consulta</th>
						<th>Contribuinte</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{id}</td>
						<td>{dataCriacao}</td>
						<td>{orgaoName}</td>
						<td>{consultaName}</td>
						<td>{contribuinteName}</td>
					</tr>
				</tbody>
			</Table>
		</TableContainer>
	</details>

	const textDefault = SHARE_OPEN_AFTER_TEXT
	const [shareAPI, sharePageHandler] = useSharePage()
	const [shareLoading, setShareLoading] = useState(false)
	const sharePage = useCallback(() => {
		const title = consulta?.nome || ""
		const text = consulta?.compartilhamento?.msgContribuicao || textDefault
		setShareLoading(true)
		sharePageHandler(title, text)
			.finally(() => setShareLoading(false))

		event({
			category: "Consulta",
			action: "Compartilhamento após contribuição",
		})
	}, [sharePageHandler, consulta, textDefault])

	const conjucagao = {
		enviada: 'ticket é',
		alterada: 'novo ticket é',
		deletada: 'ticket era',
	}[action || "enviada"]

	return <Step disableGoBack>
		<TitleSetter></TitleSetter>
		<BodySetter>
			<NonIdealState
				{...{ icon }}
				title={`Contribuição ${action}, seu ${conjucagao} ${id}`}
				description={message}
			/>
			{details}
		</BodySetter>
		<FooterSetter stickyFooter>
			<Left>
				<NextButton outline>Voltar à {process.env.REACT_APP_BRANDING_OBJECT_SHORT}</NextButton>
			</Left>
			<Right>
				<NextButton
					onClick={sharePage}
					disabled={shareLoading}
				>Compartilhar {process.env.REACT_APP_BRANDING_OBJECT_SHORT} <MDBIcon
						icon={shareLoading ? "sync" : shareAPI ? "share" : "facebook-square"}
						fab={!shareAPI}
						spin={shareLoading}
					/></NextButton>
			</Right>
		</FooterSetter>
	</Step>
}

export default CardConsultaDetalhesStep5
