import { createContext } from 'react'

import ConsultaDetalhes from "./ConsultaDetalhes"
import ConsultasAtivas from "./ConsultasAtivas"
import ConsultasFinalizadas from "./ConsultasFinalizadas"
import Home from "./Home"

export const PageContext = createContext({})
export const PageContextLoadingValue = createContext({})

export { Auth } from './Auth'
export { Cadastro } from './Cadastro'
export { Consultas } from './Consultas'
export { Resposta as ConsultasResposta } from './Consultas/Resposta'
export { Conta } from './Conta'
export { Perfil as ContaPerfil } from './Conta/Perfil'
export { Seguranca as ContaSeguranca } from './Conta/Seguranca'
export { Notificacoes as ContaNotificacoes } from './Conta/Notificacoes'
export { Preferencias as ContaPreferencias } from './Conta/Preferencias'
export { Dados as ContaDados } from './Conta/Dados'
export { Entrar } from './Entrar'
export { ForgotPass } from './ForgotPass'
export { Politicas } from './Politicas'
export { Recuperar } from './Recuperar'
export { TermosServico as PoliticasTermosServico } from './Politicas/TermosServico'
export { PoliticaPrivacidade as PoliticasPoliticaPrivacidade } from './Politicas/PoliticaPrivacidade'
export { PraticasPrivacidade as PoliticasPraticasPrivacidade } from './Politicas/PraticasPrivacidade'

export {
	ConsultaDetalhes,
	ConsultasAtivas,
	ConsultasFinalizadas,
	Home,
}
