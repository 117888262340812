import styled from "styled-components"
import { NavLink } from "react-router-dom";
import { FaCommentDots, FaComments, FaFileAlt, FaPoll, FaShare, FaSync, FaUserClock, FaFacebookSquare } from "react-icons/fa";
import { useSharePage } from "utils";
import { useState } from "react";
import { event } from "services/GA";

const ContribuicaoItemContainer = styled.li`
	background-color: white;
	padding: 16px;
	border-radius: 4px;
	border: 1px solid var(--Branding-Grey-6);
	display: flex;
	flex-direction: column;
	gap: 10px;

	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;
		justify-content: center;
		row-gap: 15px;

		.brasao {
			--size: 50px;
			width: var(--size);
			height: var(--size);
			border-radius: 50%;
			object-fit: cover;
			border: 1px solid var(--Branding-Grey-6);
			margin-right: 10px;
		}

		.orgao {
			flex: 1;
			display: flex;
			flex-direction: column;
		}
		.info {
			display: flex;
			gap: 5px;

			span {
				display: flex;
				align-items: center;
				gap: 5px;
			}
		}
	}

	@media (min-width: 512px) {
		.consulta, .acoes {
			margin-left: 60px;
		}
	}

	@media (max-width: 850px) {
		.consulta, .acoes {
			flex-direction: column;
		}
	}

	.consulta {
		h4 {
			font-weight: 500;
		}

		p {
			text-align: justify;
			margin: 0;
		}
	}

	.acoes {
		display: flex;
		gap: 10px;

		a, button {
			display: flex;
			gap: 6px
		}
	}
`

export const ContribuicaoItem = ({ consulta, orgao, estaFinalizada, hora }) => {
	const [shareAPI, sharePageHandler] = useSharePage(`${window.location.origin}/${orgao.id}/detalhes/${consulta.id}`)
	const [shareLoading, setShareLoading] = useState(false)
	function handleShare(consulta) {
		const title = consulta?.nome || ""
		const text = consulta?.compartilhamento?.msgInicio || ""
		setShareLoading(true)
		sharePageHandler(title, text)
			.finally(() => setShareLoading(false))

		event({
			category: "Consulta",
			action: "Compartilhamento na lista de contribuições",
		})
	}

	return <ContribuicaoItemContainer>
		<div className="header">
			<img
				className="brasao"
				src={`${process.env.REACT_APP_ENV === "production" ? "https://consulta-arquivos-bucket" : "http://consulta-teste"}.s3-sa-east-1.amazonaws.com/arquivos/logomarcas/logomarca${orgao.id}.jpg`}
				alt="Imagem do órgão"
			/>
			<div className="orgao">
				<b>{orgao.nome}</b>
				<i>{orgao.cidade} {orgao.estado}</i>
			</div>
			<div className="info">
				<span title={`Status da ${process.env.REACT_APP_BRANDING_OBJECT_SHORT}`}>{estaFinalizada ?
					<><FaPoll size={20} />ENCERRADA</> :
					<><FaComments size={20} />EM ANDAMENTO</>
				}</span>
				·
				<span title="Horário da contribuição"><FaUserClock size={20} />{hora}</span>
			</div>
		</div>
		<div className="consulta">
			<h4>{consulta.nome}</h4>
			<p dangerouslySetInnerHTML={{ __html: consulta.ementa }}></p>
		</div>
		<div className="acoes">
			<NavLink
				to={`/consultas/resposta?omid=${orgao.id}&id=${consulta.id}`}
				className="bp3-button bp3-intent-primary"
			><FaCommentDots />MINHAS RESPOSTAS</NavLink>

			<NavLink
				to={`/${orgao.id}/detalhes/${consulta.id}`}
				className="bp3-button bp3-intent-primary"
			><FaFileAlt />VER DETALHES</NavLink>

			<button
				onClick={() => handleShare(consulta)}
				className="bp3-button bp3-intent-primary"
			>{shareLoading ? <FaSync className="fa-spin" /> : shareAPI ? <FaShare /> : <FaFacebookSquare />}COMPARTILHAR</button>
		</div>
	</ContribuicaoItemContainer>
}