export const fields = [{
	type: "text",
	name: "nome",
	label: "Nome",
	icon: "user",
	'data-alt-name': 'nome',
}, {
	type: "text",
	name: "cpfcnpj",
	label: "CPF",
	icon: "id-card",
	mask: "9{3}.9{3}.9{3}-9{2}",
	inputMode: 'numeric',
	'data-alt-name': 'CPF',
}, {
	type: "text",
	name: "rg",
	label: "RG",
	icon: "id-card",
	inputMode: 'numeric',
	'data-alt-name': 'RG',
}, {
	type: "text",
	name: "telefone",
	label: "Celular",
	required: true,
	icon: "mobile",
	mask: "(9{2}) [9]9999-9999",
	'data-alt-name': 'Celular',
}, {
	type: "text",
	name: "telefone2",
	label: "Telefone",
	required: true,
	icon: "phone",
	mask: "(9{2}) 9999-9999",
	'data-alt-name': 'Telefone',
}, {
	type: "EstadoCidade",
	name: ["estado", "cidade"],
	label: ["Estado", "Cidade"],
	required: true,
	autoComplete: ["address-level1", "address-level2"],
	'data-alt-name': 'Cidade_Estado',
}, {
	name: "endereco",
	label: "Endereço",
	icon: "route",
	autoComplete: "street-address",
	'data-alt-name': 'Endereço',
}, {
	name: "cep",
	label: "CEP",
	icon: "road",
	mask: "9{5}-9{3}",
	autoComplete: "postal-code",
	'data-alt-name': 'Endereço',
}, {
	type: "Map",
	name: "latitudeLongitude",
	label: "Localização Geográfica",
	icon: "map-marked",
	'data-alt-name': 'Localização Geográfica',
}, {
	type: "select",
	name: "sexo",
	id: "selectSexo",
	label: "Sexo",
	icon: "venus-mars",
	options: ["Masculino", "Feminino"].map(x => ({text: x, value: x})),
	'data-alt-name': 'Sexo',
}, {
	type: "select",
	name: "escolaridade",
	id: "selectEscolaridade",
	label: "Escolaridade",
	icon: "chalkboard-teacher",
	options: ["Sem instrução", "Ensino fundamental ou 1º grau incompleto", "Ensino fundamental ou 1º grau completo", "Ensino médio ou 2º grau incompleto", "Ensino médio ou 2° grau completo", "Ensino superior incompleto", "Ensino superior completo", "Ensino superior completo com pós-graduação"].map(x => ({text: x, value: x})),
	'data-alt-name': 'Escolaridade',
}, {
	type: "select",
	name: "faixaEtaria",
	id: "selectFaixaEtaria",
	label: "Faixa Etária",
	icon: "birthday-cake",
	options: ["Até 17 anos", "De 18 até 24 anos", "De 25 até 39 anos", "40 anos ou mais"].map(x => ({text: x, value: x})),
	'data-alt-name': 'Faixa Etária',
}, {
	type: "select",
	name: "faixaSalarial",
	id: "selectFaixaSalarial",
	label: "Renda Familiar",
	icon: "comments-dollar",
	options: ["Acima de 20 salários mínimos", "Entre 10 e 20 salários mínimos", "Entre 4 e 10 salários mínimos", "Entre 2 e 4 salários mínimos", "Até 2 salários mínimos"].map(x => ({text: x, value: x})),
	'data-alt-name': 'Renda Familiar',
}, {
	type: "select",
	name: "tratamento",
	id: "selectTratamento",
	label: "Tratamento",
	icon: "comments",
	options: ["Senhor", "Senhora"].map(x => ({text: x, value: x})),
	'data-alt-name': 'Tratamento',
}]
