import { useCallback, useState } from "react"
import { Dialog } from "@blueprintjs/core"
import ListaOrgaos from "./ListaOrgaos"

export const ModalPoliticaPrivacidade = ({ orgaoData, text }) => {
	const [orgaoSelected, setOrgaoSelected] = useState()

	const handleOpen = useCallback(e => {
		e.preventDefault()
		setOrgaoSelected(orgaoData)
	}, [orgaoData])

	const handleSelect = useCallback(orgao => {
		setOrgaoSelected(orgao)
	}, [])

	const origem = orgaoSelected?.sistema ? process.env.REACT_APP_BRANDING_NAME : `${orgaoSelected?.nome} - ${orgaoSelected?.cidade} ${orgaoSelected?.estado}`
	const title = <><b>{origem}</b></>

	return <>
		{orgaoData ? <span onClick={handleOpen} style={{ cursor: 'pointer', textDecoration: 'underline' }}>{text}</span> : null}
		{!orgaoData ? <ListaOrgaos onSelect={handleSelect} large intent="success" /> : null}

		<Dialog
			title={title}
			onClose={() => setOrgaoSelected()}
			isOpen={!!orgaoSelected}
			className="overflow-hidden pb-0"
		>
			<iframe
				title="Política de Privacidade"
				src={orgaoSelected?.linkPoliticaPrivacidade}
				frameBorder="0"
				style={{ height: "calc(var(--vh) * 80)" }}
			></iframe>
		</Dialog>
	</>
}