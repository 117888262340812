import React from 'react'
import { NonIdealState } from '@blueprintjs/core'

export default function ErrorScreen({
	icon = "error",
	title = "Oops!",
	desc: description,
	action,
	children
}) {
	return <NonIdealState
		{...{ icon, title, description, action }}
	>
		{children}
	</NonIdealState>
}