import React from 'react'

import { InputSelect } from '../InputSelect'

const defaultProps = {
	outline: true,
	icon: "city",
}

export const Presenter = ({
	name, label, required, autoComplete, valueDefault,
	states, cities, handleStateChange,
	...props
}) => <>
	<InputSelect
		{...defaultProps}
		{...props}
		name={name?.[0]}
		valueDefault={valueDefault?.[0]}
		autoComplete={autoComplete?.[0]}
		label={label?.[0] + (required ? "" : " (opcional)")}
		onChange={handleStateChange}
		options={states}
		required={required}
	/>
	<InputSelect
		{...defaultProps}
		{...props}
		name={name?.[1]}
		valueDefault={valueDefault?.[1]}
		autoComplete={autoComplete?.[1]}
		label={label?.[1] + (required ? "" : " (opcional)")}
		disabled={!cities.length}
		options={cities}
		required={required}
	/>
</>
