import React, { useState } from 'react'
import styled from 'styled-components'

const Figure = styled.figure`
	overflow: hidden;
	float: left;
`

const Image = styled.img`
	max-height: 150px;
	max-width: 100%;
	height: auto;
	width: auto;
	object-fit: cover;
`

export default function Brasao({ size, filled, title, className, src }) {
	const [broken, setBroken] = useState(false)

	return src && !broken ? (
		<Figure
			className={`figure m-0 ${!size && "p-2"} ${className} ${filled&&'white rounded'}`}
			title={title}
		>
			<Image
				src={src}
				style={{ height: size || "initial" }}
				alt="Imagem do órgão"
				onError={() => setBroken(true)}
			/>
		</Figure>
	) : null
}