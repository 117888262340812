import inputMask from "inputmask";

/**
 * Ref: https://www.npmjs.com/package/inputmask
 * @param {String} mask The mask
 * @param {inputMask.Options} opts Other options
 * @returns {(inputElem: HTMLElement) => void} Function to pass the DOM element and apply the mask
 */
export const mask = (mask, opts) => inputElem => {
	if ((mask || opts) && inputElem)
		inputMask({
			// autoUnmask: true, // A validação não estava funcionando com isso
			// clearMaskOnLostFocus: false, // Não funciona pois nossa label fica sobrepondo a máscara
			...(opts || {}),
			mask,
		}).mask(inputElem)
}
