import { defaultFetch, personalToken } from "../utils"
import { MESSAGES } from "./enums/Errors";
import { setUser } from "./GA";

const API_RO = process.env.REACT_APP_API_READ_ONLY_ROOT + 'contribuidor';
const API_RW = process.env.REACT_APP_API_READ_WRITE_ROOT + 'contribuidor';
const API_AUTH = process.env.REACT_APP_API_READ_WRITE_ROOT + 'auth';

// ============================== API DOC - START ==============================
/**
 * @apiDefine AuthSuccess
 * @apiSuccess {String} token Chave do usuário, utilizado para autorizá-lo em
 * futuras requisições.
 * @apiSuccess {Object} user        Conta do usuário
 * @apiSuccess {String} user.uid    Identificador único do usuário
 * @apiSuccess {String} [user.name] Nome do usuário
 * @apiSuccess {String} user.email  Email do usuário
 * @apiSuccess {Boolean} [mustCompleteRegistration] Sinaliza que o usuário deve completar seu cadastro em virtude de mudança das configurações do órgão
 */

/**
 * @api {post} /contribuidor/login Se autenticar como um usuário
 * @apiVersion 0.1.0
 * @apiName EntrarUsuario
 * @apiGroup Autenticacao
 * @apiPermission naoAutenticados
 *
 * @apiDescription Com este endpoint deve ser possível que um usuário se
 * autentique utilizando email e senha
 *
 * @apiParam {String{..120}} email
 * @apiParam {String{..20}} senha
 *
 * @apiUse AuthSuccess
 * 
 * @apiUse ServiceResponseError
 * 
 * @apiErrorExample {json} Erro usuário não encontrado:
 * {
 *  "code": "auth/user-not-found",
 *  "message": "Não há nenhum usuário registrado com este email."
 * }
 * @apiErrorExample {json} Erro email inválido:
 * {
 *  "code": "auth/invalid-email",
 *  "message": "O endereço de email não está bem formado."
 * }
 * @apiErrorExample {json} Erro senha errada:
 * {
 *  "code": "auth/wrong-password",
 *  "message": "A senha não confere com este usuário."
 * }
 *
 */
// =============================== API DOC - END ===============================

// ============================== JS DOC - START ===============================
/**
 * @typedef LoginData
 * @property {String} email
 * @property {String} senha
 */

/**
 * @function signIn
 * @param {LoginData} loginData
 * @param {Boolean} keep_logged
*/
// =============================== JS DOC - END ================================
export async function signIn(loginData, keep_logged) {
	const endPoint = `${API_RO}/login`
	
	const _pt = await personalToken()
	return defaultFetch(endPoint, {
		method: "POST",
		body: JSON.stringify({ ...loginData, _pt }),
	}).then(res => {
		setUser(res.contribuidor.id)
		sessionStorage.setItem('auth-token', res.token)
		sessionStorage.setItem('auth-profile', JSON.stringify(res.contribuidor))
		return res
	})
}

// ============================== API DOC - START ==============================

/**
 * @api {post} /contribuidor/checkEmail Checa se existe uma conta com este email
 * @apiVersion 0.1.0
 * @apiName ChecaEmail
 * @apiGroup Autenticacao
 * @apiPermission naoAutenticados
 *
 * @apiDescription A API deve receber o email e responder se o email está
 * disponível para ser utilizado no cadastro de um novo contribuidor. Toda
 * resposta bem sucedida deve resultar em código 200, contendo a informação se
 * o email está disponível no corpo da resposta.
 *
 * @apiParam {String{..120}}	email
 *
 * @apiSuccess {Boolean} available Flag sinalizando se o email pode ser utilizado.
 * 
 * @apiUse ServiceResponseError
 * 
 * @apiErrorExample {json} Erro email inválido:
 * {
 *  "code": "auth/invalid-email",
 *  "message": "O endereço de email não está bem formado."
 * }
 *
 */
// =============================== API DOC - END ===============================

// ============================== JS DOC - START ===============================
/**
 * @function checkEmail
 * @param {String} email
 */
// =============================== JS DOC - END ================================
export function checkEmail(email) {
	const endPoint = `${API_RO}/checkEmail`
	return defaultFetch(endPoint, {
		method: "POST",
		body: email,
		headers: {
			"Content-Type": "text/plain",
		},
	})
	.then(c => {
		if (c) throw MESSAGES['auth/used_email']

		return true
	})
}

// ============================== API DOC - START ==============================

/**
 * @apiDefine paramsLogin Parâmetros - Dados para login
 */

/**
 * @apiDefine paramsPersonal Parâmetros - Dados pessoais
 */

/**
 * @api {post} /contribuidor/create Criar um novo usuário
 * @apiVersion 0.1.0
 * @apiName CriarUsuario
 * @apiGroup Autenticacao
 * @apiPermission naoAutenticados
 *
 * @apiDescription A API deve receber estes dados, criar um registro na tabela
 * <i>USUARIO</i> e então a associação deste com a tabela <i>CONTRIBUIDOR</i>.
 * Para o registro, a API deve registrar o campo <i>dtype</i> como
 * <b>Contribuidor</b>. Os demais campos são passados via parâmetros.
 * 
 * <b>Os parâmetros foram aqui separados apenas para agrupar os semelhantes,
 * entretanto todos devem compôr o mesmo registro.</b>
 *
 * @apiParam (paramsLogin) {String{..120}}	email	<b>Atenção</b>: a
 * configuração da tabela diz <i>DEFAULT NULL</i>, entretanto creio que seja um
 * equívoco.
 * @apiParam (paramsLogin) {String{..20}}	senha
 * 
 * @apiParam (paramsPersonal) {String{..100}}	nome
 * @apiParam (paramsPersonal) {String{..40}}		[cpfcnpj]		<b>Atenção</b>:
 * a configuração da tabela diz <i>NOT NULL</i>, mas pelo que reparei da
 * interface e olhei os dados do banco, me parece um campo opcional.
 * @apiParam (paramsPersonal) {String{..40}}		[rg]
 * @apiParam (paramsPersonal) {String{..100}}	[profissao]		<b>NÃO
 * UTILIZADA</b>: depois de analisar o código fonte, aparentemente esse campo só
 * é utilizado caso o órgão seja um consórcio ou seja a empresa Mirex-S, coisa
 * que acho que deixará de ser utilizada.
 * @apiParam (paramsPersonal) {String{..80}}		[instituicao]	<b>NÃO
 * UTILIZADA</b>: depois de analisar o código fonte, aparentemente esse campo só
 * é utilizado caso o órgão seja um consórcio ou seja a empresa Mirex-S, coisa
 * que acho que deixará de ser utilizada.<br /><br />Instituição onde o usuário
 * trabalha.
 * @apiParam (paramsPersonal) {String{..50}}		[area]			<b>NÃO
 * UTILIZADA</b>: depois de analisar o código fonte, aparentemente esse campo
 * não é utilizado para contribuintes, apenas para administrador geral e
 * representantes.<br /><br />Área onde o usuário trabalha.
 * @apiParam (paramsPersonal) {Boolean}			[receberEmails]	Flag para
 * determinar se o usuário deseja receber emails.
 * 
 * @apiParam (paramsPersonal) {String{..40}}	[telefone]	Telefone principal do
 * usuário. Na interface é exibido como o número de celular.
 * @apiParam (paramsPersonal) {String{..40}}	[telefone2]	Telefone secundário do
 * usuário. Na interface é exibido como o telefone fixo.
 * 
 * @apiParam (paramsPersonal) {String{..50}="Acima de 20 salários mínimos","Entre
 * 10 e 20 salários mínimos","Entre 4 e 10 salários mínimos","Entre 2 e 4
 * salários mínimos","Até 2 salários mínimos"}	[faixasalarial]	Renda familiar
 * do usuário.
 * @apiParam (paramsPersonal) {String{..50}=Masculino,Feminino} [sexo]	Sexualidade
 * do usuário.
 * @apiParam (paramsPersonal) {String{..50}="Sem instrução","Ensino fundamental
 * ou 1º grau incompleto","Ensino fundamental ou 1º grau completo","Ensino médio
 * ou 2º grau incompleto","Ensino médio ou 2° grau completo","Ensino superior
 * incompleto","Ensino superior completo","Ensino superior completo com
 * pós-graduação"} [escolaridade]
 * @apiParam (paramsPersonal) {String{..50}="Até 17 anos","De 18 até 24
 * anos","De 25 até 39 anos","40 anos ou mais"} [faixaetaria]
 * @apiParam (paramsPersonal) {String{..255}=Senhor,Senhora} [tratamento] Forma
 * que o usuário deseja ser chamado.
 * 
 * @apiParam (paramsPersonal) {String}			[estado]			<b>Atenção</b>:
 * a configuração da tabela diz <i>NOT NULL</i>, mas pelo que reparei da
 * interface e olhei os dados do banco, me parece um campo opcional.
 * @apiParam (paramsPersonal) {String{..100}}	[cidade]
 * @apiParam (paramsPersonal) {String{..250}}	[endereco]			Basicamente
 * uma concatenação da rua com o número da rua. Mantive este
 * ao invés de deixar os dois separados.
 * @apiParam (paramsPersonal) {String{..20}}	[cep]
 * @apiParam (paramsPersonal) {String}			[latitudeLongitude]	Exata
 * coordenada de onde o usuário mora. <b>Atenção</b>: a configuração da tabela
 * diz <i>NOT NULL</i>, mas pelo que reparei da interface e olhei os dados do
 * banco, me parece um campo opcional.
 *
 * @apiUse AuthSuccess
 * 
 * @apiUse ServiceResponseError
 * 
 * @apiErrorExample {json} Erro email já existe:
 * {
 *  "code": "auth/email-already-in-use",
 *  "message": "O endereço de email já está sendo utilizado por outra conta."
 * }
 * @apiErrorExample {json} Erro email inválido:
 * {
 *  "code": "auth/invalid-email",
 *  "message": "O endereço de email não está bem formado."
 * }
 * @apiErrorExample {json} Erro senha fraca:
 * {
 *  "code": "auth/weak-password",
 *  "message": "A senha precisa ter pelo menos caracteres."
 * }
 *
 */
// =============================== API DOC - END ===============================

// ============================== JS DOC - START ===============================
/**
 * @typedef Contribuidor
 * @property {String} email
 * @property {String} senha
 * @property {String} nome
 * @property {String} [cpfcnpj]
 * @property {String} [rg]
 * @property {String} [telefone] Celular
 * @property {String} [telefone2] Telefone
 * @property {String} [estado]
 * @property {String} [cidade]
 * @property {String} [endereco]
 * @property {String} [cep]
 * @property {String} [latitudeLongitude]
 * @property {Sexo} [sexo]
 * @property {Escolaridade} [escolaridade]
 * @property {FaixaEtaria} [faixaEtaria]
 * @property {FaixaSalarial} [faixaSalarial]
 * @property {Tratamento} [tratamento]
 */

/**
 * @function signUp
 * @param {String} token
 * @param {Contribuidor} profile
*/
// =============================== JS DOC - END ================================
export async function signUp(profile) {

	const endPoint = `${API_RW}/create`

	const _pt = await personalToken()
	return defaultFetch(endPoint, {
		method: "POST",
		body: JSON.stringify({ ...profile, _pt })
	}).then(res => {
		setUser(res.contribuidor.id)
		sessionStorage.setItem('auth-token', res.token)
		sessionStorage.setItem('auth-profile', JSON.stringify(res.contribuidor))
		return res.contribuidor
	})
}

// ============================== API DOC - START ==============================
/**
 * @apiDefine UpdateSuccess
 * @apiSuccess {String} token Chave atualizada do usuário, utilizado para
 * autorizá-lo em futuras requisições.
 */

/**
 * @api {post} /contribuidor/update Atualizar dados do usuário
 * @apiVersion 0.1.0
 * @apiName AtualizarUsuario
 * @apiGroup Autenticacao
 * @apiPermission autenticados
 *
 * @apiDescription A API deve receber estes dados, verificar se a autenticação é
 * válida (seja por token ou por usuário e senha) e então atualizar o registro
 * deste usuário na tabela <i>USUARIO</i>.
 *
 * @apiHeader {String}	Authorization	Token de autorização, podendo
 * ser do tipo Basic ou do tipo Bearer. No Basic, o nome de usuário com a senha
 * são combinados por dois pontos e convertidos para base64. Não é um método
 * seguro e deve ser evitado pois codificação em base64 não significa
 * encriptação ou hashing. Para resolver isso, utiliza-se o tipo Bearer para se
 * referir a OAuth 2.0, onde no caso utilizaremos JWT.
 *
 * @apiHeaderExample {json} Cabeçalho com email e senha:
 * 				{ "Authorization": "Basic YWxhZGRpbjpvcGVuc2VzYW1l" }
 * @apiHeaderExample {json} Cabeçalho com JWT:
 * 				{ "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c" }
 *
 * @apiParam (paramsPersonal) {String{..100}}	[nome]
 * @apiParam (paramsPersonal) {String{..40}}	[cpfcnpj]	<b>Atenção</b>:
 * a configuração da tabela diz <i>NOT NULL</i>, mas pelo que reparei da
 * interface e olhei os dados do banco, me parece um campo opcional.
 * @apiParam (paramsPersonal) {String{..40}}	[rg]
 * @apiParam (paramsPersonal) {String{..100}}	[profissao]	<b>NÃO
 * UTILIZADA</b>: depois de analisar o código fonte, aparentemente esse campo só
 * é utilizado caso o órgão seja um consórcio ou seja a empresa Mirex-S, coisa
 * que acho que deixará de ser utilizada.
 * @apiParam (paramsPersonal) {String{..80}}	[instituicao]	<b>NÃO
 * UTILIZADA</b>: depois de analisar o código fonte, aparentemente esse campo só
 * é utilizado caso o órgão seja um consórcio ou seja a empresa Mirex-S, coisa
 * que acho que deixará de ser utilizada.<br /><br />Instituição onde o usuário
 * trabalha.
 * @apiParam (paramsPersonal) {String{..50}}	[area]	<b>NÃO
 * UTILIZADA</b>: depois de analisar o código fonte, aparentemente esse campo
 * não é utilizado para contribuintes, apenas para administrador geral e
 * representantes.<br /><br />Área onde o usuário trabalha.
 * @apiParam (paramsPersonal) {Boolean}			[receberEmails]	Flag para
 * determinar se o usuário deseja receber emails.
 *
 * @apiParam (paramsPersonal) {String{..40}}	[telefone]	Telefone principal
 * do usuário. Na interface é exibido como o número de celular.
 * @apiParam (paramsPersonal) {String{..40}}	[telefone2]	Telefone secundário
 * do usuário. Na interface é exibido como o telefone fixo.
 *
 * @apiParam (paramsPersonal) {String{..50}="Acima de 20 salários mínimos","Entre
 * 10 e 20 salários mínimos","Entre 4 e 10 salários mínimos","Entre 2 e 4
 * salários mínimos","Até 2 salários mínimos"}	[faixasalarial]	Renda familiar
 * do usuário.
 * @apiParam (paramsPersonal) {String{..50}=Masculino,Feminino}	[sexo]
 * Sexualidade do usuário.
 * @apiParam (paramsPersonal) {String{..50}="Sem instrução","Ensino fundamental
 * ou 1º grau incompleto","Ensino fundamental ou 1º grau completo","Ensino médio
 * ou 2º grau incompleto","Ensino médio ou 2° grau completo","Ensino superior
 * incompleto","Ensino superior completo","Ensino superior completo com
 * pós-graduação"}								[escolaridade]
 * @apiParam (paramsPersonal) {String{..50}="Até 17 anos","De 18 até 24
 * anos","De 25 até 39 anos","40 anos ou mais"} [faixaetaria]
 * @apiParam (paramsPersonal) {String{..255}=Senhor,Senhora} [tratamento] Forma
 * que o usuário deseja ser chamado.
 *
 * @apiParam (paramsPersonal) {String}			[estado]	<b>Atenção</b>: a
 * configuração da tabela diz <i>NOT NULL</i>, mas pelo que reparei da interface
 * e olhei os dados do banco, me parece um campo opcional.
 * @apiParam (paramsPersonal) {String{..100}}	[cidade]
 * @apiParam (paramsPersonal) {String{..250}}	[endereco]	Basicamente uma
 * concatenação da rua com o número da rua. Mantive este ao invés de deixar os
 * dois separados.
 * @apiParam (paramsPersonal) {String{..20}}	[cep]
 * @apiParam (paramsPersonal) {String}			[latitudeLongitude]	Exata
 * coordenada de onde o usuário mora. <b>Atenção</b>: a configuração da tabela
 * diz <i>NOT NULL</i>, mas pelo que reparei da interface e olhei os dados do
 * banco, me parece um campo opcional.
 *
 * @apiUse UpdateSuccess
 *
 * @apiUse ServiceResponseError
 *
 * @apiErrorExample {json} Autenticação inválida:
 * {
 *  "code": "auth/invalid-authentication",
 *  "message": "A autenticação não foi confirmada."
 * }
 *
 */
// =============================== API DOC - END ===============================

// ============================== JS DOC - START ===============================
/**
 * @function updateUser
 * @param {String} token
 * @param {Contribuidor} profile
 */
// =============================== JS DOC - END ================================
export async function updateUser(token, profile) {
	const endPoint = `${API_RW}/update`

	const _pt = await personalToken()
	return defaultFetch(endPoint, {
		method: "POST",
		body: JSON.stringify({ ...profile, _pt }),
		headers: {
			'Authorization': `Bearer ${token}`,
		},
	})
}

// ============================== API DOC - START ==============================
/**
 * @api {post} /contribuidor/forgotPassword Usuário esqueceu a senha
 * @apiVersion 0.1.0
 * @apiName ForgotPassword
 * @apiGroup Autenticacao
 * @apiPermission naoAutenticados
 *
 * @apiDescription A API deve verificar se o email enviado confere com algum
 * usuário da tabela <i>USUARIO</i> e então enviar o link para que o usuário
 * redefina sua senha para este email.
 * 
 * Atualmente, mesmo que as senhas não estejam encriptadas, seria bom criarmos
 * um token (com JWT) que autorize o usuário definir uma nova senha pelo próprio
 * PWA. Dessa forma, não fica evidente ao usuário que sua senha é visível e já
 * nos prepara para implementar uma encriptação futura.
 * 
 * <b>A API não deve responder de forma negativa caso não exista um usuário com
 * este email. Para evitar que "caçem" quais emails estão cadastrados ou
 * não.</b>
 *
 * @apiParam {String{..120}} email Email de recuperação de senha.
 */
// =============================== API DOC - END ===============================

// ============================== JS DOC - START ===============================
/**
 * @function forgotPassword
 * @param {String} email
 */
// =============================== JS DOC - END ================================
export async function forgotPassword({ email }) {
	const endPoint = `${API_RO}/senhaEsquecida`
	return defaultFetch(endPoint, {
		method: "POST",
		body: email,
		headers: {
			"Content-Type": "text/plain",
		},
	})
	.then(() => true)
}

// ============================== API DOC - START ==============================
/**
 * @api {post} /contribuidor/updatePassword Redefinir senha do Usuário
 * @apiVersion 0.1.0
 * @apiName UpdatePassword
 * @apiGroup Autenticacao
 * @apiPermission autenticados
 *
 * @apiDescription A API deve verificar se o token de autenticação é válido e
 * então atualizar a senha deste usuário na tabela <i>USUARIO</i>.
 *
 * @apiHeader {String}	Authorization	Token de autorização recebido por email.
 * 
 * @apiHeaderExample {json} Cabeçalho com JWT:
 * 				{ "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c" }
 *
 * @apiParam {String{..120}} senha Nova senha do usuário.
 *
 * @apiUse ServiceResponseError
 *
 * @apiErrorExample {json} Erro token inválido:
 * {
 *  "code": "auth/invalid-token",
 *  "message": "O token enviado expirou ou não é válido."
 * }
 */
// =============================== API DOC - END ===============================

// ============================== JS DOC - START ===============================
/**
 * @function updatePassword
 * @param {String} senha
 */
// =============================== JS DOC - END ================================
export function updatePassword(token, password) {
	const endPoint = `${API_RW}/updatePassword`
	return defaultFetch(endPoint, {
			method: "POST",
			body: password,
			headers: {
				"Content-Type": "text/plain",
				'Authorization': `Bearer ${token}`,
			},
		})
		.then(() => true)
}

// ============================== API DOC - START ==============================
/**
 * @api {post} /auth/checkToken Checar token
 * @apiVersion 0.1.0
 * @apiName ChecarToken
 * @apiGroup Autenticacao
 * @apiPermission autenticados
 *
 * @apiDescription A API deve responder se o token de autenticação é válido.
 *
 * @apiHeader {String}	Authorization	Token de autorização.
 * 
 * @apiHeaderExample {json} Cabeçalho com JWT:
 * 				{ "Authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c" }
 *
 * @apiUse ServiceResponseError
 *
 * @apiErrorExample {json} Erro token inválido:
 * {
 *  "code": "auth/invalid-token",
 *  "message": "O token enviado expirou ou não é válido."
 * }
 */
// =============================== API DOC - END ===============================

// ============================== JS DOC - START ===============================
/**
 * @function checkToken
 */
// =============================== JS DOC - END ================================
export function checkToken() {

}

export function disableAccount(token) {
	const endPoint = `${API_AUTH}/disableAccount`
	return defaultFetch(endPoint, {
		method: "POST",
		headers: {
			'Authorization': `Bearer ${token}`,
		},
	})
		.then(() => true)
}

export function disableAccountNow(token) {
	const endPoint = `${API_AUTH}/disableAccountNow`
	return defaultFetch(endPoint, {
		method: "POST",
		headers: {
			'Authorization': `Bearer ${token}`,
		},
	})
		.catch(err => {
			if (err === MESSAGES['auth/fail']) throw MESSAGES['user/disable_token_invalid']
			throw err
		})
		.then(() => true)
}
