/**
 * Resets the validity message of an container
 * @param {HTMLElement} form 
 */
export const resetCustomValidity = form => {
	/* eslint-disable no-unused-expressions */
	form?.setCustomValidity?.('')
	form?.querySelectorAll?.('*:not([data-prevent-reset-custom-message])')
		.forEach(elem => elem.setCustomValidity?.(''))
}
