import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { MDBInput } from 'mdbreact';

import { PageCounter, NonIdealState } from '.';

// TODO: pre-create all the content and wrappers to prevent the glitch on Masonry (why only happen when go back?)

export default function Pagination({ maxResults = 5, data, map, history, wrapper: Wrapper, searchField, searchLabel }){
	data = data || []
	// const havePagination = data.length > maxResults
	const [searchString, setSearchString] = useState("")

	// if (havePagination) {

		if (searchField && searchString !== "")
			data = data.filter(x => x[searchField].toLowerCase().search(searchString.toLowerCase()) !== -1)

		const nPages = Math.ceil(data.length / maxResults),
			params = new URLSearchParams(window.location.search),
			paramPage = Number(params.get("page"));

		const limitePage = useCallback(page => {
			return Math.abs(page) % (nPages + 1) || 1
		}, [nPages])

		const pageToOffset = useCallback(page => {
			return (limitePage(page) - 1) * maxResults
		}, [limitePage, maxResults])

		const [pageOffset, setPageOffset] = useState(pageToOffset(paramPage) || 0),
			page = Math.ceil(pageOffset / maxResults) + 1;

		const setPage = useCallback(page => {
			return setPageOffset(pageToOffset(page))
		}, [pageToOffset])

		const { pathname } = useLocation()

		useEffect(() => {
			history.push({ pathname, search: `?page=${page}` })
		}, [history, pathname, page])

		const onSearch = useCallback(e => {
			setSearchString(e.target.value)
			setPage(1)
		}, [setPage])

		const sliceStart = pageOffset,
			sliceEnd = pageOffset+maxResults,
			Content = data.slice(sliceStart, sliceEnd).map(map)

		return (
			<>
				{searchField ? <MDBInput
					type="search"
					name="q"
					label={searchLabel || "Busque aqui"}
					onChange={onSearch}
					value={searchString}
				/> : nPages > 1 && <PageCounter {...{ nPages, page, setPage }} />}

				{Content.length ? <>
					{Wrapper ? <Wrapper>{Content}</Wrapper> : Content}

					{nPages > 1 && <PageCounter {...{ nPages, page, setPage }} />}

					<span className="text-muted">Mostrando {nPages === 1 ? <>todos os</> : <>{Content.length} de {data.length}</>} resultados.</span>
				</> : <NonIdealState
					icon="no-data"
					title="Nenhum resultado"
					height="250px"
				/>}
			</>
		);
	// } else {
	// 	const Content = data.map(map)
	// 	return (
	// 		<>
	// 			{Wrapper ? <Wrapper>{Content}</Wrapper> : Content}

	// 			<span className="text-muted">Mostrando {data.length} resultados.</span>
	// 		</>
	// 	)
	// }
}
