import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (min-width: 512px) {
		flex-direction: row;
	}
	@media (max-width: 512px) {
		flex-direction: column;
		gap: 10px;
	}
`
