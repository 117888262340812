import styled from "styled-components";

export const Footer = styled.footer`
	width: 100vw;
	text-align: center;
	padding: 25px 0;
	border-top: 1px solid #E0E0E0;
	color: #828282;
	font-size: 12px;
`
