import React from 'react'
import { MDBNavbar } from "mdbreact"
import styled from "styled-components"

const Container = styled(MDBNavbar)`
	cursor: pointer;
	display: initial !important;
	text-align: center;
	color: var(--UpdateNavbar_color);
	background-color: var(--UpdateNavbar_bg_color);
	z-index: 9;
	font-size: .8em;

	b {
		font-weight: 500;
	}

	&:hover span {
		text-decoration: underline;
	}
`

export const Presenter = ({ show, onClick }) => show && <Container
	{...{ onClick }}
>
	<b>Atualização disponível!</b> <span>Clique aqui para atualizar o {process.env.REACT_APP_BRANDING_NAME}.</span>
</Container>
