/* eslint-disable no-unused-expressions */
import { useEffect, useImperativeHandle } from 'react'

import { useLayout, useStepper } from '../../utils'

export const useStepLogic = ({ onBack, onNext, disableGoBack = false, loading, handleBack }) => {
	const { _stepRef } = useStepper()

	useImperativeHandle(_stepRef, () => ({
		doBack: onBack,
		doNext: onNext,
		loading,
	}), [onBack, onNext, loading])

	const { disableGoBackState, handleBackState } = useLayout(),
		setDisableGoBack = disableGoBackState?.[1],
		setHandleBack = handleBackState?.[1]
	
	useEffect(() => {
		setDisableGoBack?.(disableGoBack)
	}, [setDisableGoBack, disableGoBack])

	useEffect(() => {
		setHandleBack?.(() => handleBack)
	}, [setHandleBack, handleBack])
}
