export const MESSAGES = {
	"default": {
		title: "Ocorreu um erro",
		description: "Seremos alertados sobre o ocorrido e faremos o máximo para corrigir o problema o quanto antes.",
	},

	// Front-end

	"server/unreachable": {
		icon: "server_down",
		title: "A requisição falhou",
		description: "Não conseguimos contato com o servidor. Se o problema persistir, podemos estar passando por uma queda em nossos serviços e retornaremos o quanto antes. Tente novamente mais tarde.",
		action: "retry",
	},

	"auth/invalid_email": {
		icon: "notify",
		title: "Email inválido",
		description: "Por favor, insira um email válido.",
		// color: "var(--primaryBg)",
	},
	"auth/used_email": {
		icon: "notify",
		title: "Email já cadastrado",
		description: "Este email já está sendo utilizado. Por favor, recupere a sua senha ou utilize outro email.",
	},
	"auth/invalid_cpf": {
		icon: "notify",
		title: "CPF inválido",
		description: "Por favor, insira um CPF válido.",
	},
	"auth/invalid_telefone": {
		icon: "notify",
		title: "Telefone inválido",
		description: "Por favor, insira um telefone válido.",
	},
	"auth/recover_email_sent": {
		icon: "inbox",
		title: "Email enviado.",
		description: "Verifique sua caixa de entrada por um email de recuperação de senha.",
		action: "login",
	},

	"user/update_success": {
		icon: "profile",
		title: "Perfil atualizado",
		description: "Seus dados já foram atualizados e serão utilizados em suas próximas contribuições.",
	},

	"user/disable_request_sent": {
		icon: "inbox",
		title: "Email enviado",
		description: "Verifique sua caixa de entrada por um email para completar o processo de desativar sua conta.",
	},

	"user/account_disabled": {
		icon: "throw_away",
		title: "Conta desabilitada",
		description: "Sua conta foi desabilitada com sucesso. Sessões ativas não serão imediatamente desconectadas, mas serão incapazes de realizar contribuições. Novas sessões não conseguirão se autenticar.",
	},

	"user/disable_token_invalid": {
		icon: "notify",
		title: "Token inválido.",
		description: "Verifique o link acessado pelo seu email ou nos notifique caso acredite que esteja havendo um problema maior.",
	},

	"contribution/updated": {
		icon: "preferences",
		title: "Contribuição atualizada",
		description: "Suas respostas foram salvas.",
	},

	// API

	"server/generic_error": {
		icon: "unexpected_friends",
		title: "Ocorreu um erro inesperado",
		description: "Ocorreu um erro no servidor e que o fez não responder como esperado. Seremos alertados sobre isso e corrigiremos o mais rápido possível. Tente novamente mais tarde.",
		action: "retry",
	},
	"server/database_error": {
		title: "Ocorreu um erro inesperado",
		description: "Não conseguimos recuperar os dedados para esta requisição. Tente novamente mais tarde.",
		action: "retry",
	},
	
	"request/invalid": {
		title: "Requisição inválida!",
		description: "Esta requisição falhou, tente novamente mais tarde ou entre em contato conosco para corrigirmos o problema.",
	},
	
	"service/unavailable": {
		title: "Serviço indisponível",
		description: "Algum de nossos serviços não estão disponíveis no momento. Tente novamente mais tarde.",
	},
	
	"auth/invalid": {
		title: "Dados inválidos!",
		description: "Os dados enviados não estão válidos, tente novamente mais tarde ou entre em contato conosco para corrigirmos o problema.",
	},
	"auth/fail": {
		icon: "my_password",
		title: "Autenticação falhou",
		description: "Por favor, faça login novamente.",
		action: "login",
	},
	"auth/login_fail": {
		icon: "my_password",
		title: "Login falhou",
		description: "Por favor, verifique os dados e tente novamente.",
	},
	"auth/not_found": {
		title: "Usuário não encontrado",
		description: "Este usuário não foi encontrado. Tente novamente.",
	},
	"auth/weak_password": {
		icon: "my_password",
		title: "Senha muito fraca.",
		description: "Por favor, utilize letras minúsculas, maiúsculas, números e com pelo menos 6 caracteres no total.",
		// color: "var(--primaryBg)",
	},
	"auth/duplicated_email": {
		icon: "notify",
		title: "Email já está sendo usado",
		description: "Por favor, insira um email diferente ou recupere sua conta.",
	},
	
	"agency/invalid": {
		title: "Dados inválidos!",
		description: "Os dados enviados não estão válidos, tente novamente mais tarde ou entre em contato conosco para corrigirmos o problema.",
	},
	"agency/invalid_origin": {
		title: "Dados inválidos!",
		description: "Os dados enviados não estão válidos, tente novamente mais tarde ou entre em contato conosco para corrigirmos o problema.",
	},
	"agency/inactive": {
		title: "Órgão inativo",
		description: "Este órgão foi marcado como inativo. Caso seja o administrador deste órgão, entre em contato conosco para regularizar a situação.",
	},
	"agency/not_found": {
		icon: "page_not_found",
		title: "Órgão não encontrado",
		description: "Este órgão não foi encontrado, tente novamente.",
	},
	
	"consultation/invalid": {
		title: "Dados inválidos!",
		description: "Os dados enviados não estão válidos, tente novamente mais tarde ou entre em contato conosco para corrigirmos o problema.",
	},
	"consultation/not_found": {
		icon: "page_not_found",
		title: `Pesquisa não encontrada`,
		description: `Esta pesquisa não foi encontrada, tente novamente.`,
	},
	
	"contribution/invalid": {
		title: "Dados inválidos!",
		description: "Os dados enviados não estão válidos, tente novamente mais tarde ou entre em contato conosco para corrigirmos o problema.",
	},
	"contribution/not_found": {
		icon: "page_not_found",
		title: "Contribuição não encontrada",
		description: "Esta contribuição não foi encontrada, tente novamente.",
	},
}