import styled from "styled-components"
import FormContribuiçãoQuestãoDiscursiva from "./FormContribuiçãoQuestãoDiscursiva"
import FormContribuiçãoQuestãoObjetiva from "./FormContribuiçãoQuestãoObjetiva"
import { Undraw } from "./Undraw"


const Container = styled.div`
	position: relative;
`

const BackgroundSpace = styled.div`
	filter: blur(9px);
	pointer-events: none;
`

const ForegroundSpace = styled.div`
	position: absolute;
	top: 0; bottom: 0;
	left: 0; right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`

const Banner = styled.div`
	background-color: var(--primaryBg);
	color: var(--primaryTx);
	border-radius: 3px;
	box-shadow: 6px 4px 10px rgba(0, 0, 0, 0.25);
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
	text-align: center;
	padding: 25px;

	svg {
		height: 115px;
		width: 40% !important;
	}

	p {
		margin: 0;
		font-size: 23px;
		max-width: 400px;
	}
`

export function WaitingSegment() {
	return <Container>
		<BackgroundSpace>
			<FormContribuiçãoQuestãoObjetiva
				id="fake_options"
				titulo="Escolha atentamente sua resposta:"
				alternativas={[{
					id: 'fake_01',
					texto: 'Sim',
				}, {
					id: 'fake_02',
					texto: 'Não',
				}]}
				readOnly={true}
			/>
			<FormContribuiçãoQuestãoDiscursiva
				id="fake_text"
				titulo="Escreva livremente sua opinião"
				required={false}
				readOnly={true}
			/>
		</BackgroundSpace>
		<ForegroundSpace>
			<Banner>
				<Undraw name="project_completed" primaryColor={"currentColor"} />
				<p>As questões serão carregadas de acordo com seu perfil</p>
			</Banner>
		</ForegroundSpace>
	</Container>
}
