/* eslint-disable no-unused-expressions */
import React, { useCallback, useContext, useMemo, useRef, useState } from "react"
import { SEQUENCIA } from "../.."
import { PageContext } from "../../../../pages"
import { signIn } from "../../../../services"
import { getFormData, resetCustomValidity } from "../../../../utils"
import { useStepper } from "../../../Stepper/utils"
import { modalView } from 'services/GA'
import retryForm from "utils/retryForm"

export const useLogic = ({
	switchSequencia, neededSections, mustCompleteLogin, mustCheckProfile,
}) => {
	const formRef = useRef(),
		submitRef = useRef({}),
		{ next } = useStepper(),
		{ setLoading } = useContext(PageContext),
		[hasError, setError] = useState(false)

	const handleNext = useCallback(async e => {
		resetCustomValidity(e.target)
		resetCustomValidity(formRef.current)
		submitRef.current.target = e.target

		if (formRef.current?.checkValidity()) {
			return new Promise(res => {
				submitRef.current.res = res
			})
		}

		return false
	}, [])

	const handleSubmit = useCallback(e => {
		e.preventDefault()

		setLoading(true)
		setError(false)

		const form = formRef.current
		const data = getFormData(form)

		signIn(data)
			.then(r => {
				const profile = r.contribuidor
				for (const field in neededSections) {
					let realField = {
						CEP							: 'cep',
						CPF							: 'cpfcnpj',
						Celular						: 'telefone',
						Cidade_Estado				: ['estado', 'cidade'],
						Endereço					: 'endereco',
						Escolaridade				: 'escolaridade',
						'Faixa Etária'				: 'faixaEtaria',
						'Localização Geográfica'	: 'latitudeLongitude',
						'Renda Familiar'			: 'faixaSalarial',
					}[field]

					if (!realField) continue

					if (neededSections[field] && ((typeof realField === 'string' && !profile[realField]) || (Array.isArray(realField) && realField.every(x => !profile[x]))))
						return mustCompleteLogin()
				}

				if (r.checkProfile) mustCheckProfile(profile)

				return !!profile
			})
			.catch(message => {
				setError(retryForm(message, e, () => setError(false)))
				return false
			})
			.then(v => {
				setLoading(false)
				if (v) modalView("Login feito")
				const { res } = submitRef.current
				res(v)
			})
	}, [setLoading, neededSections, mustCompleteLogin, mustCheckProfile])

	const bottomLinks = useMemo(() => [<>
		Esqueceu a
		<a href="#!" onClick={switchSequencia(SEQUENCIA.FORGOT)}>senha?</a>
	</>, <>
		Não tem uma conta?
		<a href="#!" onClick={switchSequencia(SEQUENCIA.CADASTRO)}>Cadastre-se</a>
	</>], [switchSequencia])

	return {
		formRef, bottomLinks,
		handleNext, handleSubmit, handleBtnClick: next,
		hasError,
	}
}
