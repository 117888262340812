import React from 'react'
import {
	MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon,
} from 'mdbreact';
import styled from 'styled-components'

import { modalView } from 'services/GA'

import { useToggle } from '../utils';
import isIosSafari from '../utils/isIosSafari';
import appleShare from '../assets/apple-share.png'

const FabButton = styled(MDBBtn)`
	border-radius: 50% !important;
	width: 60px;
	height: 60px;
	padding: 0 !important;
	position: fixed;
	right: 25px;
	bottom: 45px;
	z-index: 1000;
`

const ShareIcon = styled(props => <img {...props} src={appleShare} alt="Apple Share Icon" />)`
	filter: invert(1);
	display: inline-block !important;
	width: 15px;
	margin: 0 4px;
`

export default function A2HSModal({canInstall, install}) {
	const [active, toggle] = useToggle(false),
		onIosSafari = isIosSafari()

	return (canInstall && <>
		<FabButton
			className="fasb aqua-gradient"
			onClick={() => {
				toggle()
				modalView("Instalar PWA")
			}}
		><MDBIcon icon="download" size="lg"/></FabButton>
		<MDBModal
			isOpen={active}
			toggle={toggle}
		>
			<MDBModalHeader toggle={toggle}>Instalar {process.env.REACT_APP_BRANDING_NAME}</MDBModalHeader>
			<MDBModalBody>
				<p>Instale este site na sua tela inicial para ter acesso rápido e fácil com a experiência de um aplicativo nativo.</p>
				<p>Saiba mais <a href="https://pt.wikipedia.org/wiki/Progressive_Web_App" target="_blank" rel="noopener noreferrer">aqui</a>.</p>
			</MDBModalBody>
			<MDBModalFooter style={{justifyContent: "center"}}>
				{onIosSafari ? <MDBBtn
					color="elegant"
					disabled
				>Toque em <ShareIcon /> depois em "Adicionar à Tela de Início"</MDBBtn> : <MDBBtn
					color="primary"
					onClick={() => {
						toggle()
						install()
					}}
				>Instalar</MDBBtn>}
			</MDBModalFooter>
		</MDBModal>
	</>) || <></>
}