import { MDBInput as Input } from "mdbreact";
import { ModalPoliticaPrivacidade } from "./ModalPoliticaPrivacidade";

export const InputPoliticaPrivacidade = ({ orgaoData, ...props }) => <>
	<Input
		type="checkbox"
		name="politicaPrivacidade"
		required
		id="acceptPolitica"
		label={<>Aceito a <ModalPoliticaPrivacidade
			text="política de privacidade"
			orgaoData={orgaoData}
		/></>}
	/>
	<br />
</>
