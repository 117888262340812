import styled from "styled-components"

export const BottomLink = styled.p`
	color: var(--gray);
	margin-bottom: 0;
	font-size: var(--font-small);

	> a {
		color: var(--dark);
		font-weight: bold;
		margin-left: .4em;
	}
`
