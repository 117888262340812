import { Header } from "./styled/Header";
import { Container } from "./styled/Container";
import { ConPubLogo } from "./elements/ConPubLogo";
import { LogoutButton } from "./elements/LogoutButton/index";

export const UsuarioPageHeaderPresenter = ({ showLogout }) => <Header>
	<Container className="page-container">
		<ConPubLogo />
		{showLogout && <LogoutButton />}
	</Container>
</Header>
