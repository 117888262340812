import { Nav } from "./styled/Nav";
import { NavTitle } from "./styled/NavTitle";
import { NavLink } from "./styled/NavLink";

export const UsuarioPageNavPresenter = ({ title, links, activePage }) => <Nav>
	<NavTitle>{title}</NavTitle>
	{links.map(link => !link.hidden && <NavLink
		key={link.key}
		to={link.href}
		$active={link.href === activePage}
	>
		{link.text}
	</NavLink>)}
</Nav>