import React from 'react'
import { InputQuestaoDiscursiva } from '.'
import { H4 } from './FormContribuiçãoQuestão'

const FormContribuiçãoQuestãoDiscursiva = ({
	id, titulo, instrucao, ...p
}) => {
	return <>
		<H4>{titulo}</H4>
		<div dangerouslySetInnerHTML={{ __html: instrucao }}></div>

		<InputQuestaoDiscursiva
			key={id}
			id={id}
			name={id}
			{...p}
		/>
	</>
}

export default FormContribuiçãoQuestãoDiscursiva