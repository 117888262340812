import React from 'react'
import styled from 'styled-components'
import { useNavbarNavigation } from '../utils'

const Container = styled.ul`
	padding: 0;
	margin: 0;
	list-style: none;
	line-height: 12px;
	margin-top: 2px;
`

const Item = styled.li`
	display: inline;
	font-size: 12px;

	& + &::before {
		padding: 0 min(8px, 1vw);
		content: '/';
	}
`

const NavbarBreadcrumb = () => {
	const { breadCrumb } = useNavbarNavigation()

	return <Container>
		{breadCrumb.map((x, i) => <Item key={i}>
			{x}
		</Item>)}
	</Container>
}

export default NavbarBreadcrumb
