import { FaCalendar } from 'react-icons/fa';
import styled from 'styled-components';

const DataItemContainer = styled.li`
	margin-left: 23px;
	display: flex;
	gap: 10px;
	align-items: center;
	color: var(--Branding-Color-Dark);
`

const IconContainer = styled.div`
	border-radius: 50%;
	--size: 35px;
	width: var(--size);
	height: var(--size);
	background-color: white;
	border: 1px solid var(--Branding-Grey-5);
	display: flex;
	justify-content: center;
	align-items: center;
`

export const DataItem = ({ data }) => <DataItemContainer>
	<IconContainer>
		<FaCalendar />
	</IconContainer>
	{data}
</DataItemContainer>
