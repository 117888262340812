import { useCallback } from "react"
import asesImage from 'assets/ases.png'
import { html_beautify } from 'js-beautify'
import { redirectByPost } from "utils/redirectByPost"

const avaliarUrl = {
	postUrl: 'https://asesweb.governoeletronico.gov.br/avaliar',
	inputName: 'url'
}

const avaliarHTML = {
	postUrl: 'https://asesweb.governoeletronico.gov.br/avaliar-codigo',
	inputName: 'html'
}

// 1MB: 1_048_576
const MAX_ASES_CODE_SIZE = 1_047_747

export const AvaliarComASES = () => {
	const handleClick = useCallback(() => {
		const currentHTML = new XMLSerializer().serializeToString(document)
		const formatedHTML = html_beautify(currentHTML)

		const endpoint = formatedHTML.length < MAX_ASES_CODE_SIZE ? avaliarHTML : avaliarUrl
		const value = formatedHTML.length < MAX_ASES_CODE_SIZE ? formatedHTML : window.location.href

		const data = {
			mark: true,
			content: true,
			presentation: true,
			multimedia: true,
			form: true,
			behavior: true,
			[endpoint.inputName]: value
		}

		redirectByPost(endpoint.postUrl, data)
	}, [])

	return <img
		src={asesImage}
		alt="Avaliar Acessibilidade"
		title="Avaliar esta página com o Avaliador e Simulador de Acessibilidade em Sítios"
		onClick={handleClick}
		tabIndex={0}
		style={{ cursor: 'pointer' }}
	/>
}