import styled from "styled-components"
import { MDBMask } from "mdbreact"

export const Mask = styled(MDBMask)`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
`
