export function fixDbMasks(dbMask) {
	let mask, opts = {}
	
	switch (dbMask) {
		// lccl.qa.gm.data
		case "mask('99/99/9999')": // pt_BR | es_PE | en_US
			mask = "99/99/9999"
			break;
	
		// lccl.qa.gm.telefone
		case "mask('(99)9999-9999')": // pt_BR | es_PE | en_US
			mask = "(99) [\\9]9999-9999"
			break;

		// lccl.qa.gm.moeda
		case "maskMoney({symbol:'R$ ', showSymbol:true, thousands:'.', decimal:',', symbolStay: true, allowNegative:true})": // pt_BR
			opts = {
				alias: "currency",
				prefix: "R$ ",
				radixPoint: ",",
				groupSeparator: ".",
				digits: 2,
				positionCaretOnClick: "lvp",
				rightAlign: false,
				allowMinus: true,
				numericInput: true,
			}
			break;
		case "maskMoney({symbol:'S/ ', showSymbol:true, thousands:'.', decimal:',', symbolStay: true, allowNegative:true})": // es_PE
			opts = {
				alias: "currency",
				prefix: "€ ",
				radixPoint: ",",
				groupSeparator: ".",
				digits: 2,
				positionCaretOnClick: "lvp",
				rightAlign: false,
				allowMinus: true,
				numericInput: true,
			}
			break;
		case "maskMoney({symbol:'US$ ', showSymbol:true, thousands:',', decimal:'.', symbolStay: true, allowNegative:true})": // en_US
			opts = {
				alias: "currency",
				prefix: "US$ ",
				radixPoint: ".",
				groupSeparator: ",",
				digits: 2,
				positionCaretOnClick: "lvp",
				rightAlign: false,
				allowMinus: true,
				numericInput: true,
			}
			break;

		// lccl.qa.gm.numero
		case "maskMoney({thousands:'.', precision:0, allowNegative:true})": // pt_BR | es_PE
			opts = {
				alias: "integer",
				groupSeparator: ".",
				rightAlign: false,
				allowMinus: true,
				numericInput: true,
			}
			break;
		case "maskMoney({thousands:',', precision:0, allowNegative:true})": // en_US
			opts = {
				alias: "integer",
				groupSeparator: ",",
				rightAlign: false,
				allowMinus: true,
				numericInput: true,
			}
			break;

		// lccl.qa.gm.decimal
		case "maskMoney({thousands:'.', decimal:',', allowNegative:true})": // pt_BR | es_PE
			opts = {
				alias: "decimal",
				radixPoint: ",",
				groupSeparator: ".",
				rightAlign: false,
				allowMinus: true,
				numericInput: true,
			}
			break;
		case "maskMoney({thousands:',', decimal:'.', allowNegative:true})": // en_US
			opts = {
				alias: "decimal",
				radixPoint: ".",
				groupSeparator: ",",
				rightAlign: false,
				allowMinus: true,
				numericInput: true,
			}
			break;

		// lccl.qa.gm.cpf
		case "mask('999.999.999-99')": // pt_BR
			mask = "999.999.999-99"
			break;

		// lccl.qa.gm.cnpj
		case "mask('99.999.999/9999-99')": // pt_BR
			mask = "99.999.999/9999-99"
			break;

		case "not_empty":
			mask = ".{1,}"
			opts = {
				"placeholder": "",
				definitions: {
					".": {
						validator: ".",
					},
				},
			}
			break;

		default:
			break;
	}

	return { mask, opts }
}
