import React from 'react'
import {
	MDBPagination, MDBPageItem, MDBPageNav, MDBRow, MDBCol,
} from "mdbreact";

export default function PageCounter({ page, nPages, setPage }){
	return ( 
		<MDBRow>
			<MDBCol>
				<MDBPagination className="m-3 justify-content-center">
				<MDBPageItem
					disabled={page === 1}
					onClick={() => setPage(page-1)}
				>
					<MDBPageNav aria-label="Anterior">
					<span aria-hidden="true">Anterior</span>
					</MDBPageNav>
				</MDBPageItem>

				{
					[...Array(nPages || 0).keys()].map((p, i) => {
						return (
							<MDBPageItem
								key={i}
								active={p === page-1}
								onClick={() => setPage(p+1)}
							>
								<MDBPageNav>
								{p+1} {p === page ? <span className="sr-only">(current)</span> : <></>}
								</MDBPageNav>
							</MDBPageItem>
						)
					})
				}

				<MDBPageItem
					disabled={page === nPages}
					onClick={() => setPage(page+1)}
				>
					<MDBPageNav aria-label="Próximo">
					<span aria-hidden="true">Próximo</span>
					</MDBPageNav>
				</MDBPageItem>
				</MDBPagination>
			</MDBCol>
		</MDBRow>
	)
}