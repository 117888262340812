import React, {
	useEffect, useRef, useState, useContext,
} from 'react'

import { FormContribuição, ServiceAlert } from '.'
import { recuperarRespostas } from './FormContribuição'
import {
	TitleSetter, BodySetter, FooterSetter, NextButton,
} from './Stepper/layouts/MDB'
import { Right } from './Stepper/layouts/default'
import { Step } from './Stepper'
import { PageContext } from '../pages'

function CardConsultaDetalhesStep3V3() {
	const { consulta } = useContext(PageContext),
		{ id, questoes: questoesRecebidas, nome, segmentos } = consulta

	const [questoes, setQuestoes] = useState(),
		[contrLivre, setContrLivre] = useState(),
		[hasError, setError] = useState(false)

	useEffect(() => {
		recuperarRespostas(id, questoesRecebidas)
			.then(({ questoes, contribuicao_livre }) => {
				setContrLivre(contribuicao_livre)
				setQuestoes(questoes)
			})
	}, [id, questoesRecebidas])

	const formRef = useRef(),
		submitRef = useRef({})

	return <Step>
		<TitleSetter>{nome}</TitleSetter>
		<BodySetter>
			{hasError && <ServiceAlert message={hasError} onClose={() => setError()} autoClose />}
			<div className="text-center red-text">
				Confirme sua contribuição.
			</div>
			{questoes && <FormContribuição
				{...{ id, questoes, formRef, submitRef, contrLivre, segmentos }}
				disabled
			/>}
		</BodySetter>
		<FooterSetter stickyFooter>
			<Right>
				<NextButton
					type="submit"
					form="formContrib"
				>Confirmar</NextButton>
			</Right>
		</FooterSetter>
	</Step>
}

export default CardConsultaDetalhesStep3V3
