import ReactGA from "react-ga4";

function mightBeEmail(s) {
	// There's no point trying to validate rfc822 fully, just look for ...@...
	return typeof s === "string" && s.indexOf("@") !== -1;
}

const redacted = "REDACTED (Potential Email Address)";

function redactEmail(string) {
	if (mightBeEmail(string)) {
		console.warn("This arg looks like an email address, redacting.");
		return redacted;
	}

	return string;
}

const smallWords =
	/^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;
function toTitleCase(string) {
	return string
		.trim()
		.replace(/[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g, (match, index, title) => {
			if (
				index > 0 &&
				index + match.length !== title.length &&
				match.search(smallWords) > -1 &&
				title.charAt(index - 2) !== ":" &&
				(title.charAt(index + match.length) !== "-" ||
					title.charAt(index - 1) === "-") &&
				title.charAt(index - 1).search(/[^\s-]/) < 0
			) {
				return match.toLowerCase();
			}

			if (match.substr(1).search(/[A-Z]|\../) > -1) {
				return match;
			}

			return match.charAt(0).toUpperCase() + match.substr(1);
		});
}

let _debug = false;
let _titleCase = true;
let _redactEmail = true;

function format(s = "", titleCase = false, redactingEmail = true) {
	let _str = s || "";

	if (titleCase) {
		_str = toTitleCase(s);
	}

	if (redactingEmail) {
		_str = redactEmail(_str);
	}

	return _str;
}

function _format(s) {
	return format(s, _titleCase, _redactEmail);
}

function removeLeadingSlash(str) {
	if (str.charAt(0) === "/" || str.charAt(0) === "\\") {
		return str.slice(1);
	}

	return str;
}

export default function init() {
	ReactGA.initialize(process.env.REACT_APP_GA_ID, {
		testMode: process.env.REACT_APP_ENV !== "production",
	});
}

export function pageView() {
	// ReactGA.pageview(window.location.pathname + window.location.search)
	ReactGA.event("page_view", {
		page_location: window.location.pathname + window.location.search,
	});
}

export function modalView(name) {
	// ReactGA.modalview(name)
	ReactGA.event("page_view", {
		page_location: `/modal/${removeLeadingSlash(name.trim())}`,
	});
}

export function setUser(id) {
	ReactGA.set({ userId: id });
}

export function resetUser() {
	ReactGA.set({ userId: null });
}

/**
 * modalview:
 * a proxy to basic GA pageview tracking to consistently track
 * modal views that are an equivalent UX to a traditional pageview
 * @param  {String} modalName e.g. 'add-or-edit-club'
 * @param {Array} trackerNames - !!deprecated!! (optional) a list of extra trackers to run the command on
 */
export function modalview(rawModalName, trackerNames) {
	if (!rawModalName) {
		console.warn("modalName is required in .modalview(modalName)");
		return;
	}

	const modalName = removeLeadingSlash(rawModalName.trim());

	if (modalName === "") {
		console.warn(
			"modalName cannot be an empty string or a single / in .modalview()"
		);
		return;
	}

	if (typeof ga === "function") {
		const path = `/modal/${modalName}`;
		// _gaCommand(trackerNames, "send", "pageview", path);
		ReactGA.event("page_view", {
			page_location: path,
		});

		if (_debug) {
			console.log("called ga('send', 'pageview', path);");
			console.log(`with path: ${path}`);
		}
	}
}

/**
 * timing:
 * GA timing
 * @param args.category {String} required
 * @param args.variable {String} required
 * @param args.value  {Int}  required
 * @param args.label  {String} required
 * @param {Array} trackerNames - (optional) a list of extra trackers to run the command on
 */
export function timing(
	{ category, variable, value, label } = {},
	trackerNames = undefined
) {
	if (!category || !variable || typeof value !== "number") {
		console.warn(
			"args.category, args.variable " +
				"AND args.value are required in timing() " +
				"AND args.value has to be a number"
		);
		return;
	}

	// Required Fields
	const fieldObject = {
		hitType: "timing",
		timingCategory: _format(category),
		timingVar: _format(variable),
		timingValue: value,
	};

	if (label) {
		fieldObject.timingLabel = _format(label);
	}

	ReactGA.send(fieldObject, trackerNames);
}

/**
 * event:
 * GA event tracking
 * @param args.category {String} required
 * @param args.action {String} required
 * @param args.label {String} optional
 * @param args.value {Int} optional
 * @param args.nonInteraction {boolean} optional
 * @param args.transport {string} optional
 * @param {{action: string, category: string}} trackerNames - (optional) a list of extra trackers to run the command on
 */
export function event(
	{ category, action, label, value, nonInteraction, transport, ...args } = {},
	trackerNames = undefined
) {
	// Simple Validation
	if (!category || !action) {
		console.warn("args.category AND args.action are required in event()");
		return;
	}

	// Required Fields
	const fieldObject = {
		hitType: "event",
		eventCategory: _format(category),
		eventAction: _format(action),
	};

	// Optional Fields
	if (label) {
		fieldObject.eventLabel = _format(label);
	}

	if (typeof value !== "undefined") {
		if (typeof value !== "number") {
			console.warn("Expected `args.value` arg to be a Number.");
		} else {
			fieldObject.eventValue = value;
		}
	}

	if (typeof nonInteraction !== "undefined") {
		if (typeof nonInteraction !== "boolean") {
			console.warn("`args.nonInteraction` must be a boolean.");
		} else {
			fieldObject.nonInteraction = nonInteraction;
		}
	}

	if (typeof transport !== "undefined") {
		if (typeof transport !== "string") {
			console.warn("`args.transport` must be a string.");
		} else {
			if (["beacon", "xhr", "image"].indexOf(transport) === -1) {
				console.warn(
					"`args.transport` must be either one of these values: `beacon`, `xhr` or `image`"
				);
			}

			fieldObject.transport = transport;
		}
	}

	Object.keys(args)
		.filter((key) => key.substr(0, "dimension".length) === "dimension")
		.forEach((key) => {
			fieldObject[key] = args[key];
		});

	Object.keys(args)
		.filter((key) => key.substr(0, "metric".length) === "metric")
		.forEach((key) => {
			fieldObject[key] = args[key];
		});

	// Send to GA
	ReactGA.send(fieldObject, trackerNames);
}

/**
 * exception:
 * GA exception tracking
 * @param args.description {String} optional
 * @param args.fatal {boolean} optional
 * @param {Array} trackerNames - (optional) a list of extra trackers to run the command on
 */
export function exception({ description, fatal }, trackerNames) {
	// Required Fields
	// const fieldObject = {
	// 	hitType: "exception",
	// };

	// // Optional Fields
	// if (description) {
	// 	fieldObject.exDescription = _format(description);
	// }

	// if (typeof fatal !== "undefined") {
	// 	if (typeof fatal !== "boolean") {
	// 		console.warn("`args.fatal` must be a boolean.");
	// 	} else {
	// 		fieldObject.exFatal = fatal;
	// 	}
	// }

	// Send to GA
	// ReactGA.send(fieldObject, trackerNames);
	ReactGA.event("app_exception", {
		description,
		fatal,
	});
}

/**
 * outboundLink:
 * GA outboundLink tracking
 * @param args.label {String} e.g. url, or 'Create an Account'
 * @param {function} hitCallback - Called after processing a hit.
 */
export function outboundLink(args, hitCallback, trackerNames) {
	if (typeof hitCallback !== "function") {
		console.warn("hitCallback function is required");
		return;
	}

	// Simple Validation
	if (!args || !args.label) {
		console.warn("args.label is required in outboundLink()");
		return;
	}

	// Required Fields
	const fieldObject = {
		hitType: "event",
		eventCategory: "Outbound",
		eventAction: "Click",
		eventLabel: _format(args.label),
	};

	let safetyCallbackCalled = false;
	const safetyCallback = () => {
		// This prevents a delayed response from GA
		// causing hitCallback from being fired twice
		safetyCallbackCalled = true;

		hitCallback();
	};

	// Using a timeout to ensure the execution of critical application code
	// in the case when the GA server might be down
	// or an ad blocker prevents sending the data

	// register safety net timeout:
	const t = setTimeout(safetyCallback, 250);

	const clearableCallbackForGA = () => {
		clearTimeout(t);
		if (!safetyCallbackCalled) {
			hitCallback();
		}
	};

	fieldObject.hitCallback = clearableCallbackForGA;

	// Send to GA
	ReactGA.send(fieldObject, trackerNames);
}
