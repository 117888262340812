import React from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Container } from './styled/container';

const containerStyle = {
	width: '100%',
	height: '200px',
};

export const Presenter = ({
	onLoad, onUnmount, containerRef, name, value, ...props
}) => <Container {...props}>
	<input type="hidden" {...{ name, value }} />
	<LoadScript
		googleMapsApiKey={process.env.REACT_APP_GMAPS_API_KEY}
		ref={containerRef}
	>
		{/* TODO: disabled state */}
		<GoogleMap
			mapContainerStyle={containerStyle}
			onLoad={onLoad}
			onUnmount={onUnmount}
			options={{ streetViewControl: false }}
		/>
	</LoadScript>
</Container>