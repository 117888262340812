/* eslint-disable no-unused-expressions */
import React, { useCallback, useContext, useMemo, useRef, useState } from "react"
import { event, modalView } from "services/GA"
import retryForm from "utils/retryForm"
import { SEQUENCIA } from "../.."
import { PageContext } from "../../../../pages"
import { forgotPassword } from "../../../../services/Auth_API"
import { getFormData, resetCustomValidity } from "../../../../utils"
import { useStepper } from "../../../Stepper/utils"

export const useLogic = ({ switchSequencia }) => {
	const formRef = useRef(),
		submitRef = useRef({}),
		{ next } = useStepper(),
		{ setLoading } = useContext(PageContext),
		[hasError, setError] = useState(false)

	const handleNext = useCallback(async e => {
		resetCustomValidity(e.target)
		resetCustomValidity(formRef.current)
		submitRef.current.target = e.target

		if (formRef.current?.checkValidity()) {
			return new Promise(res => {
				submitRef.current.res = res
			})
		}

		return false
	}, [])

	const handleSubmit = useCallback(e => {
		e.preventDefault()

		setLoading(true)
		setError(false)

		const form = formRef.current
		const data = getFormData(form)

		forgotPassword(data)
			.then(r => {
				modalView("Email de redefinição de senha enviado")
				event({
					category: "Autenticação",
					action: "Esqueceu a senha",
				})
				return !!r
			})
			.catch(message => {
				setError(retryForm(message, e, () => setError(false)))
				return false
			})
			.then(v => {
				setLoading(false)
				const { res } = submitRef.current
				res(v)
			})
	}, [setLoading])

	const bottomLinks = useMemo(() => [<>
		Lembrou a
		<a href="#!" onClick={switchSequencia(SEQUENCIA.LOGIN)}>senha?</a>
	</>, <>
		Não tem uma conta?
		<a href="#!" onClick={switchSequencia(SEQUENCIA.CADASTRO)}>Cadastre-se</a>
	</>], [switchSequencia])

	return {
		formRef, bottomLinks,
		handleNext, handleSubmit, handleBtnClick: next,
		hasError,
	}
}
