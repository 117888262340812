import { isValidCPF, isValidLandlinePhone, isValidMobilePhone } from "@brazilian-utils/brazilian-utils"
import { ServiceAlert } from "components"
import { useCallback, useContext, useState } from "react"
import { updateUser } from "services"
import { MESSAGES } from "services/enums/Errors"
import { getFormData } from "utils"
import retryForm from "utils/retryForm"
import { updateStorageProfile } from "./CardAuthentication/utils"
import { PageContext } from "pages"

export const FormPerfil = ({ children: Children, onSuccess }) => {
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState()
	const pageData = useContext(PageContext)

	const validateData = useCallback((data, form) => {
		if (data.cpfcnpj && !form.cpfcnpj.readOnly && !isValidCPF(data.cpfcnpj)) {
			setMessage(MESSAGES["auth/invalid_cpf"])
			return false
		} else if (data.telefone && !form.telefone.readOnly && !isValidMobilePhone(data.telefone)) {
			setMessage(MESSAGES["auth/invalid_telefone"])
			return false
		} else if (data.telefone2 && !form.telefone2.readOnly && !isValidLandlinePhone(data.telefone2)) {
			setMessage(MESSAGES["auth/invalid_telefone"])
			return false
		}

		return true
	}, [])

	const handleSubmit = useCallback(e => {
		e.preventDefault()
		const data = getFormData(e.target)

		if (!validateData(data, e.target)) return

		setLoading(true)
		if (pageData && typeof pageData.setLoading === 'function') pageData.setLoading(true)

		const token = sessionStorage.getItem('auth-token')
		const newProfile = updateStorageProfile(data)
		updateUser(token, newProfile)
			.then(() => typeof onSuccess === "function" ? onSuccess() : setMessage(MESSAGES["user/update_success"]))
			.catch(err => setMessage(retryForm(err, e, () => setMessage())))
			.finally(() => {
				setLoading(false)
				if (pageData && typeof pageData.setLoading === 'function') pageData.setLoading(false)
			})
	}, [validateData, onSuccess, pageData])

	const data = JSON.parse(sessionStorage.getItem('auth-profile')) || {}

	return <form id="formInfo" onSubmit={handleSubmit}>
		<ServiceAlert
			{...{ message }}
			onClosed={() => setMessage()}
			autoClose
		/>
		<Children {...{ data, loading }} />
	</form>
}
