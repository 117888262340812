import { defaultFetch, restoreOrgaoId } from "../utils"

const API = process.env.REACT_APP_API_READ_ONLY_ROOT + 'orgaomunicipal';

// ============================== API DOC - START ==============================
/**
 * @api {get} /orgaomunicipal Lista de órgãos
 * @apiVersion 0.1.0
 * @apiName listaOrgaos
 * @apiGroup Orgao
 * @apiPermission todos
 * 
 * @apiDescription Listar todos os órgãos disponíveis
 * 
 * @apiSuccess {Number} id Identificação do órgão
 * @apiSuccess {String} nome Nome do órgão
 * 
 * @apiUse ServiceResponseError
 */
// =============================== API DOC - END ===============================

// ============================== JS DOC - START ===============================
/**
 * @typedef ListaOrgaos
 * @property {Number} id Identificação do órgão
 * @property {String} nome Nome do órgão
 */

/**
 * Listar todos os órgãos disponíveis
 * @function getList
 * @return {ListaOrgaos}
 */
// =============================== JS DOC - END ================================
export function getList() {
	const endPoint = `${API}`
	return defaultFetch(endPoint)
}

// ============================== API DOC - START ==============================
/**
 * @api {get} /:id Detalhes do Órgão
 * @apiVersion 0.1.0
 * @apiName detalhesOrgao
 * @apiGroup Orgao
 * @apiPermission todos
 * 
 * @apiDescription Receber as informações de um órgão específico
 * 
 * @apiParam {Number} id Identificação única do órgão
 * 
 * @apiSuccess {String} nome Nome do órgão
 * @apiSuccess {String} [brasao] URL da imagem do brasão do órgão
 * @apiSuccess {String} [descricao] 
 * @apiSuccess {String} [imagem_fundo] URL da imagem de fundo da página principal
 * @apiSuccess {Number} [redirecionar_para] Id da consulta que deve ser aberta
 * 
 * @apiUse ServiceResponseError
 */
// =============================== API DOC - END ===============================

// ============================== JS DOC - START ===============================
/**
 * @typedef DetalhesOrgao
 * @property {String} nome Nome do órgão
 * @property {String} [brasao] URL da imagem do brasão do órgão
 * @property {String} [descricao] 
 * @property {String} [imagem_fundo] URL da imagem de fundo da página principal
 * @property {Number} [redirecionar_para] Id da consulta que deve ser aberta
 */

/**
 * Receber as informações do órgão ativo
 * @function detalhesOrgao
 * @return {Promise<DetalhesOrgao>}
 */
// =============================== JS DOC - END ================================
export function detalhesOrgao() {
	const id = restoreOrgaoId(),
		endPoint = `${API}/${id}`

	return defaultFetch(endPoint)
		.then(r => r === true ? Promise.reject("Órgão não encontrado") : r)
}

// ============================== API DOC - START ==============================
/**
 * @typedef ParamsOrgao
 * @type {Map<string, boolean | number | string>}
 */

/**
 * Receber as informações do órgão ativo
 * @function paramsOrgao
 * @return {Promise<ParamsOrgao>}
 */
// =============================== JS DOC - END ================================
export function paramsOrgao() {
	const id = restoreOrgaoId(),
		endPoint = `${API}/${id}/params`

	return defaultFetch(endPoint)
		.then(r => r === true ? Promise.reject("Órgão não encontrado") : r)
		.then(r => r.reduce((prev, cur) => {
			prev.set(cur.id, cur.value)
			return prev
		}, new Map()))
}


// ============================== API DOC - START ==============================
// TODO
// =============================== API DOC - END ===============================

// ============================== JS DOC - START ===============================
/**
 * Listar todos os órgãos disponíveis
 * @function findOrgaoByOrigin
 * @return {DetalhesOrgao}
 */
// =============================== JS DOC - END ================================
export function findOrgaoByOrigin(origin) {
	const endPoint = `${API}/find?origin=${origin}`
	return defaultFetch(endPoint)
}