const urlUtils = {
	join(...paths) {
		let list = []

		const schemaParts = paths[0].split("//")
		const hasSchema = schemaParts[1] !== undefined
		const schema = hasSchema ? schemaParts[0] + '//' : paths[0].startsWith("/") ? '/' : ''

		if (hasSchema) paths[0] = paths[0].replace(schema, '')

		for (const path of paths) {
			list = list.concat(...path.split("/"))
		}

		list = list.filter(x => !!x)

		return schema + list.join("/")
	}
}

export default urlUtils
