import { isValidEmail } from "@brazilian-utils/brazilian-utils"
import { ServiceAlert } from "components"
import { useCallback, useState } from "react"
import { useHistory } from "react-router"
import { signIn, signUp } from "services"
import { forgotPassword } from "services/Auth_API"
import { MESSAGES } from "services/enums/Errors"
import styled from "styled-components"
import { checkPassStrength, getFormData } from "utils"
import retryForm from "utils/retryForm"

const Form = styled.form`
	display: flex;
	justify-content: center;
	flex-direction: column;
	max-width: 300px;
	margin: 0 auto;

	.md-form.md-outline {
		margin: 10px 0;
	}
`

export const FormLogin = ({ children: Children, onSuccess, ...props }) => {
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState()

	const validateData = useCallback(data => {
		if (data.email && !isValidEmail(data.email)) {
			setMessage(MESSAGES["auth/invalid_email"])
			return false
		}

		return true
	}, [])

	const history = useHistory()
	const handleSubmit = useCallback(async e => {
		e.preventDefault?.()
		const data = getFormData(e.target)

		if (!validateData(data)) return

		setLoading(true)

		await signIn(data)
			.then(r => onSuccess ? onSuccess(r) : history.push('/conta'))
			.catch(err => setMessage(retryForm(err, e, () => setMessage())))
			.finally(() => setLoading(false))
	}, [validateData, history, onSuccess])

	const content = <>
		<ServiceAlert
			{...{ message }}
			onClosed={() => setMessage()}
			autoClose
		/>
		<Children {...{ loading, handleSubmit }} />
	</>

	return <Form onSubmit={handleSubmit} {...props}>{content}</Form>
}


export const FormCadastro = ({ children: Children, onSuccess, ...props }) => {
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState()

	const validateData = useCallback(data => {
		if (data.email && !isValidEmail(data.email)) {
			setMessage(MESSAGES["auth/invalid_email"])
			return false
		} else if (data.senha && !checkPassStrength(data.senha)) {
			setMessage(MESSAGES["auth/weak_password"])
			return false
		}

		return true
	}, [])

	const history = useHistory()
	const handleSubmit = useCallback(e => {
		e.preventDefault()
		const data = getFormData(e.target)

		if (!validateData(data)) return

		setLoading(true)

		signUp(data)
			.then(r => onSuccess ? onSuccess(r) : history.push('/conta'))
			.catch(err => setMessage(retryForm(err, e, () => setMessage())))
			.finally(() => setLoading(false))
	}, [validateData, history, onSuccess])

	return <Form onSubmit={handleSubmit} {...props}>
		<ServiceAlert
			{...{ message }}
			onClosed={() => setMessage()}
			autoClose
		/>
		<Children {...{ loading }} />
	</Form>
}

export const FormRecuperar = ({ children: Children, ...props }) => {
	const [loading, setLoading] = useState(false)
	const [message, setMessage] = useState()

	const validateData = useCallback(data => {
		if (data.email && !isValidEmail(data.email)) {
			setMessage(MESSAGES["auth/invalid_email"])
			return false
		}

		return true
	}, [])

	const handleSubmit = useCallback(e => {
		e.preventDefault()
		const data = getFormData(e.target)

		if (!validateData(data)) return

		setLoading(true)

		forgotPassword(data)
			.then(() => setMessage(MESSAGES['auth/recover_email_sent']))
			.catch(err => setMessage(retryForm(err, e, () => setMessage())))
			.finally(() => setLoading(false))
	}, [validateData])

	return <Form onSubmit={handleSubmit} {...props}>
		<ServiceAlert
			{...{ message }}
			onClosed={() => setMessage()}
			autoClose
		/>
		<Children {...{ loading }} />
	</Form>
}