import ReactDOM from 'react-dom'
import { getTextColorFromBgColor, lightenHex, darkenHex, hex2Rgb } from './colorUtilities'

import { ReactComponent as Favicon } from 'assets/favicon.svg'

// import changeManifest from "./changeManifest"

export const defaultColors = {
	primaria: process.env.REACT_APP_DEFAULT_PRIMARY_COLOR,
	secundaria: process.env.REACT_APP_DEFAULT_SECONDARY_COLOR,
}

export function defineAppColors(colors = defaultColors) {
	if (!colors.primaria || !colors.secundaria) colors = defaultColors

	let s = document.querySelector('#app-colors')
	if (!s) {
		s = document.createElement('style')
		s.id = 'app-colors'
		document.head.appendChild(s)
	}

	const colorVariables = [colors.primaria, colors.secundaria].map((color) => {
		const bg = color,
			tx = getTextColorFromBgColor(bg),
			bg_light = lightenHex(bg, 0.7),
			tx_light = getTextColorFromBgColor(bg_light),
			bg_lighten = lightenHex(bg, 0.9),
			tx_lighten = getTextColorFromBgColor(bg_lighten),
			bg_dark = darkenHex(bg, 0.4),
			tx_dark = getTextColorFromBgColor(bg_dark),
			bg_darken = darkenHex(bg, 0.1),
			tx_darken = getTextColorFromBgColor(bg_darken),
			bg_rgb = [hex2Rgb(bg)].map((x) => [x.r, x.g, x.b].join(', '))

		return {
			bg,
			tx,
			bg_light,
			tx_light,
			bg_lighten,
			tx_lighten,
			bg_dark,
			tx_dark,
			bg_darken,
			tx_darken,

			bg_rgb,
		}
	})

	s.innerHTML = `
		:root {
			--Branding-Color: ${process.env.REACT_APP_DEFAULT_PRIMARY_COLOR};
			--Branding-Color-Dark: #004F70;

			--primaryBg: ${colorVariables[0].bg};
			--primaryTx: ${colorVariables[0].tx};
			--primaryBgLight: ${colorVariables[0].bg_light};
			--primaryTxLight: ${colorVariables[0].tx_light};
			--primaryBgLighten: ${colorVariables[0].bg_lighten};
			--primaryTxLighten: ${colorVariables[0].tx_lighten};
			--primaryBgDark: ${colorVariables[0].bg_dark};
			--primaryTxDark: ${colorVariables[0].tx_dark};
			--primaryBgDarken: ${colorVariables[0].bg_darken};
			--primaryTxDarken: ${colorVariables[0].tx_darken};
			--secondaryBg: ${colorVariables[1].bg};
			--secondaryTx: ${colorVariables[1].tx};
			--secondaryBgLight: ${colorVariables[1].bg_light};
			--secondaryTxLight: ${colorVariables[1].tx_light};
			--secondaryBgLighten: ${colorVariables[1].bg_lighten};
			--secondaryTxLighten: ${colorVariables[1].tx_lighten};
			--secondaryBgDark: ${colorVariables[1].bg_dark};
			--secondaryTxDark: ${colorVariables[1].tx_dark};
			--secondaryBgDarken: ${colorVariables[1].bg_darken};
			--secondaryTxDarken: ${colorVariables[1].tx_darken};

			--primaryBg-RGB: ${colorVariables[0].bg_rgb};
			--secondaryBg-RGB: ${colorVariables[1].bg_rgb};
		}
	`

	updateFavicon(colorVariables[0].bg, colorVariables[0].tx)
}

export function resetAppColors() {
	let s = document.querySelector('#app-colors')
	if (!s) return

	s.innerHTML = `
		:root {
			--Branding-Color: ${process.env.REACT_APP_DEFAULT_PRIMARY_COLOR};
			--Branding-Color-Dark: #004F70;
		}
	`
	document.head.querySelector('meta[name="theme-color"]').setAttribute('content', '#ffffff')

	updateFavicon('#FFF', process.env.REACT_APP_DEFAULT_PRIMARY_COLOR)
}

function updateFavicon(bg, fg) {
	// reset all favicons
	document.querySelectorAll("link[rel~='icon']").forEach((elem) => elem.remove())

	// create new favicon
	let link = document.createElement('link')
	link.rel = 'icon'
	document.head.appendChild(link)

	// create container to render the SVG
	const cont = document.querySelector('#favContainer') || document.createElement('div')
	cont.id = 'favContainer'
	cont.style.height = 0
	cont.style.overflow = 'hidden'
	document.body.appendChild(cont)

	ReactDOM.render(<Favicon />, cont, () => {
		const img = cont.querySelector('svg')

		// changing the colors
		img.style.setProperty('--bg', bg)
		img.style.setProperty('--fg', fg)

		// generating blob URL to this favicon
		let { width, height } = img.getBBox()
		let clonedSvgElement = img.cloneNode(true)
		let outerHTML = clonedSvgElement.outerHTML,
			blob = new Blob([outerHTML], { type: 'image/svg+xml;charset=utf-8' }),
			blobURL = URL.createObjectURL(blob)

		// loading the favicon svg as image
		let image = new Image()
		image.onload = () => {
			// creating a canvas
			let canvas = document.createElement('canvas')
			canvas.width = width
			canvas.height = height
			let context = canvas.getContext('2d')

			// draw the favicon svg into the canvas
			context.drawImage(image, 0, 0, width, height)

			// save the canvas into the favicon
			link.type = 'image/x-icon'
			link.setAttribute('sizes', `${width}x${height}`)
			link.href = canvas.toDataURL()

			cont.remove()
		}
		image.src = blobURL
	})
}
