
const AnexoIcons = {
	WORD: 'file-word',
	VIDEO: 'file-video',
	PRES: 'file-powerpoint',
	PDF: 'file-pdf',
	IMAGE: 'file-image',
	SHEET: 'file-excel',
	CODE: 'file-code',
	AUDIO: 'file-audio',
	ZIP: 'file-archive',
	TEXT: 'file-alt',
	BLANK: 'file',
	CSV: 'file-csv',
	URL: 'link',
}

function getAnexoIcon(type) {
	// https://gitlab.com/nbssistemas/conpub/monolito/-/issues/80
	// https://gitlab.com/nbssistemas/conpub/apis/readonly/-/blob/master/src/main/java/br/com/nbsnet/consultapublica/beans/TipoArquivo.java
	// Textos: doc, docx, odt, pdf, rtf, tex, txt, wdp
	// Imagens: ai, bmp, gif, ico, jpeg, jpg, png, ps, psd, svg, tif, tiff
	// Vídeos: 3g2, 3gp, avi, flv, h264, m4v, mkv, mov, mp4, mpg, mpeg, rm, swf, vob, wmv
	// Audios: aif, cda, mid, midi, mp3, mpa, ogg, wav, wma, wpl
	// Planilhas: ods, xls, xlsm, xlsx
	// Apresentações: ppt, pptx, pps, odp, key
	// Dados: csv, dat, db, dbf, log, mdb, sav, sql, tar, xml
	// Arquivos comprimidos: 7z, arj, deb, pkg, rar, rpm, tar.gz, z, zip
	switch (type) {
		// TEXTOS
		case '__doc':
		case '_docx':
		case '_odt':
			return AnexoIcons.WORD
		case '_pdf':
			return AnexoIcons.PDF
		case '_rtf':
		case '_tex':
		case '_txt':
		case '_wdp':
			return AnexoIcons.TEXT
		
		// IMAGENS
		case '_ai':
		case '_bmp':
		case '_gif':
		case '_ico':
		case '_jpeg':
		case '_jpg':
		case '_png':
		case '_ps':
		case '_psd':
		case '_svg':
		case '_tif':
		case '_tiff':
			return AnexoIcons.IMAGE

		// VÍDEOS
		case '__3g2':
		case '_3gp':
		case '_avi':
		case '_flv':
		case '_h264':
		case '_m4v':
		case '_mkv':
		case '_mov':
		case '_mp4':
		case '_mpg':
		case '_mpeg':
		case '_rm':
		case '_swf':
		case '_vob':
		case '_wmv':
			return AnexoIcons.VIDEO

		// AUDIOS
		case '_aif':
		case '_cda':
		case '_mid':
		case '_midi':
		case '_mp3':
		case '_mpa':
		case '_ogg':
		case '_wav':
		case '_wma':
		case '_wpl':
			return AnexoIcons.AUDIO

		// PLANILHAS
		case '_ods':
		case '_xls':
		case '_xlsm':
		case '_xlsx':
			return AnexoIcons.SHEET

		// APRESENTAÇÕES
		case '_ppt':
		case '_pptx':
		case '_pps':
		case '_odp':
		case '_key':
			return AnexoIcons.PRES

		// DADOS
		case '_csv':
			return AnexoIcons.CSV
		case '_dat':
		case '_db':
		case '_dbf':
		case '_log':
		case '_mdb':
		case '_sav':
		case '_sql':
		case '_tar':
		case '_xml':
			return AnexoIcons.TEXT
		
		// ARQUIVOS COMPRIMIDOS:
		case '_7z':
		case '_arj':
		case '_deb':
		case '_pkg':
		case '_rar':
		case '_rpm':
		case '_tar_gz':
		case '_z':
		case '_zip':
			return AnexoIcons.ZIP

		// URL
		case '_url':
			return AnexoIcons.URL

		// PADRÃO
		default:
			return AnexoIcons.BLANK
	}
}

module.exports = getAnexoIcon
