const BUTTONS_SELECTOR = 'input[type=submit], button[type=submit], button:not([type])'

/**
 *
 * @param {*} err See: ../services/enums/Errors.js
 * @param {*} evt
 * @param {() => void} clear
 * @param {Boolean} [forceToUseEvtTarget]
 * @returns
 */
export default function retryForm(err, evt, clear, forceToUseEvtTarget = false) {
	if (err.action !== 'retry') return err

	let submitButton = (forceToUseEvtTarget || evt.target.matches(BUTTONS_SELECTOR)) ?
		evt.target :
		evt.target.querySelector(BUTTONS_SELECTOR)

	if (!submitButton && evt.target.hasAttribute('id')) {
		submitButton = document.querySelector(`*[form='${evt.target.id}']`)
	}

	if (submitButton) {
		err.onClick = () => {
			clear()
			submitButton.click()
		}
	} else {
		delete err.action
	}

	return err
}
