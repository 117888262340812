import styled from "styled-components";
import { NavLink as NavLinkRouter } from "react-router-dom";

export const NavLink = styled(NavLinkRouter)`
	font-size: 18px;
	color: ${props => props.$active ? "white" : "#131A26"};
	background-color: ${props => props.$active ? "#004F70": "unset"};
	padding: 10px 20px;
	display: block;
	border-top: solid 1px #B3B3B3;

	:hover, :focus-visible {
		color: #131A26;
		background-color: ${props => props.$active ? "#91b3c1" : "#e7f3f9"};
		outline: none;
	}
`
