import React from 'react'

import { Step } from "../../../Stepper"
import { BodySetter, FooterSetter } from "../../../Stepper/layouts/MDB"
import { NonIdealState } from '../../..'
import { Button } from '@blueprintjs/core'

export const ChangePasswordSuccess = () => {
	return <Step disableGoBack>
		<BodySetter>
			<NonIdealState
				icon="agree"
				title="Senha alterada com sucesso"
				description="Sua senha foi redefinida, utilize a nova senha para acessar o sistema. Você pode fechar esta janela."
				action={<Button text="Ir para tela inicial" onClick={() => window.location.reload()} />}
			/>
		</BodySetter>
		<FooterSetter></FooterSetter>
	</Step>
}