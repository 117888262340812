import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Select } from '@blueprintjs/select'
import { H4 } from './FormContribuiçãoQuestão'
import { getStates, getCities } from '@brazilian-utils/brazilian-utils';
import { Button, MenuItem } from '@blueprintjs/core';
import { removeAcento } from 'utils/removeAcentos';
import styled from 'styled-components';

const Table = styled.table`
	th {
		font-weight: 600;
	}

	th, td {
		padding: 3px 10px;
	}

	tr:nth-child(2n) {
		background-color: #0001;
	}

	tfoot td {
		padding-top: 5px;
	}
`

const Item = (item, { modifiers, handleClick }) => {
	if (!modifiers.matchesPredicate) {
		return null;
	}
	return <MenuItem
		key={item.code}
		active={modifiers.active}
		text={item.name}
		multiline
		onClick={handleClick}
		shouldDismissPopover={false}
		disabled={item.disabled}
	/>
}

const basicFilter = (query, item) => {
	query = removeAcento(query).toLowerCase()
	return [item.code, item.name]
		.find(x => removeAcento(x).toLowerCase().indexOf(query) >= 0)
}

const List = ({ label, items, onItemSelect, selected, disabled }) => <Select
	items={items}
	itemRenderer={Item}
	onItemSelect={onItemSelect}
	itemPredicate={basicFilter}
	disabled={disabled}
	resetOnSelect={true}
	inputProps={{
		placeholder: "Buscar..."
	}}
	popoverProps={{
		position: "top"
	}}
>
	<Button
		text={selected ? selected.name : label}
		rightIcon="double-caret-vertical"
		intent={selected ? "success" : "none"}
		disabled={disabled}
	/>
</Select>

function mapResposta(resposta) {
	return resposta.map(r => {
		let [cidade, e] = r.split("-")
		let estado = getStates().find(es => es.code === e)

		return { e: estado, c: { code: cidade, name: cidade } }
	})
}

const FormContribuiçãoQuestãoCidades = ({ id, titulo, instrucao, onChange, resposta = [], disabled, minAlternativasSelecionadas, maxAlternativasSelecionadas }) => {
	const [estados] = useState(getStates())
	const [cidades, setCidades] = useState([])

	const [selected, setSelected] = useState({})
	const [added, setAdded] = useState(mapResposta(resposta))

	const updateCities = useCallback(estado => {
		if (!estado || !estado.code) return
		setCidades(getCities(estado.code)
			.map(x => ({
				code: x,
				name: x,
				disabled: !!added.find(y => y.e.name === estado.name && y.c.name === x),
			})))
	}, [added])

	const handleEstadoSelect = useCallback(estado => {
		setSelected(x => {
			if (x.e?.code === estado.code) {
				setCidades([])
				return {}
			} else {
				updateCities(estado)
				return { e: estado }
			}
		})
	}, [updateCities])

	const handleCidadeSelect = useCallback(cidade => {
		setSelected(x => x.c?.code === cidade.code ? ({ e: x.e }) : ({ e: x.e, c: cidade }))
	}, [])

	const [canAdd, setCanAdd] = useState(added.length < maxAlternativasSelecionadas)
	useEffect(() => {
		setCanAdd(added.length < maxAlternativasSelecionadas)
	}, [added, maxAlternativasSelecionadas])

	const handleAdd = useCallback(e => {
		e.preventDefault()
		if (!selected.e || !selected.c) return

		setSelected(old => {
			setCidades([])
			setAdded(x => [...x, old])
			return {}
		})
	}, [selected])

	const addRef = useRef()

	useEffect(() => {
		updateCities(selected.e)
	}, [selected, updateCities])

	const firstUpdate = useRef(true)
	useEffect(() => {
		if (!firstUpdate.current)
			onChange({target: addRef.current})

		let message = added.length >= minAlternativasSelecionadas ? "" : `Adicione pelo menos ${minAlternativasSelecionadas} cidades/estados.`
		addRef.current?.scrollIntoView({ block: "center", behavior: "smooth" })
		addRef.current?.setCustomValidity?.(message)

		if (firstUpdate.current)
			firstUpdate.current = false
	}, [added, onChange, minAlternativasSelecionadas])

	return <>
		<H4>{titulo}</H4>
		<div dangerouslySetInnerHTML={{ __html: instrucao }}></div>

		<Table>
			<thead>
				{added.length ? <tr>
					<th></th>
					<th>Estado</th>
					<th>Cidade</th>
				</tr> : null}
			</thead>
			<tbody>
				{added.length ? added.map(({ e, c }, i) => <tr key={`${id}-${i}`}>
					<td>{i + 1}.</td>
					<td>{e.name}</td>
					<td>{c.name}</td>
					{!disabled && <td>
						<input
							type="hidden"
							name={id}
							data-questao={id}
							value={[c.name, e.code].join("-")}
						/>
						<Button icon="cross" onClick={() => {
							setAdded(x => { x.splice(i, 1); return [...x] })
						}} />
					</td>}
				</tr>) : <tr>
					<td colSpan="3" style={{ textAlign: 'center' }}>Nenhuma cidade selecionada</td>
				</tr>}
			</tbody>
			{!disabled && <tfoot>
				<tr>
					<td>{added.length} de {maxAlternativasSelecionadas}</td>
					<td>
						<List
							label="Lista de Estados"
							items={estados}
							onItemSelect={handleEstadoSelect}
							selected={selected.e}
							disabled={!estados.length || !canAdd}
						/>
					</td>
					<td>
						<List
							label="Lista de Cidades"
							items={cidades}
							onItemSelect={handleCidadeSelect}
							selected={selected.c}
							disabled={!cidades.length || !canAdd}
						/>
					</td>
					<td>
						<Button
							type="submit"
							icon="plus"
							onClick={handleAdd}
							elementRef={addRef}
							data-prevent-reset-custom-message={true}
							disabled={!canAdd}
						/>
					</td>
				</tr>
			</tfoot>}
		</Table>
	</>
}

export default FormContribuiçãoQuestãoCidades