import React from 'react'

import {
	FormContribuiçãoQuestãoCheckbox as QuestaoCheckbox,
	FormContribuiçãoQuestãoCidades as QuestaoCidades,
	FormContribuiçãoQuestãoDiscursiva as QuestaoDiscursiva,
	FormContribuiçãoQuestãoObjetiva as QuestaoObjetiva,
	FormContribuiçãoQuestãoTabelaObjetiva as QuestaoTabelaObjetiva,
} from '.'
import styled from 'styled-components'

export const H4 = styled.h4`
	border-left: 7px double var(--primaryBg);
	padding-left: 10px;

	/* ::before {
		counter-increment: questoes;
		content: counter(questoes) ". ";
	} */

	&:not(:first-child) {
		margin-top: 2em;
	}
`

const FormContribuiçãoQuestão = ({questao, disabled, ...p}) => {
	let comp
	switch (questao.tipo) {
		case "checkbox":
			comp = <QuestaoCheckbox {...{...questao, disabled, ...p}} />
			break;
		case "cidades":
			comp = <QuestaoCidades {...{...questao, disabled, ...p}} />
			break;
		case "discursiva":
			comp = <QuestaoDiscursiva {...{...questao, disabled, ...p}} />
			break;
		case "objetiva":
			comp = <QuestaoObjetiva {...{...questao, disabled, ...p}} />
			break;
		case "tabela_objetiva":
			comp = <QuestaoTabelaObjetiva {...{ ...questao, disabled, ...p }} />
			break;
		case "condicional":
			comp = <QuestaoObjetiva {...{ ...questao, disabled, ...p }} />
			break;
		default:
			comp = null;
			break;
	}

	return comp
}

export default FormContribuiçãoQuestão
