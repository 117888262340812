export const defaultReq = {
	atLeast1LowercaseLetter		: true,
	atLeast1CapitalLetter		: true,
	atLeast1Number				: true,
	atLeast1SpecialCharacter	: false,
	atLeastNCharactersInTotal	: 6,
}

export function checkPassStrength(pass, showDetails, requirements = {}) {
	const opts = { ...defaultReq, requirements }

	const {
		atLeast1LowercaseLetter,
		atLeast1CapitalLetter,
		atLeast1Number,
		atLeast1SpecialCharacter,
		atLeastNCharactersInTotal,
	} = opts

	const problems = []

	if (atLeast1LowercaseLetter && !/^(?=.*[a-z])/g.test(pass)) problems.push('atLeast1LowercaseLetter')
	if (atLeast1CapitalLetter && !/^(?=.*[A-Z])/g.test(pass)) problems.push('atLeast1CapitalLetter')
	if (atLeast1Number && !/^(?=.*[0-9])/g.test(pass)) problems.push('atLeast1Number')
	if (atLeast1SpecialCharacter && !/^(?=.*[!@#$%^&*])/g.test(pass)) problems.push('atLeast1SpecialCharacter')
	if (atLeastNCharactersInTotal && !(new RegExp(`^(?=.{${atLeastNCharactersInTotal},})`, 'g')).test(pass)) problems.push('atLeastNCharactersInTotal')

	return showDetails ? problems : !problems.length
}
