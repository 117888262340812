import { useEffect } from "react"
import { useRouteMatch } from "react-router"

export const useMainPageLogic = () => {
	// Rolagem para o topo da página ao mudar de página
	const { path } = useRouteMatch()
	useEffect(() => {
		document.querySelector("#root").scrollTo({
			behavior: "smooth",
			top: 0,
		})
	}, [path])
}
