import React from 'react'
import styled from 'styled-components'
import { Undraw } from './Undraw'

const Header = styled.header`
	display: flex;
	align-items: center;
	gap: min(100px, 10vw);
	margin-bottom: 50px;

	:not(:first-child) {
		margin-top: 50px;
	}
	
	@media (max-width: 768px) {
		flex-direction: column-reverse;
		gap: 20px;
	}
`

const MainContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	flex: 1;

	p {
		margin: 0;
	}
`

const Title = styled.h2`
	width: fit-content;
	border-bottom: 2px solid var(--primaryBg);
	padding-bottom: 10px;
	margin: 0;
`

const SVGContainer = styled(Undraw)`
	color: var(--SVG_default_color);
	flex: none;
`

export function ContribuicaoSection({ title, description = "", illustration = "", children }) {
	return <Header>
			<MainContainer>
				<Title>{title}</Title>
				<p>{description}</p>
			</MainContainer>
			<SVGContainer name={illustration} primaryColor={"currentColor"} style={{ height: '100px', width: '200px' }} />
		</Header>
}
