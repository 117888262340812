/* eslint-disable no-unused-expressions */
import { useCallback, useEffect, useState } from "react"

export const useLogic = ({ onChange, valueDefault, options }) => {
	const [value, setValue] = useState(valueDefault || ""),
		[active, setActive] = useState(!!valueDefault),
		[focus, setFocus] = useState(false)
	
	const handleSelect = useCallback(e => {
		setValue(e.value || e.target.value)
		setActive(true)
		onChange?.(e)
	}, [onChange])

	const handleFocus = useCallback(e => {
		setFocus(true)
	}, [])

	const handleBlur = useCallback(e => {
		setFocus(false)
	}, [])

	const resetValue = useCallback(() => {
		setValue("")
		setActive(false)
		onChange?.()
	}, [onChange])
	
	useEffect(() => {
		if (options instanceof Array && value !== "" && !options.find(o => o.value === value)) {
			resetValue()
		}
	}, [options, value, resetValue])

	return {
		active, focus, value,
		handleSelect, handleFocus, handleBlur, resetValue,
	}
}
