/* eslint-disable no-unused-expressions */
import { useCallback, useRef, useState } from "react"
import { event } from "services/GA"
import { updatePassword } from "../../../../services/Auth_API"
import { checkPassStrength, getFormData, resetCustomValidity } from "../../../../utils"
import { useStepper } from "../../../Stepper/utils"
import retryForm from "utils/retryForm"

export const useLogic = (props) => {
	const formRef = useRef(),
		submitRef = useRef({}),
		{ next } = useStepper(),
		[loading, setLoading] = useState(false),
		[hasError, setError] = useState(false)

	const validateForm = useCallback(() => {
		const data = getFormData(formRef.current)

		let msg = ""

		if (!checkPassStrength(data.password)) msg = "Senha muito fraca. Por favor, utilize letras minúsculas, maiúsculas, números, com pelo menos 6 caracteres no total."

		formRef.current?.querySelector('[name=password]')
			.setCustomValidity(msg)
	}, [])

	const handleNext = useCallback(async e => {
		resetCustomValidity(e.target)
		resetCustomValidity(formRef.current)
		submitRef.current.target = e.target

		validateForm()

		if (formRef.current?.checkValidity()) {
			return new Promise(res => {
				submitRef.current.res = res
			})
		}

		return false
	}, [validateForm])

	const [locked, setLock] = useState(false)

	const handleSubmit = useCallback(e => {
		e.preventDefault()

		if (locked) return

		setLoading(true)
		setError(false)
		setLock(true)

		const form = formRef.current
		const data = getFormData(form)

		const token = sessionStorage.getItem('auth-token')

		updatePassword(token, data.password)
			.then(r => {
				event({
					category: "Autenticação",
					action: "Redefiniu a senha",
				})

				return !!r
			})
			.catch(message => {
				setError(retryForm(message, e, () => setError(false)))
				return false
			})
			.then(v => {
				setLoading(false)
				setLock(false)

				const { res } = submitRef.current
				res(v)
			})
	}, [locked])

	return {
		formRef,
		handleNext, handleSubmit, handleBtnClick: next,
		loading, hasError,
	}
}
