import compose from 'react-hooks-compose'
import PropTypes from 'prop-types';

import { useCoreLogic } from './core'
import { useLayoutLogic } from './layout'
import { useMiscLogic } from './misc'
import { useRecursiveLogic } from './recursive'
import { Presenter } from './presenter'

/**
 * @typedef StepperHooksProps
 * @property {import('react').ReactChildren} children
 * @property {() => void} onFinish
 */

/**
 * @param {StepperHooksProps} props
 * @return {import('./presenter').PresenterProps}
 */
function useCreateStepperHooks({ children, onFinish }) {
	const core = useCoreLogic({ children }),
		{ StepperContextValue: { cursor, setCursor }, step } = core,
		layout = useLayoutLogic({ children, cursor }),
		mySize = layout.LayoutContextValue.sizesState[0][0]

	useRecursiveLogic(layout.LayoutContextValue, core.StepperContextValue)

	const misc = useMiscLogic({ step, onFinish, mySize, setCursor })

	return { ...core, ...layout, ...misc }
}

/**
 * @typedef StepperProps
 * @property {import('./layouts').StepperLayout} layout The layout is any
 * component that uses the `useLayout` hook to retrieve the layout data and
 * displays it in the DOM. The layout data can be set by any component also
 * using the `useLayout` hook. The default data props are `title`, `body` and
 * `footer`. The default layout implements a BackButton and a NextButton to get
 * these goals easily.
 * @property {() => void} onFinish
 */

/** @type {import('react').FunctionComponent<StepperProps>} */
export const Stepper = compose(props => ({
	useStepper: () => useCreateStepperHooks(props)
}))(Presenter)

Stepper.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.element),
		PropTypes.element,
	]).isRequired,
	onFinish: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.exact('loop'),
	])
}

export { Step } from './elements/Step'
