import React, { useCallback, useState } from 'react'
import { MDBInput } from 'mdbreact'
import { H4 } from './FormContribuiçãoQuestão'
import { InputQuestaoDiscursiva } from 'components'

const FormContribuiçãoQuestãoCheckbox = ({
	id, alternativas, titulo, instrucao, disabled, resposta = [], onChange,
	minAlternativasSelecionadas, maxAlternativasSelecionadas, opcaoOutros, opcaoOutrosTexto, respostaTextoOutros,
}) => {
	const [selected, setSelected] = useState(resposta)
	const [textoOutros, setTextoOutros] = useState(respostaTextoOutros)

	const resetOutros = useCallback(() => {
		const name = `${id}-outros`
		if (document.querySelector(`[name='${name}']`))
			document.querySelector(`[name='${name}']`).value = ''
		setTextoOutros("")
	}, [id])

	const handleChange = useCallback(e => {
		setSelected(old => {
			const value = Number(e.target.value)

			if (e.target.checked) {
				old.push(value)
			} else {
				old.splice(old.indexOf(value), 1)

				if (value === alternativas.find(x => x.opcaoOutros)?.id)
					resetOutros()
			}
			return [...old]
		})
		onChange(e)
	}, [onChange, alternativas, resetOutros])

	const isBlocked = alternativaId => {
		return selected.length >= maxAlternativasSelecionadas && !selected.includes(alternativaId)
	}

	return <>
		<H4>{titulo}</H4>
		<div dangerouslySetInnerHTML={{ __html: instrucao }}></div>

		{alternativas
			.filter(x => !disabled || resposta.includes(x.id))
			.map(alternativa => <MDBInput
			key={alternativa.id}
			id={`${id}-${alternativa.id}`}
			name={id}
			value={alternativa.id}
			type="checkbox"
			label={alternativa.texto}
			disabled={disabled || isBlocked(alternativa.id)}
			defaultChecked={resposta.includes(alternativa.id)}
			onChange={handleChange}
			required={selected.length < minAlternativasSelecionadas}
			children={alternativa.opcaoOutros && opcaoOutrosTexto ? <InputQuestaoDiscursiva
				name={`${id}-outros`}
				id={`${id}-outros`}
				mascara="not_empty"
				outline={true}
				size="sm"
				className="m-0"
				containerClass="d-inline-block m-0 ml-2"
				required={selected.includes(alternativa.id)}
				disabled={disabled || !selected.includes(alternativa.id)}
				onChange={onChange}
				resposta={textoOutros}
			/> : null}
		/>)}
	</>
}

export default FormContribuiçãoQuestãoCheckbox