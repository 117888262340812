import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom'
import ReactDOM from 'react-dom'
import {
	MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink,
	MDBCollapse, MDBIcon, MDBNavbarToggler,
} from 'mdbreact';
import styled from 'styled-components';

import { Brasao, NavbarBackButton, NavbarBreadcrumb } from '.';
import { UpdateNavbar } from './Navbar/components/UpdateNavbar';
import { getAuthState } from 'utils';
import { FaUserCircle } from 'react-icons/fa';
import urlUtils from 'utils/urlUtils';

const BP = process.env.REACT_APP_BASE_PATH
const MAIN_PAGES = [{
	to: BP, icon: "home", text: "Início"
}, {
	to: `/ativas`, icon: "clipboard-check", text: "Ativas"
}, {
	to: `/finalizadas`, icon: "clipboard-list", text: "Finalizadas"
}]

const CustomCollapse = styled(MDBCollapse)`
	@media (max-width: 768px) {
		margin-top: 10px;
	}
`

const CustomNavbarNav = styled(MDBNavbarNav)`
	flex-direction: row !important;

	@media (max-width: 768px) {
		justify-content: space-around;
	}
`

const BrandNavbar = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
`

const HeaderAccountLink = styled(Link)`
	color: currentColor;

	:hover {
		color: currentColor;
		background-color: rgba(0,0,0,.1);
	}

	svg {
		margin: 4px;
	}
`

function Navbar({ userToken, setUserToken, fixed, orgaoInfo, className }) {
	const [isOpen, setIsOpen] = useState(false),
		{ url } = useRouteMatch(),
		[navRef, setNavRef] = useState()

	useEffect(() => {
		function handleClick(e) {
			const me = ReactDOM.findDOMNode(navRef)
			if (!me.contains(e.target)) {
				setIsOpen(false)
			}
		}
		window.addEventListener('click', handleClick)

		return () => {
			window.removeEventListener('click', handleClick)
		}
	}, [navRef])

	return <>
		<MDBNavbar
			expand="md"
			className={`${className}`}
			transparent={fixed && !isOpen}
			ref={ref => setNavRef(ref)}
		>
			<div className="container p-0">
				<MDBNavbarBrand className="d-flex align-items-center p-0">
					<NavbarBackButton />
					<Link
						to={url}
						style={{
							color: "inherit",
							lineHeight: "1em",
							height: 40,
							boxSizing: "content-box",
						}}
					>
						{!fixed && <Brasao
							className="mr-2"
							filled
							size="40px"
							title={orgaoInfo?.nome}
							src={orgaoInfo?.brasao}
						/>}
						<BrandNavbar>
							<strong>{process.env.REACT_APP_BRANDING_NAME}</strong>
							<NavbarBreadcrumb />
						</BrandNavbar>
					</Link>
				</MDBNavbarBrand>

				<MDBNavbarToggler
					onClick={() => setIsOpen(!isOpen)}
					style={{ color: "inherit" }}
				>
					<MDBIcon icon={isOpen ? "times" : "bars"} fixed />
				</MDBNavbarToggler>

				<CustomCollapse isOpen={isOpen} navbar>
					<CustomNavbarNav right>
						{MAIN_PAGES.map(l => <MDBNavItem key={l.text}>
							<MDBNavLink
								to={urlUtils.join('/' + (window.isSelfOrigin ? orgaoInfo.id : ''), l.to)}
								onClick={() => setIsOpen(false)}
								style={{ color: "inherit" }}
							>
								<span className="d-inline d-md-none mr-1">
									<MDBIcon icon={l.icon} fixed />
									{' '}
								</span>
								{l.text}
							</MDBNavLink>
						</MDBNavItem>)}
						<HeaderAccountLink
							to={getAuthState() ? "/conta" : "/entrar"}
							onClick={() => sessionStorage.setItem('whereRedirect', window.location.pathname)}
						>
							<FaUserCircle size="32px" />
						</HeaderAccountLink>
					</CustomNavbarNav>

					{/* TODO: JWT
				<MDBNavbarNav right>
					{
						!userToken ? (
							<MDBNavItem>
								<MDBNavLink
									className="waves-effect waves-light"
									to={`${url}login`}
									title="Entrar"
									onClick={() => {
										sessionStorage.setItem("whereRedirect", window.location.pathname + window.location.search)
										setIsOpen(false)
									}}
									style={{ color: "inherit" }}
								>
									<MDBIcon icon="sign-in-alt" fixed /><span className="d-inline d-md-none ml-1">{' '}Entrar</span>
								</MDBNavLink>
							</MDBNavItem>
						) : (
								<MDBNavItem>
									<MDBDropdown>
										<MDBDropdownToggle
											nav caret
											style={{ color: "inherit" }}
										>
											<MDBIcon icon="user" fixed /><span className="d-inline d-md-none ml-1">{' '}Conta</span>
										</MDBDropdownToggle>
										<MDBDropdownMenu className="dropdown-default">
											{/* <MDBDropdownItem href="#!" onClick={() => setIsOpen(false)}>Meu perfil</MDBDropdownItem> }
											{/* <MDBDropdownItem href="#!" onClick={() => setIsOpen(false)} disabled>Minhas colaborações</MDBDropdownItem> }
											{/* <MDBDropdownItem divider /> }
											<MDBDropdownItem
												href="#!"
												onClick={() => setUserToken(null)}
											>Sair</MDBDropdownItem>
										</MDBDropdownMenu>
									</MDBDropdown>
								</MDBNavItem>
							)
					}
				</MDBNavbarNav> */}
				</CustomCollapse>
			</div>
		</MDBNavbar>
		<UpdateNavbar />
	</>
}

export default styled(Navbar)`
	color: var(--Navbar_color);
	background-color: var(--Navbar_bg_color);
	z-index: 10;
`

export { MAIN_PAGES }