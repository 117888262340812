import React from 'react'
import { MDBInput } from 'mdbreact'
import { H4 } from './FormContribuiçãoQuestão'
import styled from 'styled-components'

const Table = styled.table`
	width: 100%;
	table-layout: fixed;

	thead th:first-child {
		width: 300px;
	}

	thead th {
		text-align: center;
	}

	@media (min-width: 1024px) {
		thead th:first-child {
			width: 50%;
		}
	}

	@media (max-width: 768px) {
		overflow: auto;
		thead, tbody {
			width: 650px;
		}

		thead th:first-child {
			width: 200px;
		}

		thead th span {
			writing-mode: vertical-rl;
			transform: rotate(180deg);
			padding-top: 10px;
			text-align: left;
		}
	}

	@media (max-width: 425px) {
		thead th:first-child {
			width: 30vw;
		}
	}

	tr:nth-child(2n) {
		background-color: #0001;
	}

	thead th {
		font-weight: 500;
	}

	.form-check {
		padding: 0;
		width: fit-content;
		margin: 0 auto;
	}

	&&& .form-check-label {
		padding-left: 25px;
	}
`

const FormContribuiçãoQuestaoTabelaObjetiva = ({
	id, perguntas, alternativas, titulo, instrucao, disabled, resposta = [], ...p
}) => {
	return <>
		<H4>{titulo}</H4>
		<div dangerouslySetInnerHTML={{ __html: instrucao }}></div>

		<Table>
			{!disabled && <thead>
				<tr>
					<th></th>
					{alternativas.map(alternativa => <th
						key={[id, alternativa.id].join()}
					>
						<span>{alternativa.texto}</span>
					</th>)}
				</tr>
			</thead>}
			<tbody>
				{perguntas.map((pergunta, i) => <tr
					key={[id, pergunta.id].join()}
				>
					<th>{pergunta.texto}</th>
					{!disabled && alternativas.map(alternativa => <td
						key={[id, pergunta.id, alternativa.id].join()}
					>
						<MDBInput
							type="radio"
							id={[pergunta.id, alternativa.id].join()}
							name={`${pergunta.id}`}
							value={alternativa.id}
							required
							defaultChecked={resposta[i] === alternativa.id}
							data-questao={id}
							{...p}
						/>
					</td>)}
					{disabled && alternativas
						.filter(alternativa => resposta[i] === alternativa.id)
						.map(alternativa => <td
							key={[id, pergunta.id, alternativa.id].join()}
						>{alternativa.texto}</td>)}
				</tr>)}
			</tbody>
		</Table>
	</>
}

export default FormContribuiçãoQuestaoTabelaObjetiva