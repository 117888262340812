export function redirectByPost(purl, pparameters, in_new_tab) {
	pparameters = (typeof pparameters == 'undefined') ? {} : pparameters
	in_new_tab = (typeof in_new_tab == 'undefined') ? true : in_new_tab

	var form = document.createElement("form")
	form.style.display = 'none'
	form.setAttribute("id", "reg-form")
	form.setAttribute("name", "reg-form")
	form.setAttribute("action", purl)
	form.setAttribute("method", "post")
	form.setAttribute("enctype", "multipart/form-data")
	if (in_new_tab) {
		form.setAttribute("target", "_blank")
	}

	for (const key in pparameters) {
		const input = document.createElement('textarea')
		input.name = key
		input.value = pparameters[key]
		form.appendChild(input)
	}
	document.body.appendChild(form)
	form.submit()
	document.body.removeChild(form)
}
