export default async function changeManifest(props) {
	document.head
		.querySelector('meta[name="theme-color"]')
		.setAttribute('content', props.background_color)

	const actualElem = document.head.querySelector("[rel=manifest]")
	const actualMan = await fetch(actualElem.href).then(r => r.json())
	const actualManText = JSON.stringify(actualMan)

	const newMan = {
		...actualMan,
		...props
	}

	prependOrigin(newMan, "start_url")
	newMan.icons && newMan.icons.forEach(icon => {
		prependOrigin(icon, "src")
	})

	const stringMan = JSON.stringify(newMan)

	if (actualManText === stringMan) return

	const blobMan = new Blob([stringMan], {
		type: 'application/json'
	})
	const urlMan = URL.createObjectURL(blobMan)
	actualElem.setAttribute('href', urlMan)
}

function prependOrigin(obj, attr) {
	const { origin } = window.location
	let base_path = process.env.REACT_APP_BASE_PATH

	if (origin.endsWith('/') && base_path.startsWith('/'))
		base_path = base_path.slice(1)

	if (base_path.endsWith('/') && obj[attr].startsWith('/'))
		base_path = base_path.slice(0, -1)
	
	if (obj[attr] && !obj[attr].startsWith(origin)) {
		obj[attr] = origin+base_path+obj[attr]
	}
}