import { Button, Colors, H1, H2 } from "@blueprintjs/core"
import { UsuarioMainPage } from "components/UsuarioPageLayout"
import { FormDeleteAccount } from 'components'

export const Dados = () => <UsuarioMainPage>
	<H1>Dados</H1>
	<H2 style={{ color: Colors.RED3 }}>Desativar conta</H2>
	<p style={{ textAlign: 'justify' }}>Se você deseja realmente que sua conta seja desativada, há algumas coisas importantes a se levar em consideração:</p>
	<ul style={{ textAlign: 'justify' }}>
		<li>Nem todas as informações que você nos proveu serão excluídas:
			<ul>
				<li>Contribuições de {process.env.REACT_APP_BRANDING_OBJECT} encerradas <b>não serão removidas</b> pois já fazem parte de um processo público;</li>
				<li>Contribuições de {process.env.REACT_APP_BRANDING_OBJECT} que estão nesse momento em andamento serão <b>removidas</b> após sua confirmação via e-mail.</li>
			</ul>
		</li>
		<li>O acesso a sua conta será encerrado imediatamente após sua confirmação via email. Você poderá reverter esta ação em até 7 dias após tendo realizado a confirmação. Você receberá maiores informações na sua caixa de e-mail.</li>
	</ul>
	<div style={{ textAlign: 'center' }}>
		<FormDeleteAccount>
			{({ loading }) => <Button
				intent="danger"
				type="submit"
				loading={loading}
			>DESATIVAR CONTA</Button>}
		</FormDeleteAccount>
	</div>
</UsuarioMainPage>
