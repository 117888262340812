import { ConsultasNav } from "./elements/ConsultasNav";
import { ContaNav } from "./elements/ContaNav";
import { PoliticasNav } from "./elements/PoliticasNav";
import { Aside } from "./styled/Aside";

export const UsuarioPageAsidePresenter = ({ authState }) => <Aside>
	{authState && <ConsultasNav />}
	<ContaNav />
	<PoliticasNav />
</Aside>
