import { getAuthState } from "utils";
import { UsuarioPageNav } from "./Nav";

export const ContaNav = () => <UsuarioPageNav
	title="Minha Conta"
	links={[{
		key: "perfil",
		href: "/conta/perfil",
		text: "Perfil",
		hidden: !getAuthState(),
	// }, {
	// 	key: "seguranca",
	// 	href: "/conta/seguranca",
	// 	text: "Segurança",
	// 	hidden: !getAuthState(),
	// }, {
	// 	key: "noti",
	// 	href: "/conta/notificacoes",
	// 	text: "Notificações",
	// 	hidden: !getAuthState(),
	// }, {
	// 	key: "pref",
	// 	href: "/conta/preferencias",
	// 	text: "Preferências",
	// 	hidden: !getAuthState(),
	}, {
		key: "dados",
		href: "/conta/dados",
		text: "Dados",
		hidden: !getAuthState(),
	}, {
		key: "entrar",
		href: "/entrar",
		text: "Entrar",
		hidden: getAuthState(),
	}, {
		key: "cadastro",
		href: "/cadastro",
		text: "Cadastrar-se",
		hidden: getAuthState(),
	}, {
		key: "recuperar",
		href: "/recuperar",
		text: "Recuperar senha",
		hidden: getAuthState(),
	}]}
/>